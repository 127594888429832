import * as React from "react";
import { Backdrop, Typography, Stack, CircularProgress } from "@mui/material";

const LoadingSpinner = ({ loading }) => {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={loading}
		>
			<Stack spacing={2} sx={{ fontWeight: 'bold', textAlign: 'center', display:'flex', justifyContent:'center', alignItems:'center' }}>
				<CircularProgress />
				<Typography>We are fetching the latest data for you...</Typography>
			</Stack>
		</Backdrop>
	);
};

export default LoadingSpinner;
