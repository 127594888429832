// THIS IS THE OFFICIAL CODE STRUCTURE TEMPLATE USE FOR THIS TEMPLATE
// USE: rfce + Tab
import React from "react";
import * as Material from "@mui/material";

function About() {
	return (
		<Material.Box flex={4} height="100vh" >
			<Material.Box
				paddingLeft={{xs: 4, sm: 10, md: 10 }}
				paddingTop={{ xs: 4, sm: 6, md: 10 }}
			>
				EMPTY ABOUT FOR NOW!
			</Material.Box>
		</Material.Box>
	);
}

export default About;
