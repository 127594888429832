import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  clientTrainingsSave,
  clientEligibilitySave,
} from "../../../services/ClientProfileService";
import * as Material from "@mui/material";
import { displayAlert } from "../../common/Alert";

// Icons Used
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

const useInitialTrainingState = (initialData = {}) => {
  const user_id = useSelector((state) => state.user.id);
  const profiles_id = useSelector((state) => state.profile_id || 0);

  const createNewTrainingEntry = () => ({
    profiles_id: profiles_id,
    name: "",
    hours_of_training: "",
    institution: "",
    skills_acquired: "",
    certificate_received: "",
    added_by: user_id,
  });

  const trainingData = initialData
    ? initialData?.result1.map((training) => {
        return {
          id: training?.id || 0,
          profiles_id: training.profiles_id,
          name: training.name || "",
          hours_of_training: training.hours_of_training || "",
          institution: training.institution || "",
          skills_acquired: training.skills_acquired || "",
          certificate_received: training.certificate_received || "",
          added_by: training.added_by || user_id,
        };
      })
    : [createNewTrainingEntry()];

  const addNewTrainingEntry = () => {
    const newEntry = createNewTrainingEntry();
    return [...trainingData, newEntry];
  };

  return { trainingData, addNewTrainingEntry };
};

const useInitialEligibilityState = (initialData = {}) => {
  const user_id = useSelector((state) => state.user.id || 0);
  const profiles_id = useSelector((state) => state.profile_id || 0);

  const createNewEligibilityEntry = () => ({
    id: 0,
    profiles_id: profiles_id,
    eligibility: "",
    rating: 0,
    exam_date: "",
    license: "",
    valid_until: "",
    added_by: user_id,
  });

  const eligibilityData = initialData
    ? initialData?.result2.map((eligibility) => ({
        id: eligibility.id || 0,
        profiles_id: eligibility.profiles_id,
        eligibility: eligibility.eligibility || "",
        rating: eligibility.rating || 0,
        exam_date: eligibility.exam_date || "",
        license: eligibility.license || "",
        valid_until: eligibility.valid_until || "",
        added_by: eligibility.added_by || user_id,
      }))
    : [createNewEligibilityEntry()];

  const addNewEligibilityEntry = () => {
    const newEntry = createNewEligibilityEntry();
    return [...eligibilityData, newEntry];
  };

  return { eligibilityData, addNewEligibilityEntry };
};

const TrainingAndVocational = ({ InitialProfileData }) => {
  const profiles_id = useSelector((state) => state.profile_id || 0);
  const initialTrainingsState = useInitialTrainingState(InitialProfileData);
  const initialEligibilityState =
    useInitialEligibilityState(InitialProfileData);

  const user_id = useSelector((state) => state.user.id);
  const [trainings, setTrainings] = useState(
    initialTrainingsState.trainingData
  );
  const [eligibilities, setEligibilities] = useState(
    initialEligibilityState.eligibilityData
  );
  const [doneFirstLoad, setDoneFirstLoad] = useState(false);
  const [coursesRow, setCoursesRow] = useState([{ id: 1 }]);
  const [eligibilityRow, setEligibilityRow] = useState([{ id: 1 }]);
  const [initialSave, setInitialSave] = useState(false);

  const editMode =
    InitialProfileData && Object.keys(InitialProfileData).length > 0;
  const prof_id = editMode ? InitialProfileData.result0[0].id : profiles_id;

  const saveMultipleTimes = () => {
    if (trainings.length > 0) {
      trainings.forEach((training, index) => {
        // console.log("$$Im Here for training!", training);
        const eligibility = eligibilities[index] || {};
        handleTrainingandVocationalSave(training, eligibility);
      });
    }

    if (eligibilities.length > trainings.length) {
      eligibilities.slice(trainings.length).forEach((eligibility) => {
        // console.log("$$Im Here for remaining eligibility!", eligibility);

        const training = {};
        handleTrainingandVocationalSave(training, eligibility);
      });
    }
  };

  const handleTrainingandVocationalSave = (training, eligibility) => {
    // console.log("$$Im Here1!");
    setDoneFirstLoad(true);

    const isNewProfile = training.id === 0 || eligibility.id === 0;

    if (editMode || !isNewProfile) {
      saveTrainingAndEligibility(training, eligibility, true);
    } else {
      saveTrainingAndEligibility(training, eligibility, false);
    }
  };

  const saveTrainingAndEligibility = (training, eligibility, isUpdate) => {
    const actionType = isUpdate ? "Updated" : "Saved";

    if (training && Object.keys(training).length > 0) {
      // console.log("$$Im Here Logging training!", training);
      if (
        !training.name &&
        !training.hours_of_training &&
        !training.skills_acquired &&
        !training.certificate_received &&
        !training.institution
      ) {
        return;
      } else {
        clientTrainingsSave(training)
          .then((res) => {
            // console.log("$$Res:", res);
            if (res.error) {
              displayAlert({
                message: `Error ${actionType} Training!`,
                severity: "error",
              });
            } else {
              displayAlert({
                message: `Training ${actionType} Successfully!`,
                severity: "success",
              });
              {
                editMode ? setInitialSave(false) : setInitialSave(true);
              }
            }
          })
          .catch((err) =>
            displayAlert({
              message: `Error Occurred: ${err}`,
              severity: "error",
            })
          );
      }
    }

    if (eligibility && Object.keys(eligibility).length > 0) {
      // console.log("$$Im Here Logging eligibility!", eligibility);
      if (
        !eligibility.eligibility &&
        !eligibility.exam_date &&
        !eligibility.license
      ) {
        return;
      } else {
        clientEligibilitySave(eligibility)
          .then((res) => {
            if (res.error) {
              displayAlert({
                message: `Eligibility ${actionType} Failed`,
                severity: "error",
              });
            } else {
              displayAlert({
                message: `Eligibility ${actionType} Successfully!`,
                severity: "success",
              });
              {
                editMode ? setInitialSave(false) : setInitialSave(true);
              }
            }
          })
          .catch((err) =>
            displayAlert({
              message: `Error Saving Eligibility: ${err}`,
              severity: "error",
            })
          );
      }
    }
  };

  const onHandleChangeTrainings = (e, index) => {
    const { name, value } = e.target;
    setTrainings((prevTrainings) => {
      const updatedTrainings = [...prevTrainings];
      updatedTrainings[index] = {
        ...updatedTrainings[index],
        [name]:
          name === "user_id" || name === "prof_id" || name === "id"
            ? parseInt(value, 10)
            : value,
      };
      return updatedTrainings;
    });
  };

  const onHandleChangeEligibility = (e, index) => {
    const { name, value } = e.target;
    setEligibilities((prevEligibility) => {
      const updatedEligibility = [...prevEligibility];
      updatedEligibility[index] = {
        ...updatedEligibility[index],
        [name]:
          name === "user_id" ||
          name === "rating" ||
          name === "prof_id" ||
          name === "id"
            ? parseInt(value, 10)
            : value,
      };
      return updatedEligibility;
    });
  };

  const handleAddCourseRow = (id) => {
    const newId =
      coursesRow.length > 0 ? coursesRow[coursesRow.length - 1].id + 1 : 1;
    setCoursesRow([...coursesRow, { id: newId }]);

    setTrainings((prevTrainings) => [
      ...prevTrainings,
      {
        id: 0,
        profiles_id: prof_id,
        name: "",
        date_start: "",
        date_end: "",
        institution: "",
        certificate_received: "",
        added_by: user_id,
      },
    ]);
  };

  const handleRemoveCourseRow = () => {
    const updatedCourses = [...coursesRow];
    updatedCourses.pop();
    setCoursesRow(updatedCourses);
    setTrainings((prevTrainings) => prevTrainings.slice(0, -1));
  };

  const handleAddEligibilityRow = () => {
    const newId =
      eligibilityRow.length > 0
        ? eligibilityRow[eligibilityRow.length - 1].id + 1
        : 1;
    setEligibilityRow([...eligibilityRow, { id: newId }]);

    setEligibilities((prevEligibility) => [
      ...prevEligibility,
      {
        id: 0,
        profiles_id: prof_id,
        eligibility: "",
        rating: 0,
        exam_date: "",
        license: "",
        valid_until: "",
        added_by: user_id,
      },
    ]);
  };

  const handleRemoveEligibilityRow = () => {
    const updatedCourses = [...eligibilityRow];
    updatedCourses.pop();
    setEligibilityRow(updatedCourses);
    setEligibilities((prevEligibility) => prevEligibility.slice(0, -1));
  };

  return (
    <Material.Box
      sx={{ width: "100%", padding: 2, overflow: "auto", height: "90%" }}
    >
      <Material.Paper
        //  elevation={2}
        sx={{ padding: 2, marginBottom: 4 }}
      >
        <Material.Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Training and Vocational Course
        </Material.Typography>
        {trainings?.map((course, index) => (
          <Material.Grid
            key={index}
            style={{ paddingTop: 20 }}
            container
            spacing={2}
          >
            <Material.Grid item xs={12}>
              <Material.Typography
                variant="subtitle1"
                gutterBottom
                sx={{ color: "#42a5f5", fontWeight: "bold" }}
              >
                {index + 1}
              </Material.Typography>
            </Material.Grid>
            <Material.Grid item xs={12} sm={6}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="name"
                  label="Training/Vocational Course"
                  variant="filled"
                  value={trainings[index].name || ""}
                  onChange={(e) => onHandleChangeTrainings(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
            <Material.Grid item xs={12} sm={6}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                 name="hours_of_training"
                 label="Hours of Training"
                 variant="filled"
                 value={trainings[index].hours_of_training || ""}
                 onChange={(e) => onHandleChangeTrainings(e, index)}
                 />
              </Material.FormControl>
            </Material.Grid>
            {/* <Material.Grid item xs={12} sm={3}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="date_start"
                  label="     "
                  variant="standard"
                  type="date"
                  helperText="Date From"
                  value={trainings[index].date_start || ""}
                  onChange={(e) => onHandleChangeTrainings(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
            <Material.Grid item xs={12} sm={3}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="date_end"
                  label="    "
                  variant="standard"
                  type="date"
                  helperText="Date To"
                  value={trainings[index].date_end || ""}
                  onChange={(e) => onHandleChangeTrainings(e, index)}
                />
              </Material.FormControl>
            </Material.Grid> */}
            <Material.Grid item xs={12} sm={6}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="institution"
                  label="Training Institution"
                  variant="filled"
                  value={trainings[index].institution || ""}
                  onChange={(e) => onHandleChangeTrainings(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
            <Material.Grid item xs={12} sm={6}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                 name="skills_acquired"
                 label="Skills Acquired"
                 variant="filled"
                 value={trainings[index].skills_acquired || ""}
                 onChange={(e) => onHandleChangeTrainings(e, index)}
                 />
              </Material.FormControl>
            </Material.Grid>
            <Material.Grid item xs={12} sm={12}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="certificate_received"
                  label="Certificates Received"
                  variant="filled"
                  value={trainings[index].certificate_received || ""}
                  onChange={(e) => onHandleChangeTrainings(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
          </Material.Grid>
        ))}
        {/* Button Grid */}
        <Material.Grid container spacing={2} sx={{ padding: 2 }}>
          <Material.Grid item xs={12} sm={2}>
            <Material.FormControl fullWidth variant="outlined">
              <Material.Button
                variant="text"
                startIcon={<AddRoundedIcon />}
                onClick={handleAddCourseRow}
              >
                Add Row
              </Material.Button>
            </Material.FormControl>
          </Material.Grid>
          {coursesRow.length > 1 ? (
            <Material.Grid item xs={12} sm={3}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.Button
                  variant="text"
                  startIcon={<RemoveRoundedIcon />}
                  onClick={handleRemoveCourseRow}
                >
                  Remove Row
                </Material.Button>
              </Material.FormControl>
            </Material.Grid>
          ) : (
            ""
          )}
        </Material.Grid>
      </Material.Paper>

      <Material.Paper
        // elevation={2}
        sx={{ padding: 2, marginBottom: 4 }}
      >
        <Material.Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 5, fontWeight: "bold" }}
        >
          Eligibility/Professional License
        </Material.Typography>
        {eligibilities?.map((eligibility, index) => (
          <Material.Grid
            key={eligibility.id}
            style={{ paddingTop: 20 }}
            container
            spacing={2}
          >
            <Material.Grid item xs={12}>
              <Material.Typography
                variant="subtitle1"
                gutterBottom
                sx={{ color: "#42a5f5", fontWeight: "bold" }}
              >
                {index + 1}
              </Material.Typography>
            </Material.Grid>
            <Material.Grid item xs={12} sm={9}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="eligibility"
                  label="Eligibility"
                  variant="filled"
                  value={eligibilities[index].eligibility || ""}
                  onChange={(e) => onHandleChangeEligibility(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
            {/* <Material.Grid item xs={12} sm={6}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="rating"
                  label="Rating"
                  variant="standard"
                  type="number"
                  value={eligibilities[index].rating || ""}
                  onChange={(e) => onHandleChangeEligibility(e, index)}
                />
              </Material.FormControl>
            </Material.Grid> */}
            <Material.Grid item xs={12} sm={3}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="exam_date"
                  label="     "
                  variant="filled"
                  type="date"
                  helperText="Date of Taken"
                  value={eligibilities[index].exam_date || ""}
                  onChange={(e) => onHandleChangeEligibility(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
            <Material.Grid item xs={12} sm={9}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="license"
                  label="Professional License"
                  variant="filled"
                  value={eligibilities[index].license || ""}
                  onChange={(e) => onHandleChangeEligibility(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
            <Material.Grid item xs={12} sm={3}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.TextField
                  name="valid_until"
                  label="    "
                  variant="filled"
                  type="date"
                  helperText="Valid Until"
                  value={eligibilities[index].valid_until || ""}
                  onChange={(e) => onHandleChangeEligibility(e, index)}
                />
              </Material.FormControl>
            </Material.Grid>
          </Material.Grid>
        ))}

        {/* Button Grid */}
        <Material.Grid container spacing={2} sx={{ padding: 2 }}>
          <Material.Grid item xs={12} sm={2}>
            <Material.FormControl fullWidth variant="outlined">
              <Material.Button
                variant="text"
                startIcon={<AddRoundedIcon />}
                onClick={handleAddEligibilityRow}
              >
                Add Row
              </Material.Button>
            </Material.FormControl>
          </Material.Grid>
          {eligibilityRow.length > 1 ? (
            <Material.Grid item xs={12} sm={3}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.Button
                  variant="text"
                  startIcon={<RemoveRoundedIcon />}
                  onClick={handleRemoveEligibilityRow}
                >
                  Remove Row
                </Material.Button>
              </Material.FormControl>
            </Material.Grid>
          ) : (
            ""
          )}
        </Material.Grid>
      </Material.Paper>

      <Material.Button
        onClick={saveMultipleTimes}
        sx={{
          marginLeft: "10px",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#7ab3ef",
          },
        }}
        disabled={initialSave}
      >
        Save
      </Material.Button>
    </Material.Box>
  );
};

export default TrainingAndVocational;
