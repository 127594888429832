import React, { useEffect, useState } from "react";
import * as Material from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import axios from "axios";

const COLORS = [
  "#0088FE",
  "#f06292",
  "#FFBB28",
  "#FF8042",
  "#c2185b",
  "#8884d8",
  "#8e24aa",
  "#d4e157",
  "#ff7043",
  "#9e9d24",
  "#3949ab",
  "#26a69a",
  "#ffca28",
  "#5c6bc0",
  "#00C49F",
  "#7b1fa2",
  "#afb42b",
  "#29b6f6",
  "#ef5350",
  "#ab47bc",
  "#66bb6a",
  "#ffa726",
  "#42a5f5",
  "#7e57c2",
  "#d32f2f",
  "#388e3c",
  "#1976d2",
  "#c2185b",
  "#689f38",
  "#0288d1",
  "#d81b60",
  "#8e24aa",
  "#fbc02d",
  "#512da8",
  "#e64a19",
  "#f57c00",
  "#5d4037",
  "#00796b",
  "#f4511e",
  "#6d4c41",
  "#0288d1",
  "#00796b",
  "#f57c00",
  "#7e57c2",
  "#d81b60",
  "#0288d1",
  "#c2185b",
  "#689f38",
];

function Dashboard() {
  const [tabIndex, setTabIndex] = useState(0);
  const [genderCounts, setGenderCounts] = useState({ male: 0, female: 0 });
  const [municipalDataDistrict1, setMunicipalDataDistrict1] = useState([]);
  const [municipalDataDistrict2, setMunicipalDataDistrict2] = useState([]);
  const [municipalDataDistrict3, setMunicipalDataDistrict3] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [activeProgramCounts, setActiveProgramCounts] = useState([]);
  const [totalProgramCounts, setTotalProgramCounts] = useState([]);

  const years = Array.from(
    new Array(10),
    (val, index) => new Date().getFullYear() - index
  ); // Last 10 years
  const fetchGenderCounts = (year) => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/gender/genders?year=${year}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      })
      .then((response) => {
        const genders = response.data;
        const counts = genders.reduce(
          (acc, gender) => {
            if (gender === "male") acc.male += 1;
            if (gender === "female") acc.female += 1;
            return acc;
          },
          { male: 0, female: 0 }
        );
        setGenderCounts(counts);
      })
      .catch((error) => {
        console.error("Error fetching gender data:", error);
      });
  };

  const fetchMunicipalData = (district, year, setMunicipalData) => {
    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/municipalitiesdistrict${district}/count?year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      )
      .then((response) => {
        setMunicipalData(
          response.data.map((item, index) => ({
            id: index,
            value: item.COUNT,
            label: item.municipal_name,
          }))
        );
      })
      .catch((error) => {
        console.error(
          `Error fetching municipal data for district ${district}:`,
          error
        );
      });
  };

  const fetchActiveProgramCounts = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/countprograms/active-counts-today`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      )
      .then((response) => {
        setActiveProgramCounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching active program counts:", error);
      });
  };

  const fetchTotalProgramCounts = () => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/countprograms/total-counts`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      })
      .then((response) => {
        setTotalProgramCounts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching total program counts:", error);
      });
  };

  useEffect(() => {
    fetchGenderCounts(selectedYear);
    fetchMunicipalData(1, selectedYear, setMunicipalDataDistrict1);
    fetchMunicipalData(2, selectedYear, setMunicipalDataDistrict2);
    fetchMunicipalData(3, selectedYear, setMunicipalDataDistrict3);
    fetchActiveProgramCounts();
    fetchTotalProgramCounts();
  }, [selectedYear]);

  const genderData = [
    { name: "Male", value: genderCounts.male },
    { name: "Female", value: genderCounts.female },
  ];

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <Material.Box
      flex={4}
      height="90vh"
      sx={{ maxWidth: "90%", margin: "0 auto", overflow: "hidden" }}
    >
      <Material.Box
        paddingLeft={{ xs: 2, sm: 3, md: 3 }}
        paddingTop={{ xs: 1, sm: 2, md: 2 }}
      >
        {/* <Material.Typography
          fontWeight="bold"
          variant="h4"
          fontFamily="Sen, sans-serif"
          marginTop={1}
        >
          Dashboard
        </Material.Typography> */}
        <Material.Box
          sx={{ display: "flex", justifyContent: "left", marginTop: 2 }}
        >
          <Material.Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Material.Tab label="District 1" />
            <Material.Tab label="District 2" />
            <Material.Tab label="District 3" />
            <Material.Tab label="Gender" />
          </Material.Tabs>
        </Material.Box>
        <Material.Grid
          container
          spacing={2}
          sx={{ padding: 2, justifyContent: "center" }} // Centering the content
        >
          <Material.Grid item xs={12} md={8} height="calc(100vh - 200px)">
            {" "}
            {/* 2/3 of the dashboard */}
            {tabIndex === 0 && (
              <Material.Card
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(3px)",
                  borderRadius: "15px",
                  height: "100%",
                  position: "relative", // Add relative positioning
                }}
              >
                <Material.CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    height: "100%", // Make content fill the card height
                  }}
                >
                  <Material.FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 16,
                      zIndex: 1,
                      width: 150, // Adjust the width here
                    }}
                  >
                    <Material.InputLabel id="year-select-label">
                      Year
                    </Material.InputLabel>
                    <Material.Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <Material.MenuItem key={year} value={year}>
                          {year}
                        </Material.MenuItem>
                      ))}
                    </Material.Select>
                  </Material.FormControl>
                  <Material.Typography
                    fontWeight="bold"
                    variant="h4"
                    fontFamily="Sen, sans-serif"
                    marginTop={15}
                  >
                    District 1 Municipal Count
                  </Material.Typography>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={municipalDataDistrict1}
                        dataKey="value"
                        nameKey="label"
                        cx="50%"
                        cy="50%"
                        outerRadius={250} // Adjusting radius
                        fill="#8884d8"
                        label
                      >
                        {municipalDataDistrict1.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Material.CardContent>
              </Material.Card>
            )}
            {tabIndex === 1 && (
              <Material.Card
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(3px)",
                  borderRadius: "15px",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Material.CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    height: "100%",
                  }}
                >
                  <Material.FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 16,
                      zIndex: 1,
                      width: 150,
                    }}
                  >
                    <Material.InputLabel id="year-select-label">
                      Year
                    </Material.InputLabel>
                    <Material.Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <Material.MenuItem key={year} value={year}>
                          {year}
                        </Material.MenuItem>
                      ))}
                    </Material.Select>
                  </Material.FormControl>
                  <Material.Typography
                    fontWeight="bold"
                    variant="h4"
                    fontFamily="Sen, sans-serif"
                    marginTop={15}
                  >
                    District 2 Municipal Count
                  </Material.Typography>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={municipalDataDistrict2}
                        dataKey="value"
                        nameKey="label"
                        cx="50%"
                        cy="50%"
                        outerRadius={250} // Adjusting radius
                        fill="#82ca9d"
                        label
                      >
                        {municipalDataDistrict2.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Material.CardContent>
              </Material.Card>
            )}
            {tabIndex === 2 && (
              <Material.Card
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(3px)",
                  borderRadius: "15px",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Material.CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    height: "100%",
                  }}
                >
                  <Material.FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 16,
                      zIndex: 1,
                      width: 150,
                    }}
                  >
                    <Material.InputLabel id="year-select-label">
                      Year
                    </Material.InputLabel>
                    <Material.Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <Material.MenuItem key={year} value={year}>
                          {year}
                        </Material.MenuItem>
                      ))}
                    </Material.Select>
                  </Material.FormControl>
                  <Material.Typography
                    fontWeight="bold"
                    variant="h4"
                    fontFamily="Sen, sans-serif"
                    marginTop={15}
                  >
                    District 3 Municipal Count
                  </Material.Typography>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={municipalDataDistrict3}
                        dataKey="value"
                        nameKey="label"
                        cx="50%"
                        cy="50%"
                        outerRadius={250} // Adjusting radius
                        fill="#FF8042"
                        label
                      >
                        {municipalDataDistrict3.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Material.CardContent>
              </Material.Card>
            )}
            {tabIndex === 3 && (
              <Material.Card
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(3px)",
                  borderRadius: "15px",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Material.CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    height: "100%",
                  }}
                >
                  <Material.FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 16,
                      zIndex: 1,
                      width: 150,
                    }}
                  >
                    <Material.InputLabel id="year-select-label">
                      Year
                    </Material.InputLabel>
                    <Material.Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Year"
                    >
                      {years.map((year) => (
                        <Material.MenuItem key={year} value={year}>
                          {year}
                        </Material.MenuItem>
                      ))}
                    </Material.Select>
                  </Material.FormControl>
                  <Material.Typography
                    fontWeight="bold"
                    variant="h4"
                    fontFamily="Sen, sans-serif"
                    marginTop={15}
                  >
                    Gender Distribution
                  </Material.Typography>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={genderData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={250}
                        fill="#8884d8"
                        label
                      >
                        {genderData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Material.CardContent>
              </Material.Card>
            )}
          </Material.Grid>
          <Material.Grid item xs={12} md={4} height="calc(100vh - 200px)">
            <Material.Card
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(3px)",
                borderRadius: "15px",
                height: "100%",
                position: "relative",
              }}
            >
              <Material.CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0,
                  height: "100%",
                }}
              >
                <Material.Typography
                  fontWeight="bold"
                  variant="h6"
                  fontFamily="Sen, sans-serif"
                  marginTop={2}
                >
                  Active Programs Today
                </Material.Typography>
                <Material.List>
                  {activeProgramCounts.map((program, index) => (
                    <Material.ListItem key={index}>
                      {program.program_name}: {program.active_count}
                    </Material.ListItem>
                  ))}
                </Material.List>
                <Material.Typography
                  fontWeight="bold"
                  variant="h6"
                  fontFamily="Sen, sans-serif"
                  marginTop={2}
                >
                  Total Programs
                </Material.Typography>
                <Material.List>
                  {totalProgramCounts.map((program, index) => (
                    <Material.ListItem key={index}>
                      {program.program_name}: {program.total_count}
                    </Material.ListItem>
                  ))}
                </Material.List>
              </Material.CardContent>
            </Material.Card>
          </Material.Grid>
        </Material.Grid>
      </Material.Box>
    </Material.Box>
  );
}

export default Dashboard;
