const DataPassing = {}

// sample: DataPassing.Get('sample');
DataPassing.Get = (value) => {
    return localStorage.getItem(value);
}

DataPassing.Set = (name, value) => {
    try {
        localStorage.setItem(name, value);
        return true;
    } catch {
        return false;
    }
}

export default DataPassing;