import API from "../helpers/AxiosHelper";

export const ProfileInfoSave = (value) => {
  const req = {
    id: parseInt(value.id),
    lastname: value.lastname,
    firstname: value.firstname,
    middlename: value.middlename,
    suffix: value.suffix,
    birthdate: value.birthdate,
    age: value.age,
    religion: value.religion,
    civil_status: value.civil_status,
    gender: value.gender,
    email: value.email,
    landline: value.landline,
    mobile: value.mobile,
    province: value.province_name,
    barangay_name: value.barangay_name,
    barangay_id: value.barangay_id,
    postal_code: value.postal_code,
    street_address: value.street_address,
    added_by: value.added_by,
    municipality: value.municipal_name,
    permanent_province_name: value.permanent_province_name,
    permanent_municipality: value.permanent_municipality,
    permanent_postal_code: value.permanent_postal_code,
    permanent_barangay: value.permanent_barangay,
    permanent_street_address: value.permanent_street_address,
    fathers_name: value.fathers_name,
    occupation_father: value.occupation_father,
    contact_no_father: value.contact_no_father,
    mothers_name: value.mothers_name,
    occupation_mother: value.occupation_mother,
    contact_no_mother: value.contact_no_mother,
    parents_civil_status: value.parents_civil_status,
    parents_other_info: value.parents_other_info,
    parents_unemployment_type: value.parents_unemployment_type,
    govt_id_no: value.govt_id_no,
    type_of_govt_id: value.type_of_govt_id,
    height_ft: value.height_ft,
    disabilities: value.disabilities,
    other_disability: value.other_disability,
    dialect: value.dialect,
    other_dialect: value.other_dialect,
  };

  return API.post("/bsrs/profile", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errorMessage = `${err?.message || "An error occurred"} | ${
        err?.response?.data?.message || "No additional error details"
      }`;

      return {
        error: true,
        message: errorMessage,
      };
    });
};
export const GetMunicipality = (province_id) => {
  // console.log("$$value", province_id);
  const req = {
    params: {
      province_id: province_id,
    },
  };
  return API.get("bsrs/getMunicipality", req)
    .then((res) => {
      // console.log("$$resService", res);
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const GetBarangay = (municipal_id) => {
  // console.log("$$value", municipal_id);
  const req = {
    params: {
      municipal_id: municipal_id,
    },
  };
  return API.get("bsrs/getListOfBarangay", req)
    .then((res) => {
      // console.log("$$resService", res);
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};
export const EducBackground = (value, id) => {
  console.log("$$value", value);

  const req = {
    id: parseInt(value.id),
    educ_type: value.educ_type,
    educ_status: value.educ_status, //graduate or undergrad (highest attainment)
    elem_school_name: value.elem_school_name,
    elem_level_reached:value.elem_level_reached,
    elem_yr_graduated: value.elem_yr_graduated,
    high_school_name: value.high_school_name,
    high_yr_graduated: value.high_yr_graduated,
    high_school_level_reached: value.high_school_level_reached,
    senior_high_strand: value.senior_high_strand,
    voc_school_name: value.voc_school_name,
    voc_course: value.voc_course,
    voc_level_reached: value.voc_level_reached,
    voc_yr_graduated: value.voc_yr_graduated,
    college_school_name: value.college_school_name,
    college_course: value.college_course,
    college_level_reached: value.college_level_reached,
    college_yr_graduated: value.college_yr_graduated,
    masteral_school_name: value.masteral_school_name,
    masteral_course: value.masteral_course,
    masteral_level_reached: value.masteral_level_reached,
    masteral_yr_graduated: value.masteral_yr_graduated,
  };
  return API.post("/bsrs/educBackground", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const clientTrainingsSave = (value) => {
  const req = {
    id: parseInt(value.id) || 0,
    profiles_id: parseInt(value.profiles_id),
    name: value.name,
    hours_of_training: value.hours_of_training,
    institution: value.institution,
    skills_acquired: value.skills_acquired,
    certificate_received: value.certificate_received,
    added_by: parseInt(value.added_by),
  };
  return API.post("/bsrs/updateProfilesTrainings", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const clientEligibilitySave = (value) => {
  // console.log("$$Service Value", value);

  const req = {
    id: value?.id,
    profiles_id: parseInt(value.profiles_id),
    eligibility: value.eligibility,
    rating: value.rating,
    exam_date: value.exam_date,
    license: value.license,
    valid_until: value.valid_until,
    added_by: parseInt(value.added_by),
  };

  // console.log("$$Service Request:", req);

  return API.post("/bsrs/updateEligibility", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

// Get Training by Profile ID
export const GetTrainingByProfileId = (profiles_id) => {
  const req = {
    params: {
      // id: id,
      profiles_id: profiles_id,
    },
  };
  return API.get("/bsrs/getTrainingById", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

// // Get Eligibility by Profile ID
export const GetEligibilityByProfileId = (profiles_id) => {
  const req = {
    params: {
      // id: id,
      profiles_id: profiles_id,
    },
  };
  return API.get("/bsrs/getEligibilityById", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const EmploymentStatusSave = (value) => {
  const req = {
    id: parseInt(value.id),
    abroad_country: value.abroad_country, //abroad or laid off country
    employment_status: value.employment_status,
    employed_type: value.employed_type,
    self_employed_status: value.self_employed_status,
    unemployed_status: value.unemployed_status,
    other_skills: value.other_skills,
    other_jobs: value.other_jobs,
    is_ofw: value.is_ofw,
    ofw_country: value.ofw_country,
    is_former_ofw: value.is_former_ofw,
    latest_country_of_deployment: value.latest_country_of_deployment,
    return_year: value.return_year,
    is_4ps: value.is_4ps,
    household_id: value.household_id,
  };

  return API.post("/bsrs/employmentStatus", req)
    .then((res) => res)
    .catch((err) => ({
      error: true,
      message:
        err.response?.data?.message || err.message || "An error occurred",
    }));
};

export const getEmploymentStatuses = () => {
  // console.log("Calling getEmploymentStatuses API..."); // Log before the API call
  return API.get("/bsrs/getEmploymentStatuses")
    .then((response) => {
      return response.data; // Access the data as needed
    })
    .catch((error) => {
      console.error("Error fetching employment statuses:", error);
      return { error: true, message: error.message };
    });
};

export const getSkills = () => {
  return API.get("/bsrs/getSkills")
    .then((response) => {
      return response.data; // Correctly access the 'result0' property
    })
    .catch((error) => {
      console.error("Error fetching skills:", error);
      return { error: true, message: error.message };
    });
};

// export const saveDoleProgramData = (value) => {
//   const req = {
//     id: parseInt(value.id),
//     dole_program: value.dole_program,
//     dole_program_1st_availment: value.dole_programs_1st_avialment,
//     dole_program_1st_availment_date: value.dole_programs_1st_avialment_date,
//     dole_program_1st_availment_spes_id_no:
//       value.dole_programs_1st_avialment_spes_id_no,
//     dole_program_2nd_availment: value.dole_programs_2nd_avialment,
//     dole_program_2nd_availment_date: value.dole_programs_2nd_avialment_date,
//     dole_program_2nd_availment_spes_id_no:
//       value.dole_programs_2nd_avialment_spes_id_no,
//     dole_program_3rd_availment: value.dole_programs_3rd_avialment,
//     dole_program_3rd_availment_date: value.dole_programs_3rd_avialment_date,
//     dole_program_3rd_availment_spes_id_no:
//       value.dole_programs_3rd_avialment_spes_id_no,
//     dole_program_4th_availment: value.dole_programs_4th_avialment,
//     dole_program_4th_availment_date: value.dole_programs_4th_avialment_date,
//     dole_program_4th_availment_spes_id_no:
//       value.dole_programs_4th_avialment_spes_id_no,
//   };

//   return API.post("/bsrs/dolePrograms", req)
//     .then((res) => res)
//     .catch((err) => ({
//       error: true,
//       message:
//         err.response?.data?.message || err.message || "An error occurred",
//     }));
// };

// ojt
export const DoleProgramSave = (value) => {
  const req = {
    id: parseInt(value.id),
    dole_program: value.dole_program,
    availment: value.availment,
    dole_program_1st_availment_date: value.dole_program_1st_availment_date,
    dole_program_2nd_availment_date: value.dole_program_2nd_availment_date,
    dole_program_3rd_availment_date: value.dole_program_3rd_availment_date,
    dole_program_4th_availment_date: value.dole_program_4th_availment_date,
    dole_program_1st_availment_spes_id_no:
      value.dole_program_1st_availment_spes_id_no,
    dole_program_2nd_availment_spes_id_no:
      value.dole_program_2nd_availment_spes_id_no,
    dole_program_3rd_availment_spes_id_no:
      value.dole_program_3rd_availment_spes_id_no,
    dole_program_4th_availment_spes_id_no:
      value.dole_program_4th_availment_spes_id_no,
  };

  return API.post("/bsrs/dolePrograms", req)
    .then((res) => res)
    .catch((err) => ({
      error: true,
      message:
        err.response?.data?.message || err.message || "An error occurred",
    }));
};

// ojt

export const getPrograms = () => {
  return API.get("/bsrs/getPrograms")
    .then((response) => {
      return response.data; // Correctly access the 'result0' property
    })
    .catch((error) => {
      console.error("Error fetching programs:", error);
      return { error: true, message: error.message };
    });
};

// Frontend: Save Work Experience (Updated to handle multiple rows)
export const clientWorkExperienceSave = (experienceData) => {
  const promises = experienceData.map((value) => {
    // console.log("valueExp", value);

    const req = {
      id: parseInt(value.id) || 0,
      profiles_id: parseInt(value.profiles_id),
      company_name: value.company_name,
      position: value.position,
      status: value.status,
      no_of_months: parseInt(value.no_of_months),
      address: value.address,
      added_by: parseInt(value.added_by),
    };

    return API.post("/bsrs/workExperience", req)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return {
          error: true,
          message: `${err.message} | ${err?.response?.data?.message}`,
        };
      });
  });

  // Return all promises as a batch operation (to handle multiple experience rows)
  return Promise.all(promises);
};

export const getWorkExperiencebyProfileID = (profiles_id) => {
  const req = {
    params: {
      // id: id,
      profiles_id: profiles_id,
    },
  };
  return API.get("/bsrs/getWorkExperiencebyID", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};
