// THIS IS THE OFFICIAL CODE STRUCTURE TEMPLATE USE FOR THIS TEMPLATE
// USE: rfce + Tab
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Get, Delete } from "../../../services/UsersService";
import { useState } from "react";
import { useSelector } from "react-redux";
import { displayAlert } from "../../common/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import UsersModal from "./UsersModal";
import UsersResetPasswordModal from "./UsersResetPasswordModal";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function Users() {
  const users_id = useSelector((state) => state.user.id);
  const [usersData, setUsersData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [dialogVisible, setdialogVisible] = useState(false);
  const [selectedRow, setselectedRow] = useState({});
  const [modalVisible, setmodalVisible] = useState(false);
  const [modalVisibleResetPassword, setmodalVisibleResetPassword] =
    useState(false);
  // testing:
  // useEffect(() => {
  //     console.log('$$selectedRow', selectedRow);
  // }, [selectedRow]);

  useEffect(() => {
    //componentdidmount:
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HideModal = () => {
    setmodalVisible(false);
  };
  const ShowModal = () => {
    setmodalVisible(true);
  };

  const HideModalResetPassword = () => {
    setmodalVisibleResetPassword(false);
  };
  const ShowModalResetPassword = () => {
    setmodalVisibleResetPassword(true);
  };

  const deleteUser = () => {
    if (selectedRow) {
      Delete(selectedRow.id)
        .then((res) => {
          if (res.error) {
            displayAlert({
              message: res.message,
              severity: "error",
            });
          } else {
            displayAlert({
              message: "User deleted successfully!",
              severity: "success",
            });
            getUsers();
          }
        })
        .catch((err) => {
          displayAlert({
            message: err,
            severity: "error",
          });
        });
    } else {
      displayAlert({
        message: "Nothing to delete!",
        severity: "warning",
      });
    }
    setdialogVisible(false);
  };

  const getUsers = () => {
    Get(users_id)
      .then((res) => {
        if (res.error) {
          displayAlert({
            message: res.message,
            severity: "error",
          });
        } else {
          setUsersData(res.data.result0);
          setRoleData(res.data.result1);
        }
      })
      .catch((err) => {
        displayAlert({
          message: err,
          severity: "error",
        });
      });
  };

  const onEdit = (row) => {
    // console.log('$$row', row);
    setselectedRow(row);
    ShowModal();
  };
  const onAdd = () => {
    setselectedRow({});
    ShowModal();
  };
  const onResetPassword = (row) => {
    // console.log('$$row', row);
    setselectedRow(row);
    ShowModalResetPassword();
  };

  const columns = [
    { field: "username", headerName: "Username", width: 100, type: "string" },
    { field: "fullname", headerName: "Fullname", width: 250, type: "string" },
    { field: "role_name", headerName: "Role", width: 150, type: "string" },
    {
      field: "birthday",
      headerName: "Birthday",
      width: 150,
      type: "string",
      sortable: false,
    },
    {
      field: "is_active",
      headerName: "Active",
      width: 100,
      type: "boolean",
      sortable: false,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        const onClickEdit = (e) => {
          // e.stopPropagation(); // don't select this row after clicking
          onEdit(params.row);
        };
        const onClickDelete = (e) => {
          setselectedRow(params.row);
          setdialogVisible(true);
        };
        const onClickResetPassword = (e) => {
          onResetPassword(params.row);
        };

        // return <Button onClick={onClickEdit}>Click</Button>;
        return (
          <>
            <Tooltip title="Delete">
              <IconButton
                onClick={onClickDelete}
                aria-label="delete"
                size="small"
              >
                <DeleteIcon fontSize="inherit" sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton onClick={onClickEdit} aria-label="edit" size="small">
                <EditIcon fontSize="inherit" sx={{ color: "blue" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset Password">
              <IconButton
                onClick={onClickResetPassword}
                aria-label="reset"
                size="small"
              >
                <RotateLeftIcon fontSize="inherit" sx={{ color: "orange" }} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <Box flex={4} height="80vh" sx={{ maxWidth: "90%" }}>
      <Typography
        sx={{
          paddingTop: 4,
          paddingLeft: 10,
          paddingBottom: 2,
          fontSize: 30,
          fontWeight: "bold",
          letterSpacing: 1.5,
          background: "linear-gradient(90deg, #2575fc, #6dd5ed)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
          borderRadius: 1,
        }}
      >
        Users
      </Typography>
      <Box
        paddingLeft={{ xs: 4, sm: 30, md: 10 }}
        // paddingTop={{ xs: 4, sm: 6, md: 10 }}
      >
        <div>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={onAdd}
            sx={{ marginBottom: 2 }}
          >
            Create New User
          </Button>
        </div>
        <div style={{ marginTop: 2 }}>
          <DataGrid
            columns={columns}
            rows={usersData}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </div>

        <Dialog
          open={dialogVisible}
          onClose={() => {
            setdialogVisible(false);
          }}
        >
          <DialogTitle>CONFIRM DELETE</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`You are going to delete ${selectedRow?.username?.toUpperCase()}. All her/his record will also be deleted. Do you wish to continue?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setdialogVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteUser();
              }}
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <UsersModal
          SelectedRow={selectedRow}
          ModalVisible={modalVisible}
          HideModal={HideModal}
          UserRoles={roleData}
          RefreshUser={getUsers}
        />
        <UsersResetPasswordModal
          SelectedRow={selectedRow}
          ModalVisible={modalVisibleResetPassword}
          HideModal={HideModalResetPassword}
        />
      </Box>
    </Box>
  );
}

export default Users;
