//Original
import API from '../helpers/AxiosHelper';

export const UsersLogin = (stateData) => {
    // console.log('$$stateData', stateData);
    return API.post('/auth/login', {
        // request body
        email: stateData.email,
        password: stateData.password
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.log('$$err', err);
        console.log('$$err', err?.response?.status);
        return { error: true, message: err?.message };
    });
};

