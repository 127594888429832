import React, { useState, useEffect } from "react";
import * as Material from "@mui/material";
import { EducBackground } from "../../../services/ClientProfileService";
import { displayAlert } from "../../common/Alert";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useSelector } from "react-redux";

const EducationalBackgroundContent = ({ InitialProfileData, ProfileId }) => {
  const initialStateData = {
    id: 0,
    educ_type: "",
    educ_status: "", //highest educ attainment
    elem_school_name: "",
    elem_level_reached: "",
    elem_yr_graduated: "", // year last attended/ year graduated
    high_school_name: "",
    high_yr_graduated: "", // year last attended/ year graduated
    high_school_level_reached: "",
    senior_high_strand: "",
    voc_school_name: "",
    voc_course: "",
    voc_level_reached: "",
    voc_yr_graduated: "", // year last attended/ year graduated
    college_school_name: "",
    college_course: "",
    college_level_reached: "",
    college_yr_graduated: "", // year last attended/ year graduated
    masteral_school_name: "",
    masteral_course: "",
    masteral_level_reached: "",
    masteral_yr_graduated: "", // year last attended/ year graduated
  };

  const editMode =
    InitialProfileData && Object.keys(InitialProfileData).length > 0;

  const [selectedAttainment, setSelectedAttainment] = useState("");
  const [loading, setLoading] = useState(false);
  const [educBackground, setEducBackground] = useState(initialStateData);
  const [errors, setErrors] = useState({});

  const [initialSave, setInitialSave] = useState(false);

  useEffect(() => {
    if (editMode) {
      setEducBackground(InitialProfileData.result0[0]);
      setSelectedAttainment(InitialProfileData.result0[0]?.educ_status);
    }
  }, [InitialProfileData, editMode]);

  const profile_id = useSelector((state) => state.profile_id);
  const prof_id = editMode ? InitialProfileData.result0[0]?.id : profile_id;
  // console.log("$$profi_id", prof_id);
  const handleChangeAttainment = (event) => {
    setSelectedAttainment(event.target.value);
    setEducBackground((prevEducBackground) => ({
      ...prevEducBackground,
      educ_status: event.target.value,
    }));
  };

  const handleTypeChange = (event) => {
    setEducBackground((prevEducBackground) => ({
      ...prevEducBackground,
      educ_type: event.target.value,
    }));
  };

  const handleChangeEducBackground = (event) => {
    const { name, value } = event.target;
    setEducBackground((prevEducBackground) => ({
      ...prevEducBackground,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value ? "This field is required" : "",
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    const { educ_status, educ_type } = educBackground;
    if (!educ_type) {
      newErrors.educ_type = "This field is required";
    }
    if (!educ_status) {
      newErrors.educ_status = "This field is required";
    }

    if (educ_status === "Elementary Level (Grade I-VI)") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_level_reached)
        newErrors.elem_level_reached = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
    } else if (educ_status === "Elementary Graduate") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
    } else if (educ_status === "High School Undergraduate (non K-12) (I-IV)") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.high_school_level_reached)
        newErrors.high_school_level_reached = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
    } else if (
      educ_status === "High School Undergraduate (for K-12) (Grade XI)"
    ) {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.high_school_level_reached)
        newErrors.high_school_level_reached = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
    } else if (
      educ_status === "High School Undergraduate (for K-12) (Grade XII)"
    ) {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.senior_high_strand)
        newErrors.senior_high_strand = "This field is required";
      if (!educBackground.high_school_level_reached)
        newErrors.high_school_level_reached = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
    } else if (educ_status === "High School Graduate") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.senior_high_strand)
        newErrors.senior_high_strand = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
    } else if (educ_status === "Vocational Undergraduate") {
      if (!educBackground.voc_school_name)
        newErrors.voc_school_name = "This field is required";
      if (!educBackground.voc_course)
        newErrors.voc_course = "This field is required";
      if (!educBackground.voc_level_reached)
        newErrors.voc_level_reached = "This field is required";
      if (!educBackground.voc_yr_graduated)
        newErrors.voc_yr_graduated = "This field is required";
    } else if (educ_status === "Vocational Graduate") {
      if (!educBackground.voc_school_name)
        newErrors.voc_school_name = "This field is required";
      if (!educBackground.voc_course)
        newErrors.voc_course = "This field is required";
      if (!educBackground.voc_yr_graduated)
        newErrors.voc_yr_graduated = "This field is required";
    } else if (educ_status === "College Level (I-V)") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.senior_high_strand)
        newErrors.senior_high_strand = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
      if (!educBackground.college_school_name)
        newErrors.college_school_name = "This field is required";
      if (!educBackground.college_course)
        newErrors.college_course = "This field is required";
      if (!educBackground.college_level_reached)
        newErrors.college_level_reached = "This field is required";
      if (!educBackground.college_yr_graduated)
        newErrors.college_yr_graduated = "This field is required";
    } else if (educ_status === "College Graduate") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.senior_high_strand)
        newErrors.senior_high_strand = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
      if (!educBackground.college_school_name)
        newErrors.college_school_name = "This field is required";
      if (!educBackground.college_course)
        newErrors.college_course = "This field is required";
      if (!educBackground.college_yr_graduated)
        newErrors.college_yr_graduated = "This field is required";
    } else if (educ_status === "Masteral/Post Graduate Level") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.senior_high_strand)
        newErrors.senior_high_strand = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
      if (!educBackground.college_school_name)
        newErrors.college_school_name = "This field is required";
      if (!educBackground.college_course)
        newErrors.college_course = "This field is required";
      if (!educBackground.college_yr_graduated)
        newErrors.college_yr_graduated = "This field is required";
      if (!educBackground.masteral_school_name)
        newErrors.masteral_school_name = "This field is required";
      if (!educBackground.masteral_course)
        newErrors.masteral_course = "This field is required";
      if (!educBackground.masteral_level_reached)
        newErrors.masteral_level_reached = "This field is required";
      if (!educBackground.masteral_yr_graduated)
        newErrors.masteral_yr_graduated = "This field is required";
    } else if (educ_status === "Masteral/Post Graduate") {
      if (!educBackground.elem_school_name)
        newErrors.elem_school_name = "This field is required";
      if (!educBackground.elem_yr_graduated)
        newErrors.elem_yr_graduated = "This field is required";
      if (!educBackground.high_school_name)
        newErrors.high_school_name = "This field is required";
      if (!educBackground.senior_high_strand)
        newErrors.senior_high_strand = "This field is required";
      if (!educBackground.high_yr_graduated)
        newErrors.high_yr_graduated = "This field is required";
      if (!educBackground.college_school_name)
        newErrors.college_school_name = "This field is required";
      if (!educBackground.college_course)
        newErrors.college_course = "This field is required";
      if (!educBackground.college_yr_graduated)
        newErrors.college_yr_graduated = "This field is required";
      if (!educBackground.masteral_school_name)
        newErrors.masteral_school_name = "This field is required";
      if (!educBackground.masteral_course)
        newErrors.masteral_course = "This field is required";
      if (!educBackground.masteral_yr_graduated)
        newErrors.masteral_yr_graduated = "This field is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitEducBackgroundFn = () => {
    setLoading(true);

    if (!validateFields()) {
      displayAlert({
        message: "Please fill in all required fields.",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    const educBackgroundWithProfileId = {
      ...educBackground,
      id: prof_id,
    };

    const validateEducation = () => {
      const { educ_status } = educBackgroundWithProfileId;

      switch (educ_status) {
        case "Elementary Level (Grade I-VI)":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_level_reached &&
            educBackgroundWithProfileId.elem_yr_graduated
          );
        case "Elementary Graduate":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated
          );
        case "High School Undergraduate (non K-12) (I-IV)":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.high_school_level_reached &&
            educBackgroundWithProfileId.high_yr_graduated
          );
        case "High School Undergraduate (for K-12) (Grade XI)":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.high_school_level_reached &&
            educBackgroundWithProfileId.high_yr_graduated
          );
        case "High School Undergraduate (for K-12) (Grade XII)":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.senior_high_strand &&
            educBackgroundWithProfileId.high_school_level_reached &&
            educBackgroundWithProfileId.high_yr_graduated
          );
        case "High School Graduate":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.senior_high_strand &&
            educBackgroundWithProfileId.high_yr_graduated
          );
        case "Vocational Undergraduate":
          return !(
            educBackgroundWithProfileId.voc_school_name &&
            educBackgroundWithProfileId.voc_course &&
            educBackgroundWithProfileId.voc_level_reached &&
            educBackgroundWithProfileId.voc_yr_graduated
          );
        case "Vocational Graduate":
          return !(
            educBackgroundWithProfileId.voc_school_name &&
            educBackgroundWithProfileId.voc_course &&
            educBackgroundWithProfileId.voc_yr_graduated
          );
        case "College Level (I-V)":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.senior_high_strand &&
            educBackgroundWithProfileId.high_yr_graduated &&
            educBackgroundWithProfileId.college_school_name &&
            educBackgroundWithProfileId.college_course &&
            educBackgroundWithProfileId.college_level_reached &&
            educBackgroundWithProfileId.college_yr_graduated
          );
        case "College Graduate":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.senior_high_strand &&
            educBackgroundWithProfileId.high_yr_graduated &&
            educBackgroundWithProfileId.college_school_name &&
            educBackgroundWithProfileId.college_course &&
            educBackgroundWithProfileId.college_yr_graduated
          );
        case "Masteral/Post Graduate Level":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.senior_high_strand &&
            educBackgroundWithProfileId.high_yr_graduated &&
            educBackgroundWithProfileId.college_school_name &&
            educBackgroundWithProfileId.college_course &&
            educBackgroundWithProfileId.college_yr_graduated &&
            educBackgroundWithProfileId.masteral_school_name &&
            educBackgroundWithProfileId.masteral_course &&
            educBackgroundWithProfileId.masteral_level_reached &&
            educBackgroundWithProfileId.masteral_yr_graduated
          );
        case "Masteral/Post Graduate":
          return !(
            educBackgroundWithProfileId.elem_school_name &&
            educBackgroundWithProfileId.elem_yr_graduated &&
            educBackgroundWithProfileId.high_school_name &&
            educBackgroundWithProfileId.senior_high_strand &&
            educBackgroundWithProfileId.high_yr_graduated &&
            educBackgroundWithProfileId.college_school_name &&
            educBackgroundWithProfileId.college_course &&
            educBackgroundWithProfileId.college_yr_graduated &&
            educBackgroundWithProfileId.masteral_school_name &&
            educBackgroundWithProfileId.masteral_course &&
            educBackgroundWithProfileId.masteral_yr_graduated
          );

        default:
          return true;
      }
    };
    if (validateEducation()) {
      displayAlert({
        message: "Please fill in all required fields.",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    const resetFields = () => {
      const { educ_status } = educBackgroundWithProfileId;

      switch (educ_status) {
        case "Elementary Level (Grade I-VI)":
          return {
            ...educBackgroundWithProfileId,
            high_school_name: "",
            senior_high_strand: "",
            high_school_level_reached: "",
            high_yr_graduated: 0,
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "Elementary Graduate":
          return {
            ...educBackgroundWithProfileId,
            elem_level_reached: "",
            high_school_name: "",
            senior_high_strand: "",
            high_school_level_reached: "",
            high_yr_graduated: 0,
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "High Undergraduate (non K-12) (I-IV)":
          return {
            ...educBackgroundWithProfileId,
            // elem_school_name: "",
            // elem_yr_graduated: 0,
            // high_school_name: "",
            elem_level_reached: "",
            senior_high_strand: "",
            // high_school_level_reached: "",
            // high_yr_graduated: 0,
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "High Undergraduate (for K-12) (Grade XI)":
          return {
            ...educBackgroundWithProfileId,
            // elem_school_name: "",
            // elem_yr_graduated: 0,
            // high_school_name: "",
            elem_level_reached: "",
            senior_high_strand: "",
            // high_school_level_reached: "",
            // high_yr_graduated: 0,
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "High Undergraduate (for K-12) (Grade XII)":
          return {
            ...educBackgroundWithProfileId,
            // elem_school_name: "",
            // elem_yr_graduated: 0,
            // high_school_name: "",
            // senior_high_strand: "",
            // high_school_level_reached: "",
            // high_yr_graduated: 0,
            elem_level_reached: "",
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "High School Graduate":
          return {
            ...educBackgroundWithProfileId,
            high_school_level_reached: "",
            // senior_high_strand: "",
            elem_level_reached: "",
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "Vocational Undergraduate":
          return {
            ...educBackgroundWithProfileId,
            elem_school_name: "",
            elem_level_reached: "",
            elem_yr_graduated: 0,
            high_school_name: "",
            senior_high_strand: "",
            high_yr_graduated: 0,
            high_school_level_reached: "",
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
          };
        case "Vocational Graduate":
          return {
            ...educBackgroundWithProfileId,
            elem_school_name: "",
            elem_level_reached: "",
            elem_yr_graduated: 0,
            high_school_name: "",
            senior_high_strand: "",
            high_yr_graduated: 0,
            high_school_level_reached: "",
            college_school_name: "",
            college_course: "",
            college_level_reached: "",
            college_yr_graduated: 0,
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
          };

        case "College Level (I-V)":
          return {
            ...educBackgroundWithProfileId,
            high_school_level_reached: "",
            elem_level_reached: "",
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "College Graduate":
          return {
            ...educBackgroundWithProfileId,
            high_school_level_reached: "",
            elem_level_reached: "",
            masteral_school_name: "",
            masteral_course: "",
            masteral_level_reached: "",
            masteral_yr_graduated: 0,
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "Masteral/Post Graduate Level":
          return {
            ...educBackgroundWithProfileId,
            elem_level_reached: "",
            high_school_level_reached: "",
            college_level_reached: "",
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        case "Masteral/Post Graduate":
          return {
            ...educBackgroundWithProfileId,
            elem_level_reached: "",
            high_school_level_reached: "",
            college_level_reached: "",
            masteral_level_reached: "",
            voc_school_name: "",
            voc_course: "",
            voc_level_reached: "",
            voc_yr_graduated: 0,
          };
        default:
          return educBackgroundWithProfileId;
      }
    };
    EducBackground(resetFields())
      .then((res) => {
        // console.log("res", res);
        if (res) {
          displayAlert({
            message: "Educational Background saved successfully.",
            severity: "success",
          });

          {
            editMode ? setInitialSave(false) : setInitialSave(true);
          }
        } else {
          displayAlert({
            message: "Unexpected response format from server.",
            severity: "error",
          });
        }
      })
      .catch((error) => {
        displayAlert({
          message:
            error.message ||
            "An error occurred while saving Educational Background.",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Material.Box
      sx={{ width: "100%", padding: 2, overflow: "auto", height: "90%" }}
    >
      <Material.Paper sx={{ padding: 2, marginBottom: 4 }}>
        <Material.Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Educational Background
        </Material.Typography>
        <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={12}>
          <Material.FormControl
            required
            fullWidth
            component="fieldset"
            error={!!errors.educ_type}
          >
            <Material.FormLabel component="legend">Type</Material.FormLabel>
            <Material.RadioGroup
              aria-label="type"
              name="educ_type"
              value={educBackground.educ_type}
              onChange={handleTypeChange}
              row
            >
              <Material.FormControlLabel
                value="Student"
                control={<Material.Radio color="primary" />}
                label="Student"
              />
              <Material.FormControlLabel
                value="ALS Student"
                control={<Material.Radio color="primary" />}
                label="ALS Student"
              />
              <Material.FormControlLabel
                value="Out-of-School(OSY)"
                control={<Material.Radio color="primary" />}
                label="Out-of-School (OSY)"
              />
            </Material.RadioGroup>
          </Material.FormControl>
        </Material.Grid>
        <Material.Grid item xs={12}>
          <Material.Divider style={{ margin: "5px 0" }} />
        </Material.Grid>
        <Material.Grid style={{ paddingTop: 15 }} container spacing={2}>
          <Material.Grid item xs={12} sm={12}>
            <Material.Typography>Educational Attainment*</Material.Typography>
            <Material.FormControl
              paddingTop={15}
              required
              fullWidth
              variant="filled"
              error={!!errors.educ_status}
            >
              <Material.InputLabel id="demo-simple-select-standard-label">
                Highest Educational Attainment
              </Material.InputLabel>
              <Material.Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedAttainment}
                name="educ_status"
                onChange={handleChangeAttainment}
                label="Highest Educational Attainment"
              >
                <Material.MenuItem value="">Select an Option</Material.MenuItem>
                <Material.MenuItem value={"Elementary Level (Grade I-VI)"}>
                  Elementary Level (Grade I-VI)
                </Material.MenuItem>
                <Material.MenuItem value={"Elementary Graduate"}>
                  Elementary Graduate
                </Material.MenuItem>
                <Material.MenuItem
                  value={"High School Undergraduate (non K-12) (I-IV)"}
                >
                  High School Undergraduate (non K-12) (I-IV)
                </Material.MenuItem>
                <Material.MenuItem
                  value={"High School Undergraduate (for K-12) (Grade XI)"}
                >
                  High School Undergraduate (for K-12) (Grade XI)
                </Material.MenuItem>
                <Material.MenuItem
                  value={"High School Undergraduate (for K-12) (Grade XII)"}
                >
                  High School Undergraduate (for K-12) (Grade XII)
                </Material.MenuItem>
                <Material.MenuItem value={"High School Graduate"}>
                  High School Graduate
                </Material.MenuItem>
                <Material.MenuItem value={"Vocational Undergraduate"}>
                  Vocational Undergraduate
                </Material.MenuItem>
                <Material.MenuItem value={"Vocational Graduate"}>
                  Vocational Graduate
                </Material.MenuItem>
                <Material.MenuItem value={"College Level (I-V)"}>
                  College Level (I-V)
                </Material.MenuItem>
                <Material.MenuItem value={"College Graduate"}>
                  College Graduate
                </Material.MenuItem>
                <Material.MenuItem value={"Masteral/Post Graduate Level"}>
                  Masteral/Post Graduate Level
                </Material.MenuItem>
                <Material.MenuItem value={"Masteral/Post Graduate"}>
                  Masteral/Post Graduate
                </Material.MenuItem>
              </Material.Select>
              {errors.educ_status && (
                <Material.FormHelperText>
                  {errors.educ_status}
                </Material.FormHelperText>
              )}
            </Material.FormControl>
          </Material.Grid>

          {(selectedAttainment === "Elementary Level (Grade I-VI)" ||
            selectedAttainment === "Elementary Graduate") && (
            <>
              <Material.Grid item xs={12} sm={12}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="elem_school_name"
                  label="Name of School"
                  name="elem_school_name"
                  value={educBackground.elem_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_school_name}
                  helperText={errors.elem_school_name}
                />
              </Material.Grid>
              {selectedAttainment === "Elementary Level (Grade I-VI)" && (
                <Material.Grid item xs={12} sm={12}>
                  <Material.TextField
                    required
                    fullWidth
                    variant="filled"
                    id="elem_level_reached"
                    label="Elementary Level Reached"
                    name="elem_level_reached"
                    value={educBackground.elem_level_reached}
                    onChange={handleChangeEducBackground}
                    error={!!errors.elem_level_reached}
                    helperText={errors.elem_level_reached}
                  />
                </Material.Grid>
              )}
              <Material.Grid item xs={12} sm={12}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="elem_yr_graduated"
                  label={
                    selectedAttainment === "Elementary Graduate"
                      ? "Year Graduated"
                      : "Last Year Attended"
                  }
                  name="elem_yr_graduated"
                  value={educBackground.elem_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_yr_graduated}
                  helperText={errors.elem_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}
          {selectedAttainment ===
            "High School Undergraduate (non K-12) (I-IV)" && (
            <>
              {/* Elementary Fields */}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="elem_school_name"
                  label="Elementary Name of School"
                  name="elem_school_name"
                  value={educBackground.elem_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_school_name}
                  helperText={errors.elem_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="elem_yr_graduated"
                  label="Elementary Year Graduated"
                  name="elem_yr_graduated"
                  value={educBackground.elem_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_yr_graduated}
                  helperText={errors.elem_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="high_school_name"
                  label="Secondary Name of School"
                  name="high_school_name"
                  value={educBackground.high_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_name}
                  helperText={errors.high_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="high_school_level_reached"
                  label="Level Reached"
                  name="high_school_level_reached"
                  value={educBackground.high_school_level_reached}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_level_reached}
                  helperText={errors.high_school_level_reached}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="high_yr_graduated"
                  label="Secondary Last Year Attended"
                  name="high_yr_graduated"
                  value={educBackground.high_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_yr_graduated}
                  helperText={errors.high_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}

          {selectedAttainment ===
            "High School Undergraduate (for K-12) (Grade XI)" && (
            <>
              {/* High School Fields for Grade XI */}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="elem_school_name"
                  label="Elementary Name of School"
                  name="elem_school_name"
                  value={educBackground.elem_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_school_name}
                  helperText={errors.elem_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="elem_yr_graduated"
                  label="Elementary Year Graduated"
                  name="elem_yr_graduated"
                  value={educBackground.elem_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_yr_graduated}
                  helperText={errors.elem_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="high_school_name"
                  label="Secondary Name of School"
                  name="high_school_name"
                  value={educBackground.high_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_name}
                  helperText={errors.high_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="high_school_level_reached"
                  label="Level Reached"
                  name="high_school_level_reached"
                  value={educBackground.high_school_level_reached}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_level_reached}
                  helperText={errors.high_school_level_reached}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="high_yr_graduated"
                  label="Secondary Last Year Attended"
                  name="high_yr_graduated"
                  value={educBackground.high_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_yr_graduated}
                  helperText={errors.high_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}

          {selectedAttainment ===
            "High School Undergraduate (for K-12) (Grade XII)" && (
            <>
              {/* High School Fields for Grade XII */}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="elem_school_name"
                  label="Elementary Name of School"
                  name="elem_school_name"
                  value={educBackground.elem_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_school_name}
                  helperText={errors.elem_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="elem_yr_graduated"
                  label="Elementary Year Graduated"
                  name="elem_yr_graduated"
                  value={educBackground.elem_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_yr_graduated}
                  helperText={errors.elem_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="high_school_name"
                  label="Secondary Name of School"
                  name="high_school_name"
                  value={educBackground.high_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_name}
                  helperText={errors.high_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="senior_high_strand"
                  label="Senior High Strand"
                  name="senior_high_strand"
                  value={educBackground.senior_high_strand}
                  onChange={handleChangeEducBackground}
                  error={!!errors.senior_high_strand}
                  helperText={errors.senior_high_strand}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="high_school_level_reached"
                  label="Level Reached"
                  name="high_school_level_reached"
                  value={educBackground.high_school_level_reached}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_level_reached}
                  helperText={errors.high_school_level_reached}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="high_yr_graduated"
                  label="Secondary Last Year Attended"
                  name="high_yr_graduated"
                  value={educBackground.high_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_yr_graduated}
                  helperText={errors.high_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}

          {selectedAttainment === "High School Graduate" && (
            <>
              {/* High School Graduate Fields */}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="elem_school_name"
                  label="Elementary Name of School"
                  name="elem_school_name"
                  value={educBackground.elem_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_school_name}
                  helperText={errors.elem_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="elem_yr_graduated"
                  label="Elementary Year Graduated"
                  name="elem_yr_graduated"
                  value={educBackground.elem_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_yr_graduated}
                  helperText={errors.elem_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="high_school_name"
                  label="Secondary Name of School"
                  name="high_school_name"
                  value={educBackground.high_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_name}
                  helperText={errors.high_school_name}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="senior_high_strand"
                  label="Senior High Strand"
                  name="senior_high_strand"
                  value={educBackground.senior_high_strand}
                  onChange={handleChangeEducBackground}
                  error={!!errors.senior_high_strand}
                  helperText={errors.senior_high_strand}
                />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="high_yr_graduated"
                  label="Secondary Year Graduated"
                  name="high_yr_graduated"
                  value={educBackground.high_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_yr_graduated}
                  helperText={errors.high_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}

          {(selectedAttainment === "Vocational Graduate" ||
            selectedAttainment === "Vocational Undergraduate") && (
            <>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="voc_school_name"
                  label="Vocational Name of School"
                  name="voc_school_name"
                  value={educBackground.voc_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.voc_school_name}
                  helperText={errors.voc_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="voc_course"
                  label="Vocational Course"
                  name="voc_course"
                  value={educBackground.voc_course}
                  onChange={handleChangeEducBackground}
                  error={!!errors.voc_course}
                  helperText={errors.voc_course}
                />
              </Material.Grid>
              {selectedAttainment === "Vocational Undergraduate" && (
                <Material.Grid item xs={12} sm={6}>
                  <Material.TextField
                    required
                    fullWidth
                    variant="filled"
                    id="vocational_school"
                    label="Level Reached"
                    name="voc_level_reached"
                    value={educBackground.voc_level_reached}
                    onChange={handleChangeEducBackground}
                    error={!!errors.voc_level_reached}
                    helperText={errors.voc_level_reached}
                  />
                </Material.Grid>
              )}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="voc_yr_graduated"
                  label={
                    selectedAttainment === "Vocational Graduate"
                      ? "Vocational Year Graduated"
                      : "Vocational Last Year Attended"
                  }
                  name={
                    selectedAttainment === "Vocational Graduate"
                      ? "voc_yr_graduated"
                      : "voc_yr_graduated"
                  }
                  value={educBackground.voc_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.voc_yr_graduated}
                  helperText={errors.voc_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}
          {(selectedAttainment === "College Graduate" ||
            selectedAttainment === "College Level (I-V)") && (
            <>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="elem_school"
                  label="Elementary Name of School"
                  name="elem_school_name"
                  value={educBackground.elem_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_school_name}
                  helperText={errors.elem_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="elem_yr_graduated"
                  label="Elementary Year Graduated"
                  name="elem_yr_graduated"
                  value={educBackground.elem_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_yr_graduated}
                  helperText={errors.elem_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12}>
                <Material.Divider style={{ margin: "5px 0" }} />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="secondary_school"
                  label="Secondary Name of School"
                  name="high_school_name"
                  value={educBackground.high_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_name}
                  helperText={errors.high_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="senior_high_strand"
                  label="Senior High Strand"
                  name="senior_high_strand"
                  value={educBackground.senior_high_strand}
                  onChange={handleChangeEducBackground}
                  error={!!errors.senior_high_strand}
                  helperText={errors.senior_high_strand}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="high_yr_graduated"
                  label="Secondary Year Graduated"
                  name="high_yr_graduated"
                  value={educBackground.high_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_yr_graduated}
                  helperText={errors.high_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12}>
                <Material.Divider style={{ margin: "5px 0" }} />
              </Material.Grid>

              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="college_school_name"
                  label="College Name of School"
                  name="college_school_name"
                  value={educBackground.college_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.college_school_name}
                  helperText={errors.college_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="college_course"
                  label="College Course"
                  name="college_course"
                  value={educBackground.college_course}
                  onChange={handleChangeEducBackground}
                  error={!!errors.college_course}
                  helperText={errors.college_course}
                />
              </Material.Grid>
              {selectedAttainment !== "College Graduate" && (
                <Material.Grid item xs={12} sm={6}>
                  <Material.TextField
                    required
                    fullWidth
                    variant="filled"
                    id="college_school"
                    label="Level Reached"
                    name="college_level_reached"
                    value={educBackground.college_level_reached}
                    onChange={handleChangeEducBackground}
                    error={!!errors.college_level_reached}
                    helperText={errors.college_level_reached}
                  />
                </Material.Grid>
              )}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="college_yr_graduated"
                  label={
                    selectedAttainment === "College Graduate"
                      ? "College Year Graduated"
                      : "College Last Year Attended"
                  }
                  name="college_yr_graduated"
                  value={educBackground.college_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.college_yr_graduated}
                  helperText={errors.college_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}
          {(selectedAttainment === "Masteral/Post Graduate" ||
            selectedAttainment === "Masteral/Post Graduate Level") && (
            <>
              {" "}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="elem_school"
                  label="Elementary Name of School"
                  name="elem_school_name"
                  value={educBackground.elem_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_school_name}
                  helperText={errors.elem_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="elem_yr_graduated"
                  label="Elementary Year Graduated"
                  name="elem_yr_graduated"
                  value={educBackground.elem_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.elem_yr_graduated}
                  helperText={errors.elem_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12}>
                <Material.Divider style={{ margin: "5px 0" }} />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="secondary_school"
                  label="Secondary Name of School"
                  name="high_school_name"
                  value={educBackground.high_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_school_name}
                  helperText={errors.high_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="senior_high_strand"
                  label="Senior High Strand"
                  name="senior_high_strand"
                  value={educBackground.senior_high_strand}
                  onChange={handleChangeEducBackground}
                  error={!!errors.senior_high_strand}
                  helperText={errors.senior_high_strand}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="high_yr_graduated"
                  label="Secondary Year Graduated"
                  name="high_yr_graduated"
                  value={educBackground.high_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.high_yr_graduated}
                  helperText={errors.high_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12}>
                <Material.Divider style={{ margin: "5px 0" }} />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="college_school_name"
                  label="College Name of School"
                  name="college_school_name"
                  value={educBackground.college_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.college_school_name}
                  helperText={errors.college_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="college_course"
                  label="College Course"
                  name="college_course"
                  value={educBackground.college_course}
                  onChange={handleChangeEducBackground}
                  error={!!errors.college_course}
                  helperText={errors.college_course}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="college_yr_graduated"
                  label="College Year Graduated"
                  name="college_yr_graduated"
                  value={educBackground.college_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.college_yr_graduated}
                  helperText={errors.college_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
              <Material.Grid item xs={12}>
                <Material.Divider style={{ margin: "5px 0" }} />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="masteral_school_name"
                  label="Masteral/Post Name of School"
                  name="masteral_school_name"
                  value={educBackground.masteral_school_name}
                  onChange={handleChangeEducBackground}
                  error={!!errors.masteral_school_name}
                  helperText={errors.masteral_school_name}
                />
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  id="masteral_course"
                  label="Masteral/Post Course"
                  name="masteral_course"
                  value={educBackground.masteral_course}
                  onChange={handleChangeEducBackground}
                  error={!!errors.masteral_course}
                  helperText={errors.masteral_course}
                />
              </Material.Grid>
              {selectedAttainment === "Masteral/Post Graduate Level" && (
                <Material.Grid item xs={12} sm={6}>
                  <Material.TextField
                    required
                    fullWidth
                    variant="filled"
                    id="masteral_level_reached"
                    label="Level Reached"
                    name="masteral_level_reached"
                    value={educBackground.masteral_level_reached}
                    onChange={handleChangeEducBackground}
                    error={!!errors.masteral_level_reached}
                    helperText={errors.masteral_level_reached}
                  />
                </Material.Grid>
              )}
              <Material.Grid item xs={12} sm={6}>
                <Material.TextField
                  required
                  fullWidth
                  variant="filled"
                  type="number"
                  id="masteral_yr_graduated"
                  label={
                    selectedAttainment === "Masteral/Post Graduate"
                      ? "Masteral Year Graduated"
                      : "Masteral Last Year Attended"
                  }
                  name="masteral_yr_graduated"
                  value={educBackground.masteral_yr_graduated}
                  onChange={handleChangeEducBackground}
                  error={!!errors.masteral_yr_graduated}
                  helperText={errors.masteral_yr_graduated}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    step: "1",
                  }}
                />
              </Material.Grid>
            </>
          )}
        </Material.Grid>
      </Material.Paper>
      <Material.Button
        color="primary"
        onClick={handleSubmitEducBackgroundFn}
        sx={{
          marginLeft: "10px",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#7ab3ef",
          },
        }}
        disabled={initialSave}
      >
        Save
      </Material.Button>
      <LoadingSpinner loading={loading} />
    </Material.Box>
  );
};

export default EducationalBackgroundContent;
