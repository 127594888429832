//OJT - Added Profile.js

import React, { useState, useEffect } from "react";
import * as Material from "@mui/material";
import MyAccountService from "../../../services/MyAccountService";
import { useSelector } from "react-redux";

function MyAccount() {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const users_id = useSelector((state) => state.user.id);

  useEffect(() => {
    // Fetch user details from the backend API using the ProfileService
    MyAccountService.getMyAccountDetails(users_id) // Pass the user ID
      .then((data) => {
        setUserData(data[0]); // Set the first object in the array to userData
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
        setError(error.message);
      });
  }, [users_id]); // Include users_id in the dependency array

  return (
    <Material.Box
      flex={4}
      height="100vh"
      paddingTop={{ xs: 2, md: 2 }}
      style={{ maxWidth: "90%", margin: "auto" }}
    >
      <Material.Paper
        elevation={3}
        style={{
          padding: "20px",
          textAlign: "center",
          maxWidth: "500px",
          margin: "auto",
        }}
      >
        {error ? (
          <p>Error: {error}</p>
        ) : userData ? (
          <div>
            <Material.Typography variant="h4">User Profile</Material.Typography>
            <Material.Avatar
              alt="Profile Picture"
              src={userData.profilePicture}
              sx={{ width: 150, height: 150, margin: "20px auto" }}
            />
            <p>First Name: {userData.firstname}</p>
            <p>Middle Name: {userData.middlename}</p>
            <p>Last Name: {userData.lastname}</p>
            <p>Address: {userData.address}</p>
            <p>Birthdate: {userData.birthdate}</p>
          </div>
        ) : (
          <Material.CircularProgress />
        )}
      </Material.Paper>
    </Material.Box>
  );
}

export default MyAccount;

