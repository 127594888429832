import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Checkbox,
  TextField,
  Typography,
  Modal,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";
import { displayAlert } from "../../common/Alert";
import DoleProgramService, {
  Edit,
  Save,
} from "../../../services/DoleProgramService";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../common/LoadingSpinner";

const defaultStateData = {
  profile_id: "",
  fullname: "",
  address: "",
  programs: [], // AVAILMENT PROGRAMS OF A PROFILE
};

const NUMBER_OF_AVAILMENTS = [1, 2, 3, 4];

function DoleProgramContent({
  InitialProfileData,
  ModalVisible,
  onClose,
  RefreshUser,
}) {
  let isEdit = InitialProfileData && Object.keys(InitialProfileData).length > 0;
  const [editMode, setEditMode] = useState(isEdit);
  const [profiles, setProfiles] = useState([]);
  const profile_id = useSelector((state) => state.profile_id);
  const [usersInput, setUsersInput] = useState(defaultStateData);
  const [dynamicDolePrograms, setDynamicDolePrograms] = useState([]);
  const [selectedDolePrograms, setSelectedDolePrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const isEdit =
      InitialProfileData && Object.keys(InitialProfileData).length > 0;
    setEditMode(isEdit);
  }, [InitialProfileData]);
  // console.log("profile_id", profile_id);

  useEffect(() => {
    if (ModalVisible) {
      fetchProfiles();
      fetchDoleProgramsAvailment();
      setUsersInput({
        fullname: InitialProfileData?.result0?.[0]?.fullname || "",
        address: InitialProfileData?.result0?.[0]?.address || "",
      });

      if (editMode) {
        setUsersInput((prev) => ({
          ...prev,
          programs: selectedDolePrograms.map((program) => program),
        }));
      }
    }
  }, [ModalVisible, InitialProfileData, editMode, selectedDolePrograms]);

  // console.log("Initial", InitialProfileData);

  useEffect(() => {
    if (!editMode) {
      setUsersInput((prevState) => ({
        ...prevState,
        programs: selectedDolePrograms.map((program) => program),
      }));
    }
  }, [selectedDolePrograms, editMode]);

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const data = await DoleProgramService.getDoleProgram();
      setProfiles(data);
      const matchedProfile = data.find((profile) => profile.id === profile_id);
      if (matchedProfile) {
        setUsersInput((prevState) => ({
          ...prevState,
          fullname: matchedProfile.fullname,
          address: matchedProfile.address,
        }));
      }

      // console.log("Matched Profile:", matchedProfile);
    } catch (error) {
      console.error("Error fetching profiles:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchDoleProgramsAvailment = async () => {
    try {
      const data = await DoleProgramService.getDynamicDolePrograms();
      setDynamicDolePrograms(data);
    } catch (error) {
      console.error("Error fetching Dole Programs details:", error);
      setError(error.message);
    }
  };
  const [errors, setErrors] = useState({
    name: false,
    address: false,
    programs: false,
    availments: [],
  });

  const onSave = () => {
    if (editMode) {
      const data = {
        id: InitialProfileData ? InitialProfileData.result0[0].id : profile_id,
        date_from: selectedDolePrograms[0].availments[0].periodFrom,
        date_to: selectedDolePrograms[0].availments[0].periodTo,
      };
      // console.log("datatosave", data);

      onClose();
      setLoading(true);
      Edit(data)
        .then((res) => {
          if (res.error) {
            displayAlert({
              message: res.message,
              severity: "error",
            });
          } else {
            displayAlert({
              message: `Dole Program edited successfully. ${res.message}!`,
              severity: "success",
            });

            window.location.reload();
            RefreshUser();
            resetState();
            onClose();
          }
        })
        .catch((err) => {
          displayAlert({
            message: err,
            severity: "error",
          });
        })
        .finally(() => setLoading(false));
      resetState();

      return;
    }

    let hasAvailments;
    if (usersInput.programs.length > 0) {
      hasAvailments = usersInput.programs.some(
        (program) => program.availments.length > 0
      );
    }

    let availmentsAreFilled;
    if (hasAvailments) {
      availmentsAreFilled = usersInput.programs.every(
        (program) =>
          program.doleProgramId !== "" &&
          program.program !== "" &&
          program.availments.every(
            (availment) => availment.order !== "" && availment.programId !== ""
          )
      );
    }
    // console.log("usersinputTosave", usersInput);
    const updatedUsersInput = {
      ...usersInput,
      profile_id: InitialProfileData
        ? InitialProfileData.result0[0].id
        : profile_id,
    };
    // console.log("updatedUsersInput", updatedUsersInput);
    if (
      updatedUsersInput.fullname &&
      updatedUsersInput.address &&
      updatedUsersInput.programs.length > 0 &&
      availmentsAreFilled
    ) {
      onClose();
      setLoading(true);
      Save(updatedUsersInput)
        .then((res) => {
          if (res.error) {
            displayAlert({
              message: res.message,
              severity: "error",
            });
          } else {
            displayAlert({
              message: `Dole Program saved successfully. ${res.message}!`,
              severity: "success",
            });

            window.location.reload();
            RefreshUser();
            resetState();
            onClose();
          }
        })
        .catch((err) => {
          displayAlert({
            message: err,
            severity: "error",
          });
        })
        .finally(() => setLoading(false));
      resetState();
    } else {
      displayAlert({
        message: "Please fill all the required fields!",
        severity: "warning",
      });
    }
  };

  const onCancel = () => {
    resetState();
    onClose();
  };

  const resetState = () => {
    setUsersInput(defaultStateData);
    setSelectedDolePrograms([]);
    setEditMode(false);
  };

  return (
    <Modal
      open={ModalVisible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box
        width={900}
        maxHeight="80vh"
        bgcolor={"background.default"}
        color={"text.primary"}
        p={3}
        borderRadius={3}
        sx={{
          overflow: "auto",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
          {editMode ? "EDIT DOLE PROGRAM" : "ADD NEW DOLE PROGRAM"}
        </Typography>
        <Grid container spacing={4} sx={{ padding: "16px" }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Fullname"
              value={usersInput.fullname}
              InputProps={{
                readOnly: true,
                style: { backgroundColor: "#f0f0f0" },
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Address"
              value={usersInput.address}
              InputProps={{
                readOnly: true,
                style: { backgroundColor: "#f0f0f0" },
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {dynamicDolePrograms.map((program) => (
            <DoleProgramCheckBox
              key={program.id}
              program={program}
              selectedDolePrograms={selectedDolePrograms}
              setSelectedDolePrograms={setSelectedDolePrograms}
            />
          ))}
        </Grid>
        {selectedDolePrograms.length > 0 && (
          <Stack sx={{ mt: 4 }}>
            {selectedDolePrograms.map((program) => (
              <Box key={program.doleProgramId} sx={{ mt: 2 }}>
                <Typography variant="h6">
                  History of {program.program.toUpperCase()} Availment
                </Typography>
                <Table size="small">
                  <TableBody>
                    {NUMBER_OF_AVAILMENTS.map((_, i) => (
                      <DoleProgramAvailments
                        key={i}
                        index={i}
                        program={program}
                        selectedDolePrograms={selectedDolePrograms}
                        setSelectedDolePrograms={setSelectedDolePrograms}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ))}
          </Stack>
        )}

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            sx={{ mr: 2 }}
          >
            Save
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
        {loading && <LoadingSpinner />}
      </Box>
    </Modal>
  );
}

export default DoleProgramContent;

function DoleProgramCheckBox({
  program,
  selectedDolePrograms,
  setSelectedDolePrograms,
}) {
  const [isChecked, setIsChecked] = useState(false);

  const toggleProgramSelection = () => {
    if (isChecked) {
      setSelectedDolePrograms(
        selectedDolePrograms.filter((p) => p.doleProgramId !== program.id)
      );
    } else {
      setSelectedDolePrograms([
        ...selectedDolePrograms,
        { ...program, availments: [] },
      ]);
    }
    setIsChecked(!isChecked);
  };

  return (
    <Grid item xs={3}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={() => {
              if (isChecked) {
                setSelectedDolePrograms(
                  selectedDolePrograms.filter(
                    (selectedProgram) =>
                      selectedProgram.doleProgramId !== program.id
                  )
                );
              } else {
                setSelectedDolePrograms([
                  ...selectedDolePrograms,
                  {
                    doleProgramId: program.id,
                    program: program.program,
                    availments: [],
                  },
                ]);
              }
              setIsChecked(!isChecked);
            }}
          />
        }
        label={program.program}
        // disabled={editMode}
      />
    </Grid>
  );
}
function DoleProgramAvailments({ index, program, setSelectedDolePrograms }) {
  const initialState =
    program.availments[0] && program.availments[0].order === index + 1
      ? program.availments[0]
      : {
          order: index + 1,
          periodFrom: "",
          periodTo: "",
          programId: "",
        };

  const [currentAvailmentInput, setCurrentAvailmentInput] =
    useState(initialState);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (program.availments[0] && program.availments[0].order === index + 1) {
      setIsChecked(true);
    }
  }, [program.availments, index]);

  const isJobStartProgram = program?.program === "JOBSTART";

  function getOrdinalSuffix(n, isJobStartProgram) {
    const currentYear = new Date().getFullYear();

    if (isJobStartProgram) {
      if (n === 1) return `${currentYear} Wave 1 Roll-Out`;
      if (n === 2) return `${currentYear} Wave 2 Roll-Out`;
      return `${n}th`;
    } else {
      if (n === 1) return "1st";
      if (n === 2) return "2nd";
      if (n === 3) return "3rd";
      return `${n}th`;
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setCurrentAvailmentInput((prev) => ({
        ...prev,
        order: index + 1,
        programId: "",
      }));
    }
  };

  const handleInputChange = (field, value) => {
    setCurrentAvailmentInput((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    setSelectedDolePrograms((prevSelectedDolePrograms) => {
      return prevSelectedDolePrograms.map((p) => {
        if (p.doleProgramId === program.doleProgramId) {
          const filteredAvailment = p.availments.filter(
            (fa) => fa.order !== currentAvailmentInput.order
          );
          return {
            ...p,
            availments: isChecked
              ? [...filteredAvailment, currentAvailmentInput]
              : filteredAvailment,
          };
        }
        return p;
      });
    });
  }, [currentAvailmentInput, isChecked, program.doleProgramId]);

  return (
    <TableRow key={index}>
      {isJobStartProgram && index < 2 && (
        <>
          <TableCell>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  name={`${program.program}_Wave_${
                    index + 1
                  }_ Roll-Out ${new Date().getFullYear()}`}
                />
              }
              label={getOrdinalSuffix(index + 1, true)}
            />
          </TableCell>
          <TableCell>
            <TextField
              label="Availment Date"
              type="date"
              variant="standard"
              name={`period_from_${index}`}
              value={currentAvailmentInput.periodFrom || ""}
              onChange={(e) => handleInputChange("periodFrom", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              label="Period To"
              type="date"
              variant="standard"
              name={`period_to_${index}`}
              value={currentAvailmentInput.periodTo || ""}
              onChange={(e) => handleInputChange("periodTo", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              variant="standard"
              label={`${program.program?.toUpperCase() || ""} ID No.`}
              name={`${program.program}_id_no_${index}`}
              value={currentAvailmentInput.programId || ""}
              onChange={(e) => handleInputChange("programId", e.target.value)}
              disabled={!isChecked}
            />
          </TableCell>
        </>
      )}
      {!isJobStartProgram && (
        <>
          <TableCell>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  name={`${program.program}_availment_${index + 1}`}
                />
              }
              label={getOrdinalSuffix(index + 1, false)}
            />
          </TableCell>
          <TableCell>
            <TextField
              label="Period From"
              type="date"
              variant="standard"
              name={`period_from_${index}`}
              value={currentAvailmentInput.periodFrom || ""}
              onChange={(e) => handleInputChange("periodFrom", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              label="Period To"
              type="date"
              variant="standard"
              name={`period_to_${index}`}
              value={currentAvailmentInput.periodTo || ""}
              onChange={(e) => handleInputChange("periodTo", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              variant="standard"
              label={`${program.program?.toUpperCase() || ""} ID No.`}
              name={`${program.program}_id_no_${index}`}
              value={currentAvailmentInput.programId || ""}
              onChange={(e) => handleInputChange("programId", e.target.value)}
              disabled={!isChecked}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
