import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const displayAlert = (params) => {
    // sample params:
    // message: your_message
    // severity: success | error | warning | info

    switch (params.severity) {
        case 'success':
            toast.success(
                params.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            break;
        case 'error':
            toast.error(
                params.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            break;
        case 'warning':
            toast.warning(
                params.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            break;
        case 'info':
            toast.info(
                params.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            break;

        default:
            break;
    }
};