import { Shuffle, Visibility, VisibilityOff } from "@mui/icons-material";
import * as Material from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { displayAlert } from "../../common/Alert";
import { ChangePassword } from "../../../services/UsersService";
import { ForceLogout } from "../../../helpers/AxiosHelper";
import LoadingSpinner from "../../common/LoadingSpinner";

const StyledModal = Material.styled(Material.Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const defaultStateData = {
  id: 0,
  password: "",
  new_password: "",
  verify_password: "",
  firstLoad: true,
  isPasswordStrong: false,
};

function ChangePasswordModal({ ModalVisible, HideModal }) {
  const user_id = useSelector((state) => state.user.id);
  const [showPassword, setShowPassword] = useState(false);
  const [usersInput, setUsersInput] = useState(defaultStateData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ModalVisible) {
      setUsersInput((prevState) => ({
        ...prevState,
        id: user_id,
        firstLoad: true,
      }));
    }
  }, [ModalVisible, user_id]);

  const onHandleChange = (e) => {
    setUsersInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onHandleChangeNewPass = (e) => {
    const newPassword = e.target.value;
    const isPasswordStrong =
      /[a-zA-Z]/.test(newPassword) &&
      /\d/.test(newPassword) &&
      /[A-Z]/.test(newPassword) &&
      /[a-z]/.test(newPassword) &&
      /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(newPassword);

    setUsersInput((prevState) => ({
      ...prevState,
      new_password: newPassword,
      isPasswordStrong,
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const generatePassword = () => {
    const randomPassword =
      Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
    setUsersInput((prevState) => ({
      ...prevState,
      new_password: randomPassword,
    }));
    navigator.clipboard.writeText(randomPassword);
    displayAlert({
      message: "Password is copied to clipboard.",
      severity: "info",
    });
  };

  const closeModal = () => {
    HideModal();
    setLoading(false);
    resetStateData();
  };

  const resetStateData = () => {
    setUsersInput((prevState) => ({
      ...prevState,
      password: "",
      new_password: "",
      verify_password: "",
    }));
  };

  const changePassword = () => {
    setLoading(true);
    if (!usersInput.password) {
      setLoading(false);
      displayAlert({
        message: "Please fill in password",
        severity: "warning",
      });
    } else if (!usersInput.isPasswordStrong) {
      setLoading(false);
      displayAlert({
        message: "Password does not meet the requirements.",
        severity: "warning",
      });
    } else if (usersInput.new_password !== usersInput.verify_password) {
      setLoading(false);
      displayAlert({
        message: "Passwords do not match.",
        severity: "warning",
      });
    } else {
      HideModal();
      ChangePassword(usersInput)
        .then((res) => {
          if (res.error) {
            setLoading(false);
            displayAlert({
              message: res.message,
              severity: "error",
            });
          } else {
            setLoading(false);
            displayAlert({
              message: "Password changed successfully!",
              severity: "success",
            });
            setLoading(false);
            ForceLogout();
          }
        })
        .catch((err) => {
          displayAlert({
            message: err,
            severity: "error",
          });
        });
    }
    setUsersInput((prevState) => ({
      ...prevState,
      firstLoad: false,
    }));
  };

  return (
    <div>
      <StyledModal
        open={ModalVisible}
        onClose={HideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Material.Box
          maxWidth={400}
          maxHeight={"100%"}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={4}
          borderRadius={3}
          paddingBottom={2}
        >
          <Material.DialogTitle id="alert-dialog-title" color="gray">
            Change Password
          </Material.DialogTitle>
          <Material.DialogContent>
            <Material.DialogContentText id="alert-dialog-description">
              <Material.FormControl fullWidth variant="outlined" margin="dense">
                <Material.InputLabel htmlFor="outlined-adornment-password">
                  Password
                </Material.InputLabel>
                <Material.OutlinedInput
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={usersInput.password}
                  onChange={onHandleChange}
                  error={!usersInput.firstLoad && !usersInput.password}
                  variant="outlined"
                  endAdornment={
                    <Material.InputAdornment position="end">
                      <Material.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Material.IconButton>
                    </Material.InputAdornment>
                  }
                  label="Password"
                />
              </Material.FormControl>
              <Material.FormControl fullWidth variant="outlined" margin="dense">
                <Material.InputLabel htmlFor="outlined-adornment-new-password">
                  New Password
                </Material.InputLabel>
                <Material.OutlinedInput
                  name="new_password"
                  type={showPassword ? "text" : "password"}
                  value={usersInput.new_password}
                  onChange={onHandleChangeNewPass}
                  error={!usersInput.firstLoad && !usersInput.isPasswordStrong}
                  variant="outlined"
                  endAdornment={
                    <Material.InputAdornment position="end">
                      <Material.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Material.IconButton>
                      <Material.Tooltip title="Generate Random Password">
                        <Material.IconButton onClick={generatePassword} edge="end">
                          <Shuffle />
                        </Material.IconButton>
                      </Material.Tooltip>
                    </Material.InputAdornment>
                  }
                  label="New Password"
                />
              </Material.FormControl>
              <Material.FormControl fullWidth variant="outlined" margin="dense">
                <Material.InputLabel htmlFor="outlined-adornment-verify-password">
                  Verify Password
                </Material.InputLabel>
                <Material.OutlinedInput
                  name="verify_password"
                  type={showPassword ? "text" : "password"}
                  value={usersInput.verify_password}
                  onChange={onHandleChange}
                  error={!usersInput.firstLoad && !usersInput.verify_password}
                  variant="outlined"
                  endAdornment={
                    <Material.InputAdornment position="end">
                      <Material.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Material.IconButton>
                    </Material.InputAdornment>
                  }
                  label="Verify Password"
                />
              </Material.FormControl>
              {!usersInput.isPasswordStrong && (
                <Material.Typography
                  variant="body2"
                  color="error"
                  className="password-requirements"
                >
                  Password must contain characters, digits, uppercase letters,
                  lowercase letters, and symbols.
                </Material.Typography>
              )}
            </Material.DialogContentText>
          </Material.DialogContent>
          <Material.DialogActions paddingBottom={1}>
            <Material.Button
              variant="contained"
              color="primary"
              onClick={changePassword}
              autoFocus
            >
              Save
            </Material.Button>
            <Material.Button variant="text" color="secondary" onClick={closeModal}>
              Cancel
            </Material.Button>
          </Material.DialogActions>
        </Material.Box>
      </StyledModal>
      <LoadingSpinner loading={loading} />
    </div>
  );
}

export default ChangePasswordModal;
