import { Visibility, VisibilityOff, Shuffle } from "@mui/icons-material";
import { Modal, styled } from "@mui/material";
import * as Material from "@mui/material";
import React, { useEffect, useState } from "react";
import { displayAlert } from "../../common/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import LoadingSpinner from "../../common/LoadingSpinner";
import EducationalBackgroundContent from "./EducationalBackground";
import Myskills from "../../../services/EmploymentStatusService";
import {
  ProfileInfoSave,
  GetMunicipality,
  GetBarangay,
} from "../../../services/ClientProfileService";
//import DoleProgramContent from "./DoleProgram";
import EmploymentStatusContent from "./EmploymentStatus";
import TrainingAndVocationalForm from "./TrainingAndVocational";
import {
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material"; // Added Dialog, DialogTitle, DialogActions, Button

import Checkbox from "@mui/material/Checkbox";
import DoleProgramContent from "./DoleProgram";

const SytledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const defaultStateData = {
  id: 0,
  lastname: "",
  firstname: "",
  middlename: "",
  suffix: "",
  birthdate: "",
  age: 0,
  religion: "",
  civil_status: "",
  gender: "",
  email: "",
  landline: "",
  mobile: "",
  province_name: "",
  barangay_name: "",
  barangay_id: 0,
  postal_code: "",
  educ_status: "",
  street_address: "",
  res_already_exist: 0,
  // province_id: 1,
  // municipal_id: 0,
  activeStep: 0,
  added_by: 0,
  municipal_name: "",
  // added
  permanent_province_name: "",
  permanent_municipality: "",
  permanent_postal_code: "",
  permanent_barangay: "",
  permanent_barangay_id: 0,
  permanent_street_address: "",
  fathers_name: "",
  occupation_father: "",
  contact_no_father: "",
  mothers_name: "",
  occupation_mother: "",
  contact_no_mother: "",
  parents_civil_status: "",
  parents_other_info: "",
  parents_unemployment_type: "",
  govt_id_no: "",
  type_of_govt_id: "",
  height_ft: "",
  disabilities: "",
  other_disability: "",
  dialect: "",
  other_dialect: "",
};

const initialStateData = {
  id: 0,
  lastname: "",
  firstname: "",
  middlename: "",
  suffix: "",
  birthdate: "",
  age: 0,
  religion: "",
  civil_status: "",
  gender: "",
  email: "",
  landline: "",
  mobile: "",
  province_name: "",
  barangay_name: "",
  postal_code: "",
  // educ_status: "",
  street_address: "",
  res_already_exist: 0,
  // province_id: 1,
  // municipal_id: 0,
  activeStep: 0,
  added_by: 0,
  municipal_name: "",
  // added
  permanent_province_name: "",
  permanent_municipality: "",
  permanent_postal_code: "",
  permanent_barangay: "",
  permanent_street_address: "",
  fathers_name: "",
  occupation_father: "",
  contact_no_father: "",
  mothers_name: "",
  occupation_mother: "",
  contact_no_mother: "",
  parents_civil_status: "",
  parents_other_info: "",
  parents_unemployment_type: "",
  govt_id_no: "",
  type_of_govt_id: "",
  height_ft: "",
  disabilities: "",
  other_disability: "",
  dialect: "",
  other_dialect: "",
};

const steps = [
  "Personal Information",
  "Educational Background",
  "Training and Vocational Course",
  "Employment Status",
];
const label = { inputProps: { "aria-label": "Checkbox demo" } };

// export function OtherSkillCheckBoxes() {
//   return (
//     <Material.Paper>
//       <Material.Typography
//         width={"50%"}
//         // variant="h6"
//         color="gray"
//         textAlign="left"
//         sx={{ padding: 2 }}
//       >
//         Other Skills
//       </Material.Typography>
//       <Material.FormGroup sx={{ padding: 2 }}>
//         <Material.FormControlLabel
//           control={<Checkbox defaultChecked />}
//           label="Label"
//         />
//       </Material.FormGroup>
//     </Material.Paper>
//   );
// }

function MainFormModal({
  ModalVisible,
  HideModal,
  InitialProfileData,
  handleSubmitEducBackground,
  fetchProfiles,
  handleSubmitEmploymentStatusFn,
}) {
  const user_id = useSelector((state) => state.user.id || 0);
  const editMode =
    InitialProfileData && Object.keys(InitialProfileData).length > 0;
  // console.log("InitialProfileData", InitialProfileData);

  useEffect(() => {
    if (ModalVisible) {
      const initialState = editMode
        ? {
            id: InitialProfileData.result0[0].id,
            lastname: InitialProfileData.result0[0].lastname,
            firstname: InitialProfileData.result0[0].firstname,
            middlename: InitialProfileData.result0[0].middlename,
            suffix: InitialProfileData.result0[0].suffix,
            birthdate: InitialProfileData.result0[0].birthdate,
            age: InitialProfileData.result0[0].age,
            religion: InitialProfileData.result0[0].religion,
            civil_status: InitialProfileData.result0[0].civil_status,
            gender: InitialProfileData.result0[0].gender,
            email: InitialProfileData.result0[0].email,
            landline: InitialProfileData.result0[0].landline,
            mobile: InitialProfileData.result0[0].mobile,
            province_name: InitialProfileData.result0[0].province,
            barangay_name: InitialProfileData.result0[0].barangay_name,
            postal_code: InitialProfileData.result0[0].postal_code,
            // educ_status: InitialProfileData.result0[0].educ_status,
            street_address: InitialProfileData.result0[0].street_address,
            added_by: InitialProfileData.result0[0].added_by,
            municipal_name: InitialProfileData.result0[0].municipality,
            // added

            permanent_province_name:
              InitialProfileData.result0[0].permanent_province_name,
            permanent_municipality:
              InitialProfileData.result0[0].permanent_municipality,
            permanent_postal_code:
              InitialProfileData.result0[0].permanent_postal_code,
            permanent_barangay:
              InitialProfileData.result0[0].permanent_barangay,
            permanent_street_address:
              InitialProfileData.result0[0].permanent_street_address,
            fathers_name: InitialProfileData.result0[0].fathers_name,
            occupation_father: InitialProfileData.result0[0].occupation_father,
            contact_no_father: InitialProfileData.result0[0].contact_no_father,
            mothers_name: InitialProfileData.result0[0].mothers_name,
            occupation_mother: InitialProfileData.result0[0].occupation_mother,
            contact_no_mother: InitialProfileData.result0[0].contact_no_mother,
            parents_civil_status:
              InitialProfileData.result0[0].parents_civil_status,
            parents_other_info: InitialProfileData.result0[0].parents_other_info
              ? getIdsFromNames(
                  InitialProfileData.result0[0].parents_other_info
                )
              : [],
            parents_unemployment_type:
              InitialProfileData.result0[0].parents_unemployment_type,
            govt_id_no: InitialProfileData.result0[0].govt_id_no,
            type_of_govt_id: InitialProfileData.result0[0].type_of_govt_id,
            height_ft: InitialProfileData.result0[0].height_ft,
            disabilities: InitialProfileData.result0[0].disabilities
              ? getIdsFromNamesDisability(
                  InitialProfileData.result0[0].disabilities
                )
              : [],
            other_disability: InitialProfileData.result0[0].other_disability,
            dialect: InitialProfileData.result0[0].dialect
              ? getIdsFromNamesDialect(InitialProfileData.result0[0].dialect)
              : [],
            other_dialect: InitialProfileData.result0[0].other_dialect,
          }
        : initialStateData;
      setProfileInfo(initialState);
      // let profile_id = 372;
      // getProfile(profile_id);
      // console.log("initialState", initialState);
    }
  }, [ModalVisible]);

  const [usersInput, setUsersInput] = useState(defaultStateData);
  const [doneFirstLoad, setDoneFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [showDoleProgram, setShowDoleProgram] = useState(false);
  const [showDoleQuestion, setShowDoleQuestion] = useState(false);
  const navigate = useNavigate();
  const [showSteps, setShowSteps] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    if (activeStep === steps.length - 1) {
      setShowSteps(false);
    } else {
      setShowSteps(true);
    }
  }, [activeStep, steps.length]);

  const handleCloseDoleProgram = () => {
    setShowDoleProgram(false); // Hide the Dole Program form
    navigate("/profiles");
  };

  const [skipped, setSkipped] = React.useState(new Set());
  const [skillset, setSkills] = useState([]);
  const [listOfBarangay, setListOfBarangay] = useState([]);

  const [listOfBarangayPermanent, setListOfPermanentBarangay] = useState([]);

  const [municipalities, setMunicipalities] = useState([]);
  const [municipal_id, setMunicipalID] = useState("");
  const [permanent_municipal_id, setMunicipalIDPermanent] = useState("");
  const [barangay_name, setBarangayName] = useState("");
  const [permanent_barangay, setBarangayNamePermanent] = useState("");
  const [province_id, setProvinceID] = useState("");
  const [permanent_province_id, setProvinceIDPermanent] = useState("");
  const [newdata, setnewdata] = useState("");
  const [profileInfo, setProfileInfo] = React.useState(initialStateData);
  const [formValid, setFormValid] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [originalFormData, setOriginalFormData] = useState(defaultStateData);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [res_already_exist, setResAlreadyExist] = React.useState(0);
  const [prevProfilesInfo, setPrevProfiles] = React.useState(initialStateData);
  const [sameAsPermanent, setSameAsPermanent] = React.useState(false);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const lettersRegex = /^[A-Za-z ]+$/;
  const numbersRegex = /^[0-9]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (activeStep === 4) {
      setShowDoleQuestion(true);
    }
  }, [activeStep]);
  // Initial Data Loading and Profile Setup
  useEffect(() => {
    if (ModalVisible) {
      const initialState = editMode
        ? {
            ...InitialProfileData.result0[0],
            province_name: InitialProfileData.result0[0].province,
            municipal_name: InitialProfileData.result0[0].municipality,
            barangay_name: InitialProfileData.result0[0].barangay_name,
            permanent_province_name:
              InitialProfileData.result0[0].permanent_province_name,
            permanent_municipality:
              InitialProfileData.result0[0].permanent_municipality,
            permanent_barangay:
              InitialProfileData.result0[0].permanent_barangay,
            parents_other_info: getIdsFromNames(
              InitialProfileData.result0[0].parents_other_info || ""
            ),
            disabilities: getIdsFromNamesDisability(
              InitialProfileData.result0[0].disabilities || ""
            ),
            dialect: getIdsFromNamesDialect(
              InitialProfileData.result0[0].dialect || ""
            ),
          }
        : initialStateData;

      setProfileInfo(initialState);
      setPrevProfiles(initialState);
    }
  }, [ModalVisible, InitialProfileData, editMode]);

  const provinces = [
    // {
    //   province_id: "0",
    //   province_name: "Select Province",
    // },
    { province_id: "1", province_name: "BOHOL" },

    // Add more provinces as needed
  ];

  useEffect(() => {
    const province = provinces.find(
      (p) => p.province_name === profileInfo.province_name
    );
    if (province) setProvinceID(province.province_id);

    const permanentProvince = provinces.find(
      (p) => p.province_name === profileInfo.permanent_province_name
    );
    if (permanentProvince)
      setProvinceIDPermanent(permanentProvince.province_id);
  }, [
    profileInfo.province_name,
    profileInfo.permanent_province_name,
    provinces,
  ]);

  // Update Municipal ID Based on Profile Info Changes
  useEffect(() => {
    const municipality = municipalities.find(
      (m) => m.municipal_name === profileInfo.municipal_name
    );
    if (municipality) setMunicipalID(municipality.municipal_id);

    const permanentMunicipality = municipalities.find(
      (m) => m.municipal_name === profileInfo.permanent_municipality
    );
    if (permanentMunicipality)
      setMunicipalIDPermanent(permanentMunicipality.municipal_id);
  }, [
    profileInfo.municipal_name,
    profileInfo.permanent_municipality,
    municipalities,
  ]);
  // Fetch Municipalities and Barangays when IDs change
  useEffect(() => {
    if (province_id) getMunicipality(province_id);
    if (permanent_province_id) getMunicipality(permanent_province_id);
  }, [province_id, permanent_province_id]);

  useEffect(() => {
    if (municipal_id) getBarangaysForMunicipal(municipal_id, "present");
    if (permanent_municipal_id)
      getBarangaysForMunicipal(permanent_municipal_id, "permanent");
  }, [municipal_id, permanent_municipal_id]);

  useEffect(() => {
    const barangay = listOfBarangay.find(
      (b) => b.barangay_name === profileInfo.barangay_name
    );
    if (barangay) setBarangayName(barangay.barangay_name);

    const permanentBarangay = listOfBarangayPermanent.find(
      (b) => b.barangay_name === profileInfo.permanent_barangay
    );
    if (permanentBarangay)
      setBarangayNamePermanent(permanentBarangay.barangay_name);
  }, [
    profileInfo.barangay_name,
    profileInfo.permanent_barangay,
    listOfBarangay,
    listOfBarangayPermanent,
  ]);

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);

    // Ignore time of day, set time to start of day (midnight)
    birthDate.setUTCHours(0, 0, 0, 0);
    today.setUTCHours(0, 0, 0, 0);

    let age = today.getUTCFullYear() - birthDate.getUTCFullYear();
    const monthDiff = today.getUTCMonth() - birthDate.getUTCMonth();
    const dayDiff = today.getUTCDate() - birthDate.getUTCDate();

    // Adjust age if the birthdate has not occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  };

  // Handle Province Selection
  const handleSelectProvince = (event) => {
    const selectedProvinceName = event.target.value;
    const selectedProvince = provinces.find(
      (p) => p.province_name === selectedProvinceName
    );

    if (selectedProvince) {
      setProvinceID(selectedProvince.province_id);
      setProfileInfo((prev) => ({
        ...prev,
        province_name: selectedProvinceName,
        municipal_name: "",
      }));
    }
  };

  const handleSelectProvincePermanent = (event) => {
    const selectedProvinceName = event.target.value;
    const selectedProvince = provinces.find(
      (p) => p.province_name === selectedProvinceName
    );

    if (selectedProvince) {
      setProvinceIDPermanent(selectedProvince.province_id);
      setProfileInfo((prev) => ({
        ...prev,
        permanent_province_name: selectedProvinceName,
        permanent_municipality: "",
      }));
    }
  };

  const handleSelectMunicipality = (event) => {
    const selectedMunicipalName = event.target.value;
    const selectedMunicipality = municipalities.find(
      (m) => m.municipal_name === selectedMunicipalName
    );

    if (selectedMunicipality) {
      setMunicipalID(selectedMunicipality.municipal_id);
      setProfileInfo((prev) => ({
        ...prev,
        municipal_name: selectedMunicipalName,
        barangay_name: "", // Clear barangay on municipality change
      }));
    }
  };

  const handleSelectMunicipalityPermanent = (event) => {
    const selectedMunicipalNamePermanent = event.target.value;
    const selectedMunicipalityPermanent = municipalities.find(
      (m) => m.municipal_name === selectedMunicipalNamePermanent
    );

    if (selectedMunicipalityPermanent) {
      setMunicipalIDPermanent(selectedMunicipalityPermanent.municipal_id);
      setProfileInfo((prev) => ({
        ...prev,
        permanent_municipality: selectedMunicipalNamePermanent,
        permanent_barangay: "", // Clear barangay on municipality change
      }));
    }
  };

  // Handle Barangay Selection
  const handleSelectBarangay = (event) => {
    const selectedBarangayName = event.target.value;
    const selectedBarangay = listOfBarangay.find(
      (b) => b.barangay_name === selectedBarangayName
    );

    if (selectedBarangay) {
      setBarangayName(selectedBarangay.barangay_name);
      setProfileInfo((prev) => ({
        ...prev,
        barangay_name: selectedBarangayName,
        barangay_id: selectedBarangay.barangay_id,
      }));
    }
  };

  const handleSelectBarangayPermanent = (event) => {
    const selectedBarangayNamePermanent = event.target.value;
    const selectedBarangayPermanent = listOfBarangayPermanent.find(
      (b) => b.barangay_name === selectedBarangayNamePermanent
    );

    if (selectedBarangayPermanent) {
      setBarangayNamePermanent(selectedBarangayPermanent.barangay_name);
      setProfileInfo((prev) => ({
        ...prev,
        permanent_barangay: selectedBarangayNamePermanent,

        permanent_barangay_id: selectedBarangayPermanent.barangay_id,
      }));
    }
  };

  // Fetch Municipalities and Barangays
  const getMunicipality = (province_id) => {
    GetMunicipality(province_id)
      .then((res) => {
        if (res.error) {
          displayAlert({ message: res.message, severity: "error" });
        } else {
          setMunicipalities(res.municipalities || []);
        }
      })
      .catch((error) =>
        displayAlert({ message: error.message, severity: "error" })
      );
  };

  const getBarangaysForMunicipal = (municipalId, type) => {
    // console.log("municipal-id", municipalId);

    GetBarangay(municipalId)
      .then((res) => {
        if (res.error) {
          displayAlert({ message: res.message, severity: "error" });
        } else {
          const list = res.listOfBarangay || [];
          if (type === "present") {
            setListOfBarangay(list); // Update present address barangay list.
          } else if (type === "permanent") {
            setListOfPermanentBarangay(list); // Update permanent address barangay list.
          }
        }
      })
      .catch((error) =>
        displayAlert({ message: error.message, severity: "error" })
      );
  };

  // Handler to copy present address to permanent when checkbox is checked
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSameAsPermanent(isChecked);

    if (isChecked) {
      setProfileInfo((prev) => ({
        ...prev,
        permanent_province_name: profileInfo.province_name,
        permanent_municipality: profileInfo.municipal_name,
        permanent_postal_code: profileInfo.postal_code,
        permanent_barangay: profileInfo.barangay_name,
        permanent_street_address: profileInfo.street_address,
      }));
    } else {
      setProfileInfo((prev) => ({
        ...prev,
        permanent_province_name: "",
        permanent_municipality: "",
        permanent_postal_code: "",
        permanent_barangay: "",
        permanent_street_address: "",
      }));
    }
  };
  const GetSkills = () => {
    Myskills.getSkills()
      .then((data) => {
        if (data != null) {
          setSkills(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching skills", error);
        // setError(error.message);
      });
  };

  // For Stepper
  // const isStepOptional = (step) => {
  //   return step === 2;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      // Only show the DoleQuestion when adding (not in edit mode)
      if (!editMode) {
        setShowDoleQuestion(true);
      } else {
        HideModal();
        setActiveStep(0);
        fetchProfiles();
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setSkipped(newSkipped);

    if (activeStep === 1) {
      setLoading(true);
      displayAlert({
        message:
          "Please ensure your inputs are SAVED before moving to the next step.",
        severity: "warning",
      });
      setLoading(false);
    }
  };

  const handleDoleResponse = (response) => {
    if (response === "yes") {
      setShowDoleProgram(true); // Show the DoleProgram form
      setShowDoleQuestion(false); // Hide the question
    } else {
      HideModal();
      handleResetSteps(); // Reset steps when 'No' is selected
    }
  };

  const handleResetSteps = () => {
    setActiveStep(0);
    setShowDoleQuestion(false);
  };

  const handleStartNewProfile = () => {
    handleResetSteps();
    // Additional logic to start a new profile
  };

  const handleEditProfile = () => {
    handleResetSteps();
    // Additional logic to edit an existing profile
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onCancel = () => {
    HideModal();
  };

  const handleCancel = () => {
    // Store the current form data to revert if cancel is confirmed
    setOriginalFormData(usersInput);
    setConfirmCancelOpen(true);
  };

  const handleConfirmCancel = () => {
    setErrors({});
    setProfileInfo(defaultStateData);
    setUsersInput(defaultStateData);
    setActiveStep(0); // Reset step to the initial one
    setConfirmCancelOpen(false);
    HideModal();
    setShowSnackbar(true); // Show Snackbar when cancel confirmed
    setConfirmCancelOpen(false);
    // Additional actions (replace with your own)
    // displayAlert({
    //   message: "Action canceled.",
    //   severity: "info",
    // });
  };

  const handleCloseCancelDialog = () => {
    setConfirmCancelOpen(false);
    // Reset form data to original if cancel was confirmed, otherwise do nothing
    if (confirmCancelOpen) {
      setUsersInput(InitialProfileData);
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const validateFields = (fieldName, value) => {
    switch (fieldName) {
      case "lastname":
      case "firstname":
      case "middlename":
      case "religion":
        if (!/^[a-zA-Z\s]*$/.test(value)) {
          return `${fieldName} should only contain letters and spaces.`;
        }
        break;
      case "landline":
      case "mobile":
        if (!/^\d{11}$/.test(value)) {
          return `${fieldName} should be exactly 11 digits.`;
        }
        break;
      case "postal_code":
        if (!/^\d*$/.test(value)) {
          return `${fieldName} should only contain numbers.`;
        }
        break;
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return "Invalid email address.";
        }
        break;
      case "permanent_province_name":
      case "permanent_barangay":
      case "permanent_postal_code":
        if (!/^\d*$/.test(value)) {
          return `${fieldName} should only contain numbers.`;
        }
        break;
      case "permanent_street_address":
      case "permanent_municipality":
      case "fathers_name":
        break;
      case "occupation_father":
        break;
      case "contact_no_father":
        if (!/^\d{11}$/.test(value)) {
          return `${fieldName} should be exactly 11 digits.`;
        }
        break;
      case "mothers_name":
        break;
      case "occupation_mother":
        break;
      case "contact_no_mother":
        if (!/^\d{11}$/.test(value)) {
          return `${fieldName} should be exactly 11 digits.`;
        }
        break;
      case "parents_civil_status":
      case "parents_unemployment_type":
      case "dialect":
      case "height_ft":
      case "type_of_govt_id":
      case "govt_id_no":
      default:
        break;
    }
    return "";
  };

  const handleChangeProfileInfo = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";
    errorMessage = validateFields(name, value);

    const updatedProfileInfo = {
      ...profileInfo,
      [name]: value,
    };

    if (name === "birthdate") {
      updatedProfileInfo.age = calculateAge(value);
    }

    setProfileInfo(updatedProfileInfo);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
      ...(value === "" && { [name]: "" }),
    }));
  };

  const handleSexChange = (genderValue) => {
    setProfileInfo((prevState) => ({
      ...prevState,
      gender: genderValue,
    }));
  };

  // info
  const options = [
    { id: 1, name: "Person with Disability" },
    { id: 2, name: "Senior Citizen" },
    { id: 3, name: "Sugar Plantation Worker" },
    { id: 4, name: "4P's beneficiary" },
    { id: 5, name: "Indigent" },
    { id: 6, name: "Victim of Armed Conflict" },
  ];

  const getIdsFromNames = (names) =>
    names
      .split(",")
      .map((name) => options.find((option) => option.name === name.trim())?.id)
      .filter((id) => id !== undefined);

  const stringToArray = (str) => (str ? str.split(",") : []);

  const getOptionNamesByIds = (ids = [], options) =>
    (Array.isArray(ids) ? ids : [ids])
      .map((id) => options.find((option) => option.id === id)?.name)
      .filter(Boolean) // Remove undefined or falsy values
      .join(",");

  const handleCheckboxChangeParents = (event, optionId) => {
    const isChecked = event.target.checked;

    setProfileInfo((prevProfileInfo) => {
      const updatedSelections = isChecked
        ? [
            ...new Set([
              ...(prevProfileInfo.parents_other_info || []),
              optionId,
            ]),
          ]
        : (prevProfileInfo.parents_other_info || []).filter(
            (id) => id !== optionId
          );

      return { ...prevProfileInfo, parents_other_info: updatedSelections };
    });
  };

  const handleChangeCivilStatus = (event) => {
    const value = event.target.value;
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      civil_status: value,
    }));
  };
  const handleChangeCivilStatusParents = (event) => {
    const value = event.target.value;
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      parents_civil_status: value,
    }));
  };
  const handleChangeParentUnemploymentType = (event) => {
    const value = event.target.value;
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      parents_unemployment_type: value,
    }));
  };
  const handleChangeSuffix = (event) => {
    const value = event.target.value;
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      suffix: value,
    }));
  };

  const optionsDisability = [
    { id: 1, name: "Visual" },
    { id: 2, name: "Hearing" },
    { id: 3, name: "Speech" },
    { id: 4, name: "Physical" },
    { id: 5, name: "Mental" },
    { id: 6, name: "Others" },
  ];

  const getIdsFromNamesDisability = (names) =>
    names
      .split(",")
      .map(
        (name) =>
          optionsDisability.find((option) => option.name === name.trim())?.id
      )
      .filter((id) => id !== undefined);

  const getOptionNameByIdsDisability = (ids = [], optionsDisability) =>
    (Array.isArray(ids) ? ids : [ids])
      .map((id) => optionsDisability.find((option) => option.id === id)?.name)
      .filter(Boolean) // Remove undefined or falsy values
      .join(",");

  const handleChangeDisability = (event, optionId) => {
    const isChecked = event.target.checked;

    setProfileInfo((prevProfileInfo) => {
      const updatedDisabilities = isChecked
        ? [...new Set([...(prevProfileInfo.disabilities || []), optionId])]
        : (prevProfileInfo.disabilities || []).filter((id) => id !== optionId);

      const updatedOtherDisability =
        optionId === 6 && !isChecked ? "" : prevProfileInfo.other_disability;

      return {
        ...prevProfileInfo,
        disabilities: updatedDisabilities,
        other_disability: updatedOtherDisability,
      };
    });
  };

  const handleOtherDisabilityChange = (event) => {
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      other_disability: event.target.value,
    }));
  };

  const optionsDialects = [
    { id: 1, name: "Filipino" },
    { id: 2, name: "English" },
    { id: 3, name: "Mandarin" },
    { id: 4, name: "Others" },
  ];

  const getIdsFromNamesDialect = (names) =>
    names
      .split(",")
      .map(
        (name) =>
          optionsDialects.find((option) => option.name === name.trim())?.id
      )
      .filter((id) => id !== undefined);

  const getOptionNameByIdsDialect = (ids, options) =>
    ids
      .map((id) => optionsDialects.find((option) => option.id === id)?.name)
      .join(",");

  const handleChangeDialect = (event, optionId) => {
    const isChecked = event.target.checked;

    setProfileInfo((prevProfileInfo) => {
      const updatedDialect = isChecked
        ? [...new Set([...prevProfileInfo.dialect, optionId])]
        : prevProfileInfo.dialect.filter((id) => id !== optionId);

      const updatedOtherDialect =
        optionId === 6 && !isChecked ? "" : prevProfileInfo.other_dialect;
      return {
        ...prevProfileInfo,
        dialect: updatedDialect,
        other_dialect: updatedOtherDialect,
      };
    });
  };

  const handleOtherDialectChange = (event) => {
    setProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      other_dialect: event.target.value,
    }));
  };

  const religionOpt = [
    "Assembly of God",
    "Aglipay",
    "Baptist",
    "Body Of Christ",
    "Born Again",
    "Born Again Christian",
    "Christian",
    "Christ The healer International Missions Movement ",
    "Church of Jesus Christ of Latter day Saints ",
    "Faith Tabernacle",
    "Foursquare",
    "Iglesia Filipina Independiente ",
    "Iglesia Ni Cristo ",
    "Islam",
    "Jehoveh Witnesses",
    "Jesus Miracle Crusade International Ministry",
    "Jesus Is Lord Church Worldwide",
    "Jesus Is Lord Church ",
    "Judaism",
    "March Of Faith",
    "Members Church of God International",
    "Mormons",
    "Muslim",
    "New Apostolic",
    "Pentecostal",
    "Protestant",
    "RA",
    "Roman Catholic",
    "Science of Identity Foundation",
    "Seventh-day Adventist",
    "The Church of God",
    "United Church of Christ in the Philippines",
    "United Pentecostal Church ",
    "United Pentecostal Church International",
    "Victory Every Nation Church",
  ];

  const handleSubmitProfileInfo = () => {
    setLoading(true);
    let hasError = false;
    const errors = {};

    if (!profileInfo.lastname) {
      errors.lastname = "Last Name is required";
      hasError = true;
    }
    if (!profileInfo.firstname) {
      errors.firstname = "First Name is required";
      hasError = true;
    }
    if (!profileInfo.birthdate) {
      errors.birthdate = "Birthdate is required";
      hasError = true;
    }
    if (!profileInfo.age || profileInfo.age === 0) {
      errors.age = "Age is required";
      hasError = true;
    }
    if (!profileInfo.religion) {
      errors.religion = "Religion is required";
      hasError = true;
    }
    if (!profileInfo.civil_status) {
      errors.civil_status = "Civil Status is required";
      hasError = true;
    }
    if (!profileInfo.gender) {
      errors.gender = "Gender is required";
      hasError = true;
    }
    if (!profileInfo.email) {
      errors.email = "Email is required";
      hasError = true;
    }
    if (!profileInfo.mobile) {
      errors.mobile = "Mobile is required";
      hasError = true;
    }
    if (!profileInfo.province_name) {
      errors.province_name = "Province Name is required";
      hasError = true;
    }
    if (!profileInfo.barangay_name) {
      errors.barangay_name = "Barangay Name is required";
      hasError = true;
    }
    if (!profileInfo.postal_code) {
      errors.postal_code = "Postal Code is required";
      hasError = true;
    }
    if (!profileInfo.street_address) {
      errors.street_address = "Street Address is required";
      hasError = true;
    }
    if (!profileInfo.municipal_name) {
      errors.municipal_name = "Municipal Name is required";
      hasError = true;
    }

    if (!profileInfo.permanent_province_name) {
      errors.permanent_province_name = "Province Name is required";
      hasError = true;
    }
    if (!profileInfo.permanent_barangay) {
      errors.permanent_barangay = "Barangay Name is required";
      hasError = true;
    }
    if (!profileInfo.permanent_postal_code) {
      errors.permanent_postal_code = "Postal Code is required";
      hasError = true;
    }
    if (!profileInfo.permanent_street_address) {
      errors.permanent_street_address = "Street Address is required";
      hasError = true;
    }
    if (!profileInfo.permanent_municipality) {
      errors.permanent_municipality = "Municipal Name is required";
      hasError = true;
    }
    if (!profileInfo.fathers_name) {
      errors.fathers_name = "Father's Name is required";
      hasError = true;
    }
    if (!profileInfo.occupation_father) {
      errors.occupation_father = "Occupation is required";
      hasError = true;
    }
    if (!profileInfo.contact_no_father) {
      errors.contact_no_father = "Contact number is required";
      hasError = true;
    }
    if (!profileInfo.mothers_name) {
      errors.mothers_name = "Mother's Name is required";
      hasError = true;
    }
    if (!profileInfo.occupation_mother) {
      errors.occupation_mother = "Occupation is required";
      hasError = true;
    }
    if (!profileInfo.contact_no_mother) {
      errors.contact_no_mother = "Contact number is required";
      hasError = true;
    }
    if (!profileInfo.parents_civil_status) {
      errors.parents_civil_status = "Parent/s civil status is required";
      hasError = true;
    }
    if (!profileInfo.parents_unemployment_type) {
      errors.parents_unemployment_type =
        "Parent/s unemployment type is required";
      hasError = true;
    }
    if (!profileInfo.dialect || profileInfo.dialect.length === 0) {
      errors.dialect = "Dialect is required";
      hasError = true;
    }
    if (!profileInfo.height_ft) {
      errors.height_ft = "Height is required";
      hasError = true;
    }
    if (!profileInfo.type_of_govt_id) {
      errors.type_of_govt_id = "Government ID Type is required";
      hasError = true;
    }
    if (!profileInfo.govt_id_no) {
      errors.govt_id_no = "Government ID number is required";
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      setErrors(errors);
      displayAlert({
        message: "Please fill in the required fields.",
        severity: "error",
      });
      return;
    }

    const profileInfoWithAddedBy = {
      ...profileInfo,
      added_by: user_id,
      parents_other_info: getOptionNamesByIds(
        profileInfo.parents_other_info || [],
        options || ""
      ),
      disabilities: getOptionNameByIdsDisability(
        profileInfo.disabilities || [],
        optionsDisability || ""
      ),
      dialect: getOptionNameByIdsDialect(profileInfo.dialect, optionsDialects),
    };

    if (JSON.stringify(prevProfilesInfo) === JSON.stringify(profileInfo)) {
      setLoading(false);
      // displayAlert({ message: "No changes detected.", severity: "info" });
      handleNext();
      return;
    }
    ProfileInfoSave(profileInfoWithAddedBy)
      .then((res) => {
        if (res.error) {
          displayAlert({
            message: res.message || "An error occurred.",
            severity: "error",
          });
          return;
        }

        if (res.exist === "Profile already exists in database") {
          displayAlert({
            message: "Profile already exists in database.",
            severity: "warning",
          });
          return;
        }

        displayAlert({
          message: "Profile saved successfully.",
          severity: "success",
        });

        dispatch({ type: "SET_PROFILE_ID", payload: res.profile_id });
        handleNext();
      })
      .catch((error) => {
        console.error("Error saving profile info:", error);
        displayAlert({
          message: "An error occurred while saving the profile.",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // For Step Content Below
  const stepContent = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <div style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}>
            <Material.Box
              component="div"
              // elevation={2}
              sx={{ width: "100%", height: "500px" }}
            >
              <Material.Paper
                sx={{ padding: 3, margin: 0.2 }}
                elevation={3}
                square={false}
              >
                <Material.Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Personal Information
                </Material.Typography>
                <Material.Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={1}
                  sx={{ padding: "4px" }}
                >
                  <Material.Grid item xs={3} sm={3}>
                    <Material.TextField
                      required
                      id="standard-required-last-name"
                      label="Last Name"
                      name="lastname"
                      fullWidth
                      variant="filled"
                      value={profileInfo.lastname}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.lastname}
                      // helperText={errors.lastname}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={3} sm={3}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-first-name"
                      label="First Name"
                      name="firstname"
                      variant="filled"
                      value={profileInfo.firstname}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.firstname}
                      // helperText={errors.firstname}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={3} sm={3}>
                    <Material.TextField
                      id="standard-middle-name"
                      label="Middle Name"
                      name="middlename"
                      variant="filled"
                      fullWidth
                      value={profileInfo.middlename}
                      onChange={handleChangeProfileInfo}
                    />
                  </Material.Grid>
                  <Material.Grid item xs={3} sm={3}>
                    <Material.FormControl fullWidth variant="filled">
                      <Material.InputLabel id="demo-simple-select-standard-label">
                        Suffix
                      </Material.InputLabel>
                      <Material.Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Suffix"
                        value={profileInfo.suffix}
                        onChange={handleChangeSuffix}
                      >
                        <Material.MenuItem value="">
                          <em>None</em>
                        </Material.MenuItem>
                        <Material.MenuItem value={"Sr."}>Sr.</Material.MenuItem>
                        <Material.MenuItem value={"Jr."}>Jr.</Material.MenuItem>
                        <Material.MenuItem value={"III"}>III</Material.MenuItem>
                        <Material.MenuItem value={"IV"}>IV</Material.MenuItem>
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 25 }} item xs={3} sm={3}>
                    <Material.TextField
                      fullWidth
                      variant="filled"
                      required
                      id="date-of-birth"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      name="birthdate"
                      label="Date of Birth"
                      value={profileInfo.birthdate}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.birthdate} // Add error prop
                      // helperText={errors.birthdate}
                      // Add helper text
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 25 }} item xs={3} sm={3}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-number"
                      label="Age"
                      type="number"
                      name="age"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="filled"
                      value={profileInfo.age}
                      InputProps={{ readOnly: true }}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.age}
                      // helperText={errors.age}
                      // Add helper text
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 25 }} item xs={3} sm={3}>
                    <Material.Autocomplete
                      freeSolo
                      fullWidth
                      required
                      options={religionOpt}
                      value={profileInfo.religion}
                      onChange={(event, newValue) =>
                        handleChangeProfileInfo({
                          target: { name: "religion", value: newValue },
                        })
                      }
                      onInputChange={(event, newInputValue) =>
                        handleChangeProfileInfo({
                          target: { name: "religion", value: newInputValue },
                        })
                      }
                      renderInput={(params) => (
                        <Material.TextField
                          {...params}
                          required
                          label="Religion"
                          variant="filled"
                          error={!!errors.religion}
                          // helperText={errors.religion}
                          name="religion"
                        />
                      )}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 24 }} item xs={3} sm={3}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.civil_status}
                    >
                      <Material.InputLabel id="demo-simple-select-standard-label">
                        Civil Status
                      </Material.InputLabel>
                      <Material.Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Civil Status"
                        value={profileInfo.civil_status}
                        onChange={handleChangeCivilStatus}
                      >
                        <Material.MenuItem value={"Single"}>
                          Single
                        </Material.MenuItem>
                        <Material.MenuItem value={"Married"}>
                          Married
                        </Material.MenuItem>
                        <Material.MenuItem value={"Divorced"}>
                          Divorced
                        </Material.MenuItem>
                        <Material.MenuItem value={"Separated"}>
                          Separated
                        </Material.MenuItem>
                        <Material.MenuItem value={"Widow/er"}>
                          Widow/er
                        </Material.MenuItem>
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 15 }} item xs={3} sm={3}>
                    <Material.FormControl
                      required
                      fullWidth
                      component="fieldset"
                      error={!!errors.gender}
                    >
                      <Material.FormLabel component="legend">
                        Sex
                      </Material.FormLabel>
                      <Material.FormGroup aria-label="position" row>
                        <Material.FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={profileInfo.gender === "Female"}
                              onChange={() => handleSexChange("Female")}
                            />
                          }
                          label="Female"
                          labelPlacement="end"
                        />
                        <Material.FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={profileInfo.gender === "Male"}
                              onChange={() => handleSexChange("Male")}
                            />
                          }
                          label="Male"
                          labelPlacement="end"
                        />
                      </Material.FormGroup>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={3} sm={3}>
                    <Material.TextField
                      fullWidth
                      required
                      id="standard-required-email"
                      label="Email"
                      variant="filled"
                      name="email"
                      value={profileInfo.email}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.email}
                      // helperText={errors.email}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={3} sm={3}>
                    <Material.TextField
                      fullWidth
                      id="standard-landline"
                      label="Landline Number"
                      variant="filled"
                      name="landline"
                      value={profileInfo.landline}
                      onChange={handleChangeProfileInfo}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={3} sm={3}>
                    <Material.TextField
                      fullWidth
                      required
                      id="standard-required-cellphone"
                      label="Cellphone Number"
                      variant="filled"
                      name="mobile"
                      value={profileInfo.mobile}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.mobile}
                      // helperText={errors.mobile}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Type of Gov't. ID"
                      variant="filled"
                      name="type_of_govt_id"
                      value={profileInfo.type_of_govt_id}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.type_of_govt_id}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="ID No."
                      variant="filled"
                      name="govt_id_no"
                      value={profileInfo.govt_id_no}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.govt_id_no}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Height (ft.)"
                      variant="filled"
                      name="height_ft"
                      value={profileInfo.height_ft}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.height_ft}
                    />
                  </Material.Grid>
                  <Material.Grid
                    container
                    style={{ paddingTop: 2 }}
                    item
                    xs={12}
                  >
                    <Material.Typography>Present Address*</Material.Typography>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 0 }} item xs={12} sm={4}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.province_name}
                    >
                      <Material.InputLabel id="state-province-label">
                        State/Province
                      </Material.InputLabel>
                      <Material.Select
                        labelId="state-province-label"
                        id="state-province-select"
                        label="State Province"
                        value={profileInfo.province_name}
                        onChange={handleSelectProvince}
                      >
                        <Material.MenuItem value="">
                          <em>Select State/Province</em>
                        </Material.MenuItem>
                        {provinces.map((province) => (
                          <Material.MenuItem
                            key={province.province_id}
                            value={province.province_name}
                          >
                            {province.province_name}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 0 }} item xs={12} sm={4}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.municipal_name}
                    >
                      <Material.InputLabel id="city-municipality-label">
                        City/Municipality
                      </Material.InputLabel>
                      <Material.Select
                        labelId="city-municipality-label"
                        id="city-municipality-select"
                        label="City/Municipality"
                        value={profileInfo.municipal_name}
                        onChange={handleSelectMunicipality}
                      >
                        <Material.MenuItem value="">
                          <em>Select City/Municipality</em>
                        </Material.MenuItem>
                        {municipalities.map((municipality) => (
                          <Material.MenuItem
                            key={municipality.municipal_id}
                            value={municipality.municipal_name}
                          >
                            {municipality.municipal_name}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 0 }} item xs={12} sm={4}>
                    <Material.TextField
                      fullWidth
                      required
                      id="standard-required-cellphone"
                      label="Postal Code"
                      variant="filled"
                      name="postal_code"
                      onChange={handleChangeProfileInfo}
                      value={profileInfo.postal_code}
                      error={!!errors.postal_code}
                      // helperText={errors.postal_code}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={6}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.barangay_name}
                    >
                      <Material.InputLabel id="barangay-select-label">
                        Barangay
                      </Material.InputLabel>
                      <Material.Select
                        labelId="barangay-label"
                        id="barangay-select"
                        variant="filled"
                        label="Barangay"
                        value={profileInfo.barangay_name}
                        onChange={handleSelectBarangay}
                      >
                        <Material.MenuItem value="">
                          <em>Select Barangay</em>
                        </Material.MenuItem>
                        {listOfBarangay.map((barangay) => (
                          <Material.MenuItem
                            key={barangay.barangay_id}
                            value={barangay.barangay_name}
                          >
                            {barangay.barangay_name}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={6}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Street Address"
                      variant="filled"
                      name="street_address"
                      value={profileInfo.street_address}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.street_address}
                    />
                  </Material.Grid>
                  <Material.Divider />
                  <Material.Grid
                    container
                    style={{ paddingTop: 10 }}
                    item
                    xs={12}
                  >
                    <Material.Typography>
                      Permanent Address*
                    </Material.Typography>
                    <Material.FormGroup fullWidth>
                      <Material.FormControlLabel
                        style={{ paddingLeft: 15 }}
                        control={<Material.Checkbox />}
                        label="same as present"
                        onChange={handleCheckboxChange}
                      />
                    </Material.FormGroup>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 1 }} item xs={12} sm={4}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.permanent_province_name}
                    >
                      <Material.InputLabel id="state-province-label">
                        State/Province
                      </Material.InputLabel>
                      <Material.Select
                        labelId="state-province-label"
                        id="state-province-select"
                        label="State Province"
                        value={profileInfo.permanent_province_name}
                        onChange={handleSelectProvincePermanent}
                      >
                        <Material.MenuItem value="">
                          <em>Select State/Province</em>
                        </Material.MenuItem>
                        {provinces.map((province) => (
                          <Material.MenuItem
                            key={province.province_id}
                            value={province.province_name}
                          >
                            {province.province_name}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 1 }} item xs={12} sm={4}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.permanent_municipality}
                    >
                      <Material.InputLabel id="city-municipality-label">
                        City/Municipality
                      </Material.InputLabel>
                      <Material.Select
                        labelId="city-municipality-label"
                        id="city-municipality-select"
                        label="City/Municipality"
                        value={profileInfo.permanent_municipality}
                        onChange={handleSelectMunicipalityPermanent}
                      >
                        <Material.MenuItem value="">
                          <em>Select City/Municipality</em>
                        </Material.MenuItem>
                        {municipalities.map((municipality) => (
                          <Material.MenuItem
                            key={municipality.municipal_id}
                            value={municipality.municipal_name}
                          >
                            {municipality.municipal_name}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 1 }} item xs={12} sm={4}>
                    <Material.TextField
                      fullWidth
                      required
                      id="standard-required-cellphone"
                      label="Postal Code"
                      variant="filled"
                      name="permanent_postal_code"
                      onChange={handleChangeProfileInfo}
                      value={profileInfo.permanent_postal_code}
                      error={!!errors.postal_code}
                      // helperText={errors.permanent_postal_code}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={6}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.permanent_barangay}
                    >
                      <Material.InputLabel id="barangay-select-label">
                        Barangay
                      </Material.InputLabel>
                      <Material.Select
                        labelId="barangay-label"
                        id="barangay-select"
                        variant="filled"
                        label="Barangay"
                        value={profileInfo.permanent_barangay}
                        onChange={handleSelectBarangayPermanent}
                      >
                        <Material.MenuItem value="">
                          <em>Select Barangay</em>
                        </Material.MenuItem>
                        {listOfBarangayPermanent.map((barangay) => (
                          <Material.MenuItem
                            key={barangay.barangay_id}
                            value={barangay.barangay_name}
                          >
                            {barangay.barangay_name}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={6}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Street Address"
                      variant="filled"
                      name="permanent_street_address"
                      value={profileInfo.permanent_street_address}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.permanent_street_address}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Father's Name"
                      variant="filled"
                      name="fathers_name"
                      value={profileInfo.fathers_name}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.fathers_name}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Occupation"
                      variant="filled"
                      name="occupation_father"
                      value={profileInfo.occupation_father}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.occupation_father}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Contact Number"
                      variant="filled"
                      name="contact_no_father"
                      value={profileInfo.contact_no_father}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.contact_no_father}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Mother's Name"
                      variant="filled"
                      name="mothers_name"
                      value={profileInfo.mothers_name}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.mothers_name}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Occupation"
                      variant="filled"
                      name="occupation_mother"
                      value={profileInfo.occupation_mother}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.occupation_mother}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 20 }} item xs={12} sm={4}>
                    <Material.TextField
                      required
                      fullWidth
                      id="standard-required-street-address"
                      label="Contact Number"
                      variant="filled"
                      name="contact_no_mother"
                      value={profileInfo.contact_no_mother}
                      onChange={handleChangeProfileInfo}
                      error={!!errors.contact_no_mother}
                    />
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 24 }} item xs={4} sm={6}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.parents_civil_status}
                    >
                      <Material.InputLabel id="demo-simple-select-standard-label">
                        Parent/s' Civil Status
                      </Material.InputLabel>
                      <Material.Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Parent/s' Civil Status"
                        value={profileInfo.parents_civil_status}
                        onChange={handleChangeCivilStatusParents}
                      >
                        <Material.MenuItem value={"Living Together"}>
                          Living Together
                        </Material.MenuItem>
                        <Material.MenuItem value={"Solo Parent"}>
                          Solo Parent
                        </Material.MenuItem>
                        <Material.MenuItem value={"Separated"}>
                          Separated
                        </Material.MenuItem>
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid style={{ paddingTop: 24 }} item xs={4} sm={6}>
                    <Material.FormControl
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.parents_unemployment_type}
                    >
                      <Material.InputLabel id="demo-simple-select-standard-label">
                        Parent/s'Unemployment Type
                      </Material.InputLabel>
                      <Material.Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label=" Parent/s'Unemployment Type"
                        value={profileInfo.parents_unemployment_type}
                        onChange={handleChangeParentUnemploymentType}
                      >
                        <Material.MenuItem value={"Laid off (local)"}>
                          Laid off (local)
                        </Material.MenuItem>
                        <Material.MenuItem value={"Laid off (overseas)"}>
                          Laid off (overseas)
                        </Material.MenuItem>
                        <Material.MenuItem value={"Not Applicable"}>
                          Not Applicable
                        </Material.MenuItem>
                      </Material.Select>
                    </Material.FormControl>
                  </Material.Grid>

                  <Material.Grid style={{ paddingTop: 24 }} item xs={12}>
                    <Material.Typography gutterBottom>
                      Parent's Other Info
                    </Material.Typography>

                    <Material.FormControl required fullWidth variant="filled">
                      <Material.FormGroup row>
                        {options.map((option) => (
                          <Material.FormControlLabel
                            key={option.id}
                            control={
                              <Material.Checkbox
                                checked={profileInfo.parents_other_info?.includes(
                                  option.id
                                )}
                                onChange={(e) =>
                                  handleCheckboxChangeParents(e, option.id)
                                }
                                value={option.id}
                              />
                            }
                            label={option.name}
                            style={{ marginRight: 16, flexBasis: "30%" }}
                          />
                        ))}
                      </Material.FormGroup>
                    </Material.FormControl>
                  </Material.Grid>

                  <Material.Grid item xs={12}>
                    <Material.Divider style={{ margin: "5px 0" }} />
                  </Material.Grid>
                  <Material.Grid item xs={12}>
                    <Material.Typography gutterBottom>
                      Disability
                    </Material.Typography>
                    <Material.FormControl component="fieldset" fullWidth>
                      <Material.FormGroup row>
                        {" "}
                        {/* Horizontal layout */}
                        {optionsDisability.map((option) => (
                          <Material.FormControlLabel
                            key={option.id}
                            control={
                              <Material.Checkbox
                                checked={profileInfo.disabilities?.includes(
                                  option.id
                                )}
                                onChange={(e) =>
                                  handleChangeDisability(e, option.id)
                                }
                                value={option.id}
                              />
                            }
                            label={option.name}
                            style={{ marginRight: 16 }} // Adds space between checkboxes
                          />
                        ))}
                        {/* Display "Please specify" field beside "Others" */}
                        {profileInfo.disabilities?.includes(6) && (
                          <Material.TextField
                            label="Please specify"
                            variant="filled"
                            size="small"
                            value={profileInfo.other_disability || ""}
                            onChange={handleOtherDisabilityChange}
                            style={{ marginLeft: 16 }}
                          />
                        )}
                      </Material.FormGroup>
                    </Material.FormControl>
                  </Material.Grid>
                  <Material.Grid item xs={12}>
                    <Material.Divider style={{ margin: "5px 0" }} />
                  </Material.Grid>
                  <Material.Grid item xs={12}>
                    <Material.Typography gutterBottom>
                      Language/Dialect Proficiency (check if applicable)*
                    </Material.Typography>

                    <Material.FormControl
                      error={!!errors.dialect} // Apply error styling if validation fails
                      component="fieldset"
                      fullWidth
                      sx={{
                        border: errors.dialect ? "1px solid red" : "none", // Red border on error
                        borderRadius: 4,
                        padding: 2,
                      }}
                    >
                      <Material.FormGroup row>
                        {optionsDialects.map((option) => (
                          <Material.FormControlLabel
                            key={option.id}
                            control={
                              <Material.Checkbox
                                checked={profileInfo.dialect?.includes(
                                  option.id
                                )}
                                onChange={(e) =>
                                  handleChangeDialect(e, option.id)
                                }
                                value={option.id}
                                sx={{
                                  color: errors.dialect ? "red" : "default", // Checkbox color on error
                                }}
                              />
                            }
                            label={option.name}
                            style={{ marginRight: 16 }}
                          />
                        ))}

                        {profileInfo.dialect?.includes(4) && (
                          <Material.TextField
                            label="Please specify"
                            variant="filled"
                            size="small"
                            value={profileInfo.other_dialect || ""}
                            onChange={handleOtherDialectChange}
                            style={{ marginLeft: 16 }}
                          />
                        )}
                      </Material.FormGroup>

                      {errors.dialect && (
                        <Material.FormHelperText>
                          {errors.dialect}
                        </Material.FormHelperText>
                      )}
                    </Material.FormControl>
                  </Material.Grid>
                </Material.Grid>
              </Material.Paper>
            </Material.Box>
          </div>
        );
      case 1:
        return (
          <div style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}>
            <Material.Box
              component="div"
              // elevation={2}
              sx={{ width: "1000px", height: "500px" }}
            >
              <EducationalBackgroundContent
                ProfileId={profileInfo.id}
                onSave={handleSubmitEducBackground}
                InitialProfileData={InitialProfileData}
              />
            </Material.Box>
          </div>
        );

      case 2:
        return (
          <div style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}>
            <Material.Box
              component="div"
              // elevation={2}
              sx={{ width: "100%", height: "500px" }}
            >
              <TrainingAndVocationalForm
                InitialProfileData={InitialProfileData}
              />
            </Material.Box>
          </div>
        );

      case 3:
        return (
          <div style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}>
            <Material.Box
              component="div"
              // elevation={2}
              sx={{ width: "100%", height: "500px" }}
            >
              <Material.Box sx={{ marginBottom: 2 }}>
                <EmploymentStatusContent
                  ProfileId={profileInfo.id}
                  onSave={handleSubmitEmploymentStatusFn}
                  InitialProfileData={InitialProfileData}
                />
              </Material.Box>
              <Material.Box style={{ paddingTop: 20 }} sx={{ marginBottom: 2 }}>
                <Material.FormGroup sx={{ padding: 2 }}>
                  {skillset.map((row, index) => (
                    <Material.FormControlLabel
                      key={index}
                      control={<Checkbox defaultChecked />}
                      label={row.name}
                    />
                  ))}
                </Material.FormGroup>
              </Material.Box>
            </Material.Box>
          </div>
        );
    }
  };

  const handleFinish = () => {
    setShowSteps(false);
    handleNext();
  };

  const handleYesResponse = () => {
    handleDoleResponse("yes");
    setShowDoleProgram(true);
  };

  return (
    <div>
      <SytledModal
        open={ModalVisible}
        onClose={HideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "auto" }}
        maxWidth="lg"
        fullWidth={true}
        scroll={"body"}
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Material.Box
          maxWidth="lg"
          maxHeight={"99%"}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={3}
          borderRadius={3}
          paddingBottom={10}
        >
          {!showDoleQuestion && !showDoleProgram && (
            <Material.Typography
              variant="h6"
              color="gray"
              textAlign="left"
              sx={{ padding: 2 }}
            >
              {editMode
                ? `EDIT CLIENT'S INFORMATION`
                : `FILL UP CLIENT'S INFORMATION`}
            </Material.Typography>
          )}

          {!showDoleQuestion && !showDoleProgram && (
            <Material.Paper sx={{ padding: 2 }} elevation={3} square={false}>
              <Material.Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Material.Step
                      key={label}
                      {...stepProps}
                      sx={{ marginTop: 2 }}
                    >
                      <Material.StepLabel {...labelProps}>
                        {label}
                      </Material.StepLabel>
                    </Material.Step>
                  );
                })}
              </Material.Stepper>
            </Material.Paper>
          )}

          {!editMode && showDoleQuestion ? (
            <Material.Dialog
              open={showDoleQuestion}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Material.DialogTitle id="alert-dialog-title">
                {"Add DOLE Program"}
              </Material.DialogTitle>
              <Material.DialogContent>
                <Material.DialogContentText id="alert-dialog-description">
                  Do you want to Add DOLE Program?
                </Material.DialogContentText>
              </Material.DialogContent>
              <Material.DialogActions>
                <Material.Button
                  onClick={() => handleDoleResponse("no")}
                  color="primary"
                >
                  No
                </Material.Button>
                <Material.Button
                  onClick={handleYesResponse}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Material.Button>
              </Material.DialogActions>
            </Material.Dialog>
          ) : (
            ""
          )}
          {!editMode && showDoleProgram ? (
            <Material.Box sx={{ marginBottom: 2 }}>
              <DoleProgramContent
                ProfileId={profileInfo.id}
                onClose={HideModal}
                InitialProfileData={InitialProfileData}
                ModalVisible={ModalVisible}
              />
            </Material.Box>
          ) : (
            <React.Fragment>
              <Material.Typography sx={{ mt: 2, mb: 1 }}>
                {stepContent(activeStep)}
              </Material.Typography>
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  justifyContent: "flex-end",
                  "& > :not(style) + :not(style)": {
                    marginLeft: "8px",
                  },
                }}
              >
                <Material.Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Material.Button>
                <Material.Box sx={{ flex: "1 1 auto" }} />

                <Material.Button
                  color="error"
                  onClick={activeStep === 4 ? handleCancel : undefined}
                  disabled={activeStep !== 4}
                >
                  Cancel
                </Material.Button>
                <Material.Button
                  onClick={
                    activeStep === 0 ? handleSubmitProfileInfo : handleNext
                  }
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Material.Button>
              </Material.Box>
            </React.Fragment>
          )}
        </Material.Box>
      </SytledModal>
      <LoadingSpinner loading={loading} />

      <Material.Dialog
        open={confirmCancelOpen}
        onClose={handleCloseCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Material.DialogTitle id="alert-dialog-title">
          {"Cancel Confirmation"}
        </Material.DialogTitle>
        <Material.DialogContent>
          <Material.DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel? Any unsaved changes will be lost.
          </Material.DialogContentText>
        </Material.DialogContent>
        <Material.DialogActions>
          <Material.Button onClick={handleCloseCancelDialog} color="primary">
            No
          </Material.Button>
          <Material.Button
            onClick={handleConfirmCancel}
            color="primary"
            autoFocus
          >
            Yes
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>

      <Snackbar
        open={showToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleToastClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Action canceled
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MainFormModal;
