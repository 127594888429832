import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  styled,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { displayAlert } from "../../common/Alert";
import {
  Save,
  UpdateEmploymentStatus,
} from "../../../services/EmploymentStatusService";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useSelector } from "react-redux";
import EmploymentStatusService from "../../../services/EmploymentStatusService";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const defaultStateData = {
  id: 0,
  occupation: "",
  description: "",
  employment_status: "",
  employed_type: "",
  added_by: "",
};

const employmentStatusTypes = [
  { id: "Employed", name: "Employed" },
  { id: "Unemployed", name: "Unemployed" },
];

const employedType = [
  { id: "wage_employed", name: "Wage Employed" },
  { id: "self_employed", name: "Self Employed" },
];

function EmploymentStatusManagementAdd({
  SelectedRow,
  ModalVisible,
  HideModal,
  EmploymentStatusTypes = employmentStatusTypes,
  EmployedType = employedType,
  fetchEmploymentStatus,
}) {
  const user_id = useSelector((state) => state.user.id || 0);
  const [allEmploymentStatus, setAllEmploymentStatus] = useState([]);
  const [employmentStatusInput, setEmploymentStatusInput] =
    useState(defaultStateData);
  const [loading, setLoading] = useState(false);
  const [doneFirstLoad, setDoneFirstLoad] = useState(false);

  const editMode = Object.keys(SelectedRow).length > 0 ? true : false;

  const isOccupationEmpty = !employmentStatusInput.occupation;
  const isEmploymentStatusEmpty = !employmentStatusInput.employment_status;
  const isEmployedTypeEmpty =
    employmentStatusInput.employment_status === "Employed" &&
    !employmentStatusInput.employed_type;

  useEffect(() => {
    console.log("IM HERE");
  });

  useEffect(() => {
    if (ModalVisible) {
      const initialState = editMode
        ? {
            id: SelectedRow.id,
            occupation: SelectedRow.occupation,
            description: SelectedRow.description,
            employment_status: SelectedRow.employment_status,
            employed_type: SelectedRow.employed_type,
          }
        : defaultStateData;
      setEmploymentStatusInput(initialState);
    }
  }, [ModalVisible]);

  useEffect(() => {
    if (editMode) {
      setEmploymentStatusInput({
        id: SelectedRow.id,
        occupation: SelectedRow.occupation || "",
        description: SelectedRow.description || "",
        employment_status: SelectedRow.employment_status || "",
        employed_type: SelectedRow.employed_type || "",
        added_by: SelectedRow.added_by || "",
      });
    }
  }, [SelectedRow, editMode]);

  const onHandleChange = (e) => {
    setEmploymentStatusInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSave = () => {
    setDoneFirstLoad(true);

    const employmentStatusInputWithAddedBy = {
      ...employmentStatusInput,
      added_by: user_id,
    };

    if (
      !isOccupationEmpty &&
      !isEmploymentStatusEmpty &&
      (employmentStatusInput.employment_status === "Unemployed" ||
        !isEmployedTypeEmpty)
    ) {
      // setLoading(true);
      if (editMode) {
        UpdateEmploymentStatus(employmentStatusInputWithAddedBy)
          .then((res) => {
            if (res.error) {
              displayAlert({
                message: res.message,
                severity: "error",
              });
            } else {
              displayAlert({
                message: "Employment status updated successfully!",
                severity: "success",
              });
              HideModal();
              resetState();
              fetchEmploymentStatus();
            }
          })
          .catch((err) => {
            displayAlert({
              message: err.message || "An error occurred",
              severity: "error",
            });
            // setLoading(false);
          });
      } else {
        Save(employmentStatusInputWithAddedBy)
          .then((res) => {
            if (res.error) {
              displayAlert({
                message: res.message,
                severity: "error",
              });
            } else {
              displayAlert({
                message: "Employment status saved successfully!",
                severity: "success",
              });
              HideModal();
              resetState();
              fetchEmploymentStatus();
            }
          })
          .catch((err) => {
            displayAlert({
              message: err.message || "An error occurred",
              severity: "error",
            });
            // setLoading(false);
          });
      }
    } else {
      displayAlert({
        message: "Please fill all the required fields!",
        severity: "warning",
      });
      setLoading(false);
    }
  };

  const onCancel = () => {
    resetState();
    HideModal();
  };

  const resetState = () => {
    setEmploymentStatusInput(defaultStateData);
  };

  return (
    <div>
      <StyledModal
        open={ModalVisible}
        onClose={HideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "auto" }}
      >
        <Box
          width={600}
          maxHeight={"90%"}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={3}
          borderRadius={3}
          paddingBottom={3}
        >
          <Typography variant="h6" color="gray" textAlign="center">
            {editMode
              ? `EDIT EMPLOYMENT STATUS`
              : `CREATE NEW EMPLOYMENT STATUS`}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            sx={{ padding: "5px" }}
          >
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                required
                error={doneFirstLoad && isEmploymentStatusEmpty}
              >
                <InputLabel id="employment-status-label">
                  Employment Status
                </InputLabel>
                <Select
                  labelId="employment-status-label"
                  name="employment_status"
                  value={employmentStatusInput.employment_status}
                  onChange={onHandleChange}
                >
                  {EmploymentStatusTypes.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {employmentStatusInput.employment_status === "Employed" && (
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  fullWidth
                  required
                  error={doneFirstLoad && isEmployedTypeEmpty}
                >
                  <InputLabel id="employed-type-label">
                    Employment Type
                  </InputLabel>
                  <Select
                    labelId="employed-type-label"
                    name="employed_type"
                    value={employmentStatusInput.employed_type}
                    onChange={onHandleChange}
                  >
                    {EmployedType.map((type) => (
                      <MenuItem key={type.id} value={type.name}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                required
                name="occupation"
                label="Occupation"
                value={employmentStatusInput.occupation}
                onChange={onHandleChange}
                error={doneFirstLoad && isOccupationEmpty}
                helperText={
                  doneFirstLoad && isOccupationEmpty
                    ? "Occupation is required"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                rows={4}
                name="description"
                label="Description"
                value={employmentStatusInput.description}
                onChange={onHandleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={onSave} variant="contained">
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
      {loading && <LoadingSpinner loading={loading} />}
    </div>
  );
}

export default EmploymentStatusManagementAdd;
