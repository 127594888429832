import MainContainer from './components/container/MainContainer'
import LoginContainer from './components/container/LoginContainer'
import { useSelector } from 'react-redux';

function App() {
  const user = useSelector((state) => state.user);
  const userLength = Object.keys(user).length;
  return (
    <>
      {
        userLength > 0 ?
          <MainContainer />
          : <LoginContainer />
      }
    </>
  )
}

export default App;
