import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { displayAlert } from "../../common/Alert";
import { Save, UpdateSkills } from "../../../services/SkillsService";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useSelector } from "react-redux";
import SkillsService from "../../../services/SkillsService";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const defaultStateData = {
  name: "",
  description: "",
  added_by: "",
};

function SkillsManagementAdd({ SelectedRow, ModalVisible, HideModal }) {
  const user_id = useSelector((state) => state.user.id || 0);
  const [skillsInput, setSkillsInput] = useState(defaultStateData);
  const [loading, setLoading] = useState(false);
  const [doneFirstLoad, setDoneFirstLoad] = useState(false);

  const editMode = Object.keys(SelectedRow).length > 0;

  useEffect(() => {
    if (ModalVisible) {
      const initialState = editMode
        ? {
            id: SelectedRow.id,
            name: SelectedRow.name,
            description: SelectedRow.description,
          }
        : defaultStateData;
      setSkillsInput(initialState);
    }
  }, [ModalVisible]);

  useEffect(() => {
    if (editMode) {
      setSkillsInput({
        id: SelectedRow.id || "",
        name: SelectedRow.name || "",
        description: SelectedRow.description || "",
        added_by: SelectedRow.added_by || "",
      });
    }
  }, [SelectedRow, editMode]);

  const onHandleChange = (e) => {
    setSkillsInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const fetchSkills = async () => {
    try {
      await SkillsService.getSkills();
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Skills:", error);
      setLoading(false);
    }
  };

  const onSave = () => {
    setDoneFirstLoad(true);

    const skillsInputWithAddedBy = {
      ...skillsInput,
      added_by: user_id,
      description:
        skillsInput.description.trim() === "" ? null : skillsInput.description,
    };

    const isNameEmpty = !skillsInputWithAddedBy.name;

    if (!isNameEmpty) {
      setLoading(true);
      if (editMode) {
        UpdateSkills(skillsInputWithAddedBy)
          .then((res) => {
            if (res.error) {
              displayAlert({
                message: res.message,
                severity: "error",
              });
            } else {
              displayAlert({
                message: "Skills updated successfully!",
                severity: "success",
              });
              fetchSkills();
            }
            setLoading(false);
            resetState();
            HideModal();
          })
          .catch((err) => {
            displayAlert({
              message: err.message || "An error occurred",
              severity: "error",
            });
            setLoading(false);
          });
      } else {
        Save(skillsInputWithAddedBy)
          .then((res) => {
            if (res.error) {
              displayAlert({
                message: res.message,
                severity: "error",
              });
            } else {
              displayAlert({
                message: "Skills saved successfully!",
                severity: "success",
              });
              fetchSkills();
            }
            setLoading(false);
            resetState();
            HideModal();
          })
          .catch((err) => {
            displayAlert({
              message: err.message || "An error occurred",
              severity: "error",
            });
            setLoading(false);
          });
      }
    } else {
      displayAlert({
        message: "Please fill in the required fields!",
        severity: "warning",
      });
      setLoading(false);
    }
  };

  const onCancel = () => {
    resetState();
    HideModal();
  };

  const resetState = () => {
    setSkillsInput(defaultStateData);
  };

  return (
    <div>
      <StyledModal
        open={ModalVisible}
        onClose={HideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "auto" }}
      >
        <Box
          width={600}
          maxHeight={"90%"}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={3}
          borderRadius={3}
          paddingBottom={3}
        >
          <Typography variant="h6" color="gray" textAlign="center">
            {editMode ? `EDIT SKILLS` : `ADD NEW SKILLS`}
          </Typography>
          <Grid container spacing={2} sx={{ padding: "5px" }}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                required
                name="name"
                label="Skills"
                value={skillsInput.name}
                onChange={onHandleChange}
                error={doneFirstLoad && !skillsInput.name}
                helperText={
                  doneFirstLoad && !skillsInput.name ? "Skills is required" : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                rows={4}
                name="description"
                label="Description"
                value={skillsInput.description}
                onChange={onHandleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSave}
                    >
                      Save
                    </Button>
                    <Button variant="text" color="secondary" onClick={onCancel}>
                      Cancel
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
      <LoadingSpinner loading={loading} />
    </div>
  );
}

export default SkillsManagementAdd;
