import { configureStore } from "@reduxjs/toolkit";

let jsonUser = {};
try {
  jsonUser = JSON.parse(localStorage.getItem("user"));
} catch {
  jsonUser = {};
}

// Define initial state
const initialState = {
  user:
    localStorage.getItem("user") && localStorage.getItem("user").length > 0
      ? jsonUser
      : {},
  theme: localStorage.getItem("theme") || "light",
  app_quarterly_id: localStorage.getItem("app_quarterly_id") || 0,
  user_id: localStorage.getItem("user_id") || "",
  profile_id: localStorage.getItem("profile_id") || 0,
};

// Define reducer function
function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      const newUser = action.payload;
      localStorage.setItem("user", JSON.stringify(newUser));
      return {
        ...state,
        user: newUser,
      };
    case "SET_THEME":
      const newTheme = action.payload;
      localStorage.setItem("theme", newTheme);
      return {
        ...state,
        theme: newTheme,
      };
    case "SET_APP_QUARTERLY_ID":
      const newAPPQuarterlyId = action.payload;
      localStorage.setItem("app_quarterly_id", newAPPQuarterlyId);
      return {
        ...state,
        app_quarterly_id: newAPPQuarterlyId,
      };
    case "SET_USER_ID":
      const newUserId = action.payload;
      localStorage.setItem("user_id", newUserId);
      return {
        ...state,
        user_id: newUserId,
      };
    case "SET_PROFILE_ID":
      const newProfileId = action.payload;
      localStorage.setItem("profile_id", newProfileId);
      return {
        ...state,
        profile_id: newProfileId,
      };
    default:
      return state;
  }
}

// Create Redux store
// const store = createStore(reducer);
const store = configureStore({
  reducer,
});
export default store;
