  import * as Material from "@mui/material";
  import React, { useState, useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { displayAlert } from "../common/Alert";
  import PGBhLogo from "../assets/PGBhlogo.png";
  import LoadingSpinner from "../../components/common/LoadingSpinner";
  import { ForceLogout } from "../../helpers/AxiosHelper";
  import { Redirect } from "react-router-dom";
  
  import { ResetFgtPassword } from "../../services/UsersService";
  
  // Icons used
  import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
  import Shuffle from "@mui/icons-material/Shuffle";
  
  const defaultStateDate = {
    password: "",
    verify_password: "",
    firstLoad: true,
    isPasswordStrong: false,
  };
  
  function ChangePassword({ SetUser }) {
    const user_id = useSelector((state) => state.user_id);
    const [stateData, setStateData] = useState(defaultStateDate);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [usersInput, setUsersInput] = useState(defaultStateDate);
    const [redirectNow, setRedirectNow] = useState(false);
    // for testing change stateData to any object in your state example: title
  
    // useEffect(() => {
    //     // Code to execute when the modal is rendered
    //     if (ModalVisible) {
    //       const State = {
    //         id: user_id,
    //         password: usersInput.password,
    //         verify_password: usersInput.verify_password,
    //         // errorHandler: usersInput.errorHandler
    //         firstLoad: true,
    //       };
    //       console.log("$$user", user_id);
    //       setUsersInput(State);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ModalVisible]);
  
    // useEffect(() => {
    //   console.log("$$uu", user_id);
    // });
    // component change state:
  
    // For getting url params
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code");
  
    const onHandleChange = (e) => {
      setUsersInput((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    };
  
    const onHandleChangePass = (e) => {
      const password = e.target.value;
      const isPasswordStrong =
        /[a-zA-Z]/.test(password) &&
        /\d/.test(password) &&
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password);
  
      setUsersInput((prevState) => ({
        ...prevState,
        password: password,
        isPasswordStrong,
      }));
    };
  
    // variable change state:
    const setState = (name, value) => {
      setStateData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
    const handleCloseComponent = () => {
      window.location.replace(`${window.location.origin}`);
    };
  
    const handleSubmit = () => {
      setLoading(true);
      if (!usersInput.password && !usersInput.verify_password) {
        setLoading(false);
        displayAlert({
          message: "Please fill in all fields",
          severity: "warning",
        });
      } else {
        if (usersInput.password !== usersInput.verify_password) {
          setLoading(false);
          displayAlert({
            message: "Passwords not match.",
            severity: "warning",
          });
        } else {
          if (!usersInput.password) {
            setLoading(false);
            displayAlert({
              message: "Please fill in new password",
              severity: "warning",
            });
  
            setUsersInput((prevState) => ({
              ...prevState,
              password: "",
              verify_password: "",
            }));
          } else {
            //   HideModal();
            ResetFgtPassword(code, usersInput.password)
              .then((res) => {
                if (res.error) {
                  setLoading(false);
                  displayAlert({
                    message: res.message,
                    severity: "error",
                  });
                } else {
                  setLoading(false);
                  displayAlert({
                    message: "Password changed successfully!",
                    severity: "success",
                  });
                  setLoading(false);
                  // ForceLogout();
                  setTimeout(handleCloseComponent, 3000);
                }
              })
              .catch((err) => {
                displayAlert({
                  message: err,
                  severity: "error",
                });
              });
          }
        }
      }
      setUsersInput((prevState) => ({
        ...prevState,
        firstLoad: false,
      }));
    };
  
    const generatePassword = () => {
      const randomPassword =
        Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
      setUsersInput((prevState) => ({
        ...prevState,
        password: randomPassword,
      }));
      // console.log('$$randomPassword', randomPassword);
      // window.prompt("Copy the new password to clipboard: Ctrl+C, Enter", randomPassword);
      navigator.clipboard.writeText(randomPassword);
      displayAlert({
        message: "Password is copied to clipboard.",
        severity: "info",
      });
    };
  
    return (
      <div>
        <Material.Fade in={true} timeout={1000}>
          <Material.AppBar position="static">
            <Material.Toolbar>
              <Material.Avatar src={PGBhLogo} alt="PGBh Logo" sx={{ marginRight: 1 }} />
              <Material.Typography variant="h6">
                PGBh Procurement - Suppliers Portal
              </Material.Typography>
            </Material.Toolbar>
          </Material.AppBar>
        </Material.Fade>
        <Material.Fade in={true} timeout={2000}>
          <Material.Container component="main" maxWidth="sm">
            <Material.Box
              component="main"
              maxWidth="sm"
              bgcolor={"background.default"}
              color={"text.primary"}
              sx={{
                boxShadow: 12,
                borderRadius: 2,
                px: 4,
                py: 6,
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderBottom: 4,
                borderColor: "primary.main",
              }}
            >
              <AccountCircleRoundedIcon sx={{ width: 50, height: 50 }} />
              <Material.Typography component="h1" variant="h5" fontWeight={"bold"}>
                Reset Password
              </Material.Typography>
              <Material.Box component="form" noValidate sx={{ mt: 1 }}>
                <Material.FormControl fullWidth variant="outlined" margin="dense">
                  <Material.InputLabel htmlFor="outlined-adornment-new-password">
                    Password
                  </Material.InputLabel>
                  <Material.OutlinedInput
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={usersInput.password}
                    onChange={onHandleChangePass}
                    error={!usersInput.firstLoad && !usersInput.password}
                    variant="outlined"
                    endAdornment={
                      <Material.InputAdornment position="end">
                        <Material.IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </Material.IconButton>
                        <Material.Tooltip title="Generate Random Password">
                          <Material.IconButton onClick={generatePassword} edge="end">
                            <Shuffle />
                          </Material.IconButton>
                        </Material.Tooltip>
                      </Material.InputAdornment>
                    }
                    label="Password"
                  />
                </Material.FormControl>
                <Material.FormControl fullWidth variant="outlined" margin="dense">
                  <Material.InputLabel htmlFor="outlined-adornment-verify-password">
                    Verify Password
                  </Material.InputLabel>
                  <Material.OutlinedInput
                    name="verify_password"
                    type={showPassword ? "text" : "password"}
                    value={usersInput.verify_password}
                    onChange={onHandleChange}
                    error={!usersInput.firstLoad && !usersInput.verify_password}
                    variant="outlined"
                    endAdornment={
                      <Material.InputAdornment position="end">
                        <Material.IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </Material.IconButton>
                      </Material.InputAdornment>
                    }
                    label="Verify Password"
                  />
                </Material.FormControl>
                {!usersInput.isPasswordStrong && (
                  <Material.Typography
                    variant="body2"
                    color="error"
                    className="password-requirements"
                  >
                    Password must contain characters, digits, uppercase letters,
                    lowercase letters, and symbols.
                  </Material.Typography>
                )}
  
                <Material.Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmit}
                >
                  Reset Password
                </Material.Button>
              </Material.Box>
            </Material.Box>
          </Material.Container>
        </Material.Fade>
        <LoadingSpinner loading={loading} />
      </div>
    );
  }
  
  export default ChangePassword;
  