import { Shuffle, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Tooltip,
  styled,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { displayAlert } from "../../common/Alert";
import { ChangeFgtPassword } from "../../../services/UsersService";
import { ForceLogout } from "../../../helpers/AxiosHelper";
import LoadingSpinner from "../../common/LoadingSpinner";

const SytledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const defaultStateData = {
  id: 0,
  password: "",
  verify_password: "",
  firstLoad: true,
  // errorHandler: ''
};

function UsersForgotPasswordModal({ ModalVisible, HideModal }) {
  const user_id = useSelector((state) => state.user_id);
  const [showPassword, setShowPassword] = useState(false);
  const [usersInput, setUsersInput] = useState(defaultStateData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Code to execute when the modal is rendered
    if (ModalVisible) {
      const State = {
        id: user_id,
        password: usersInput.password,
        verify_password: usersInput.verify_password,
        // errorHandler: usersInput.errorHandler
        firstLoad: true,
      };
      setUsersInput(State);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ModalVisible]);

  const onHandleChange = (e) => {
    setUsersInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const generatePassword = () => {
    const randomPassword =
      Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
    setUsersInput((prevState) => ({
      ...prevState,
      password: randomPassword,
    }));
    // console.log('$$randomPassword', randomPassword);
    // window.prompt("Copy the new password to clipboard: Ctrl+C, Enter", randomPassword);
    navigator.clipboard.writeText(randomPassword);
    displayAlert({
      message: "Password is copied to clipboard.",
      severity: "info",
    });
  };

  const closeModal = () => {
    HideModal();
    setLoading(false);
    resetStateData();
  };

  const resetStateData = () => {
    setUsersInput((prevState) => ({
      ...prevState,
      password: "",
      verify_password: "",
    }));
  };

  // const errorHandler = {
  //          password_error: !usersInput.password,
  //         new_password_error: !usersInput.password,
  //          verify_password_error: !usersInput.verify_password,

  //     setUsersInput((prevState) => ({
  //         ...prevState,
  //         errorHandler
  //     }));
  // }

  const changePassword = () => {
    setLoading(true);
    if (!usersInput.password && !usersInput.verify_password) {
      setLoading(false);
      displayAlert({
        message: "Please fill in all fields",
        severity: "warning",
      });
    } else {
      if (usersInput.password !== usersInput.verify_password) {
        setLoading(false);
        displayAlert({
          message: "Passwords not match.",
          severity: "warning",
        });
      } else {
        if (!usersInput.password) {
          setLoading(false);
          displayAlert({
            message: "Please fill in new password",
            severity: "warning",
          });

          setUsersInput((prevState) => ({
            ...prevState,
            password: "",
            verify_password: "",
          }));
        } else {
          HideModal();
          ChangeFgtPassword(usersInput)
            .then((res) => {
              if (res.error) {
                setLoading(false);
                displayAlert({
                  message: res.message,
                  severity: "error",
                });
              } else {
                setLoading(false);
                displayAlert({
                  message: "Password changed successfully!",
                  severity: "success",
                });
                setLoading(false);
                ForceLogout();
              }
            })
            .catch((err) => {
              displayAlert({
                message: err,
                severity: "error",
              });
            });
        }
      }
    }
    setUsersInput((prevState) => ({
      ...prevState,
      firstLoad: false,
    }));
  };
  return (
    <div>
      <SytledModal
        open={ModalVisible}
        onClose={HideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          maxWidth={400}
          maxHeight={"100%"}
          bgcolor={"background.default"}
          color={"text.primary"}
          p={4}
          borderRadius={3}
          paddingBottom={2}
        >
          <DialogTitle id="alert-dialog-title" color="gray">
            Change Password
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={usersInput.password}
                  onChange={onHandleChange}
                  error={!usersInput.firstLoad && !usersInput.password}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl> */}
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel htmlFor="outlined-adornment-new-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={usersInput.password}
                  onChange={onHandleChange}
                  error={!usersInput.firstLoad && !usersInput.password}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      <Tooltip title="Generate Random Password">
                        <IconButton onClick={generatePassword} edge="end">
                          <Shuffle />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                  label="New Password"
                />
              </FormControl>
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel htmlFor="outlined-adornment-verify-password">
                  Verify Password
                </InputLabel>
                <OutlinedInput
                  name="verify_password"
                  type={showPassword ? "text" : "password"}
                  value={usersInput.verify_password}
                  onChange={onHandleChange}
                  error={!usersInput.firstLoad && !usersInput.verify_password}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Verify Password"
                />
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions paddingBottom={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={changePassword}
              autoFocus
            >
              Save
            </Button>
            <Button variant="text" color="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </SytledModal>
      <LoadingSpinner loading={loading} />
    </div>
  );
}

export default UsersForgotPasswordModal;
