import * as Material from "@mui/material";
import React, { useState, useEffect } from "react";
import { UsersLogin } from "../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { displayAlert } from "../common/Alert";
import PGBhLogo from "../assets/PGBhlogo.png";
import LoadingSpinner from "../common/LoadingSpinner";
import UsersForgotPasswordModal from "./Users/UsersForgotPasswordModal";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import mainBG from "../assets/mainBG1.png";

import {
  forgotPassword,
  VerifyPassResetCode,
} from "../../services/UsersService";

// Icons used
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MenuIcon from "@mui/icons-material/Menu";


const defaultStateDate = {
  email: "",
  password: "",
  firstLoad: true,
};

const pages = ["Home", "PESO", "Register", "About"];

function Login({ SetUser }) {
  const user_id = useSelector((state) => state.user_id);
  const [stateData, setStateData] = useState(defaultStateDate);
  const { email, password, firstLoad } = stateData;
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [usersInput, setUsersInput] = useState(false);
  const [open, setOpen] = useState(false);
  const [opencode, setOpencode] = useState(false);
  const [modalVisibleForgotPassword, setmodalVisibleForgotPassword] =
    useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  // for testing change stateData to any object in your state example: title
  useEffect(() => {
    // console.log('state', stateData); // comment this if production
  }, [stateData]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  // component change state:
  const onChange = (e) => {
    setStateData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };
  const onHandleChange = (e) => {
    setUsersInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  // variable change state:
  const setState = (name, value) => {
    setStateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const HideModalForgotPassword = () => {
    setmodalVisibleForgotPassword(false);
  };

  const ShowModalForgotPassword = (e) => {
    setmodalVisibleForgotPassword(true);
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseModalCode = () => {
    setOpencode(false);
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    // e.preventDefault();
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const handleSubmitEmail = () => {
    setLoading(true);
    if (!usersInput.email) {
      setLoading(false);
      displayAlert({
        message: "Please fill in the email field",
        severity: "warning",
      });
    } else {
      forgotPassword(usersInput.email)
        .then((res) => {
          if (!res.error) {
            setLoading(false);
            navigate("/forgotPassword", { replace: true });
            setOpen(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("$$err2", err);
        });
    }
  };

  const dispatch = useDispatch();

  const handleSubmitVerificationCode = () => {
    setLoading(true);
    if (!usersInput.password_reset_code) {
      setLoading(false);
    } else {
      VerifyPassResetCode(
        usersInput.password_reset_code,
        usersInput.email,
        user_id
      )
        .then((res) => {
          if (!res.error) {
            setLoading(false);
            setOpencode(false);
            dispatch({ type: "SET_USER_ID", payload: user_id });
            ShowModalForgotPassword();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("$$err3", err);
        });
    }
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
  }
    const { email, password } = stateData;
    if (email && password 
     // && captchaValue
    ) {
      setLoading(true);
      UsersLogin(stateData)
        .then((res) => {
          if (!res.error) {
            // display alert
            displayAlert({
              message: `Welcome ${res?.data?.email?.toUpperCase()}!`,
              severity: "success",
            });
            setLoading(false);
            
            // set global state for use
            SetUser(res.data);
          } else {
            displayAlert({
              message: res?.message,
              severity: "warning",
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("$$err1", err);
        });
    } else {
      displayAlert({
        message: "Please provide all the required fields (*) !",
        severity: "warning",
      });
      setLoading(false);
    }
    setState(Object.keys({ firstLoad })[0], false);
  };


  return (
    <div>
      <style>
        {`
          body {
            background-image: url(${mainBG});
            background-size: cover;
            background-position: center;
            margin: 0;
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}
      </style>
      <Material.Fade in={true} timeout={1000}>
        <Material.AppBar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "linear-gradient(to right, #004AAD, #5DE0E6)"
        }}>
          <Material.Container maxWidth="auto">
            <Material.Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }} 
            >
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Avatar
                  src={PGBhLogo}
                  alt="PGBh Logo"
                  sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
                />
                <Material.Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontWeight: 900,
                    // letterSpacing: ".1rem",
                    textDecoration: "none",
                    color: "white",
                    fontFamily: 'Montserrat, sans-serif'
                  }}
                >
                  BEPO-PESO
                </Material.Typography>

                <Material.Box
                  sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                >
                  <Material.IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                  >
                    <MenuIcon />
                  </Material.IconButton>
                  <Material.Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                    color="white"
                  >
                    {pages.map((page) => (
                      <Material.MenuItem
                        key={page}
                        onClick={handleCloseNavMenu}
                        sx={{ color: "white", display: 'flex', justifyContent: 'center'}}
                      >
                        <Material.Typography
                          textAlign="center"
                          sx={{ color: "black" }}
                        >
                          {page}
                        </Material.Typography>
                      </Material.MenuItem>
                    ))}
                  </Material.Menu>
                </Material.Box>

                <Material.Avatar
                  src={PGBhLogo}
                  alt="PGBh Logo"
                  sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
                />
                <Material.Typography
                  //variant="h5"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                    mr: 2,
                    display: { xs: "flex", md: "none" },
                    flexGrow: 1,
                    fontFamily: "montserat, sans-serif",
                    fontWeight: 700,
                    //letterSpacing: ".3rem",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  BEPO-PESO
                </Material.Typography>
                <Material.Box
                  sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                >
                  {pages.map((page) => (
                    <Material.Button
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page}
                    </Material.Button>
                  ))}
                </Material.Box>
              </Material.Box>

              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  sx={{ display: "flex", justifyContent: "flex-end", color: "white" }}
                >
                  <AccountCircleRoundedIcon />
                </Material.IconButton>
                <Material.Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Material.MenuItem onClick={handleClose}>
                    Login
                  </Material.MenuItem>
                  <Material.MenuItem onClick={handleClose}>
                    Register
                  </Material.MenuItem>
                </Material.Menu>
              </Material.Box>
            </Material.Toolbar>
          </Material.Container>
        </Material.AppBar>
      </Material.Fade>
        <Material.Container component="main" maxWidth="sm">
          <Material.Box
            component="main"
            maxWidth="sm"
            color={"text.primary"}
            sx={{
              boxShadow: 12,
              borderRadius: 2,
              px: 6,
              py: 8,
              marginTop: 12,
              marginBottom: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderBottom: 4,
              background: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(5px)", // Blur effect
              color: 'white'
            }}
          >
           
            <Material.Typography
              component="h1"
              variant="h4"
              fontWeight={"bold"}
              style={{ color: "black"}}
            >
              Sign In
            </Material.Typography>
            <Material.Typography style={{ color: "black"}}>
              Enter your credentials to access your account.
            </Material.Typography>
            <Material.Box component="form" noValidate sx={{ mt: 1 }}>
              <Material.FormControl
                variant="outlined"
                fullWidth
                margin="normal"
              >
                <Material.InputLabel htmlFor="outlined-adornment-email" required sx={{color: 'black'}}>
                  Email
                </Material.InputLabel>
                <Material.OutlinedInput
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  fullWidth
                  value={email}
                  onChange={onChange}
                  error={!firstLoad && !email}
                  onKeyDown={handleKeypress}
                  required
                  endAdornment={
                    <Material.InputAdornment position="end">
                      <AlternateEmailRoundedIcon />
                    </Material.InputAdornment>
                  }
                  label="Email"
                  sx={{color: 'black'}}
                />
              </Material.FormControl>
              <Material.FormControl
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <Material.InputLabel
                  required
                  htmlFor="outlined-adornment-password"
                  sx={{color: 'black'}}
                >
                  Password
                </Material.InputLabel>
                <Material.OutlinedInput
                  id="password"
                  autoComplete="current-password"
                  autoFocus
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onKeyDown={handleKeypress}
                  required
                  value={password}
                  onChange={onChange}
                  error={!firstLoad && !password}
                  endAdornment={
                    <Material.InputAdornment position="end">
                      <Material.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </Material.IconButton>
                    </Material.InputAdornment>
                  }
                  label="Password"
                  sx={{color: 'black'}}
                />
              </Material.FormControl>
              <Material.Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                Sign In
              </Material.Button>

              {/* recaptcha here */}
              <ReCAPTCHA
                  style={{ display: "flex", justifyContent: "center" }}
                  sitekey="6LeAyZ4pAAAAAIcncUgzsAuy-Fj0Ui4z5irP3jIL"
                  onChange={handleCaptchaChange}
                />

              <Material.Link
                onClick={handleOpenModal}
                margin="normal"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
                style={{ cursor: "pointer", color: "blue" }}
              >
                Forgot Password?
              </Material.Link>
              <Material.Typography
                margin="normal"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                  color:'black'
                }}
                variant="overline"
                display="block"
                gutterBottom
              >
                {" BD:11192023"}
              </Material.Typography>
              {/* Forgot Password enter email Dialog */}
              <Material.Dialog open={open} onClose={handleCloseModal}>
                {loading === true ? <Material.LinearProgress /> : <></>}
                <Material.DialogTitle>
                  Account Verification
                </Material.DialogTitle>
                <Material.DialogContent>
                  <Material.DialogContentText>
                    Please enter your valid Email Address.
                  </Material.DialogContentText>
                  <Material.TextField
                    autoFocus
                    margin="dense"
                    name="email"
                    label="Email Address"
                    fullWidth
                    value={usersInput.email}
                    variant="standard"
                    onChange={onHandleChange}
                    error={!firstLoad && !email}
                  />
                </Material.DialogContent>
                <Material.DialogActions>
                  <Material.Button onClick={handleCloseModal}>
                    Cancel
                  </Material.Button>
                  <Material.Button
                    variant="contained"
                    onClick={handleSubmitEmail}
                  >
                    Submit
                  </Material.Button>
                </Material.DialogActions>
              </Material.Dialog>
              {/* end of forgot password enter email dialog */}

              {/* Forgot Password enter code Dialog */}
              <Material.Dialog open={opencode}>
                {loading === true ? <Material.LinearProgress /> : <></>}
                <Material.DialogTitle>
                  Account Verification
                </Material.DialogTitle>
                <Material.DialogContent>
                  <Material.DialogContentText>
                    An email with your account confirmation link has been sent
                    to your email <b>{usersInput.email}, </b>
                    check your email and comeback to proceed!
                  </Material.DialogContentText>
                </Material.DialogContent>
                <Material.DialogActions>
                  <Material.Button onClick={handleCloseModalCode}>
                    Cancel
                  </Material.Button>
                  <Material.Button
                    variant="contained"
                    onClick={handleSubmitVerificationCode}
                  >
                    Continue
                  </Material.Button>
                </Material.DialogActions>
              </Material.Dialog>
              {/* end of forgot password enter code dialog */}
            </Material.Box>
          </Material.Box>
        </Material.Container>
      <UsersForgotPasswordModal
        ModalVisible={modalVisibleForgotPassword}
        HideModal={HideModalForgotPassword}
        user_id={user_id}
      />
      <LoadingSpinner loading={loading} />
    </div>
  );
}

export default Login;
