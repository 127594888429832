import React, { useEffect, useState } from "react";
import * as Material from "@mui/material";
import ProfileService from "../../../services/ProfileService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { displayAlert } from "../../common/Alert";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MainFormModal from "../Forms/MainForm.Modal";
import ViewProfile from "../Profile/ViewProfile";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../common/LoadingSpinner";

function Row({ row, onDelete, onView, onEdit }) {
  const profiles = Object.values(row).filter(
    (data) => data && typeof data === "object"
  );
  const user_role = useSelector((state) => state.user.role_id);

  const [openStates, setOpenStates] = React.useState({});

  const toggleOpen = (index) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };

  return (
    <>
      {profiles.map((profileData, index) => (
        // console.log("profiles", profiles),
        <React.Fragment key={index}>
          <Material.TableRow
            key={`${profileData.id}-${index}`}
            sx={{ "& > *": { borderBottom: "unset" } }}
          >
            <Material.TableCell>
              <Material.IconButton
                aria-label="expand row"
                size="small"
                onClick={() => toggleOpen(index)}
              >
                {openStates[index] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Material.IconButton>
            </Material.TableCell>
            <Material.TableCell component="th" scope="row">
              {`${profileData.firstname || ""} ${
                profileData.middlename || ""
              } ${profileData.lastname || ""} ${profileData.suffix || ""}` ||
                "N/A"}
            </Material.TableCell>
            <Material.TableCell>
              {`${profileData.barangay_name || ""}, ${
                profileData.municipality || ""
              }, ${profileData.province || ""}` || "N/A"}
            </Material.TableCell>
            <Material.TableCell>
              {profileData.employment_status || "N/A"}
            </Material.TableCell>
            <Material.TableCell>
              {profileData.dole_program || "N/A"}
            </Material.TableCell>
            <Material.TableCell>
              {profileData.mobile || "N/A"}
            </Material.TableCell>
            <Material.TableCell
              align="left"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton
                aria-label="view"
                onClick={() => onView(profileData.id)}
              >
                <VisibilityIcon style={{ color: "orange" }} />
              </IconButton>
              <IconButton aria-label="edit" onClick={() => onEdit(profileData)}>
                <EditIcon style={{ color: "blue" }} />
              </IconButton>

              {user_role === 1 ? (
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(profileData.id)}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </IconButton>
              ) : (
                ""
              )}
            </Material.TableCell>
          </Material.TableRow>
          <Material.TableRow>
            <Material.TableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={8}
            >
              <Material.Collapse
                in={openStates[index]}
                timeout="auto"
                unmountOnExit
              >
                <Material.Box sx={{ margin: 1 }}>
                  <Material.Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                  >
                    Profile Details
                  </Material.Typography>
                  <Material.Table size="small" aria-label="purchases">
                    <Material.TableBody>
                      <Material.TableRow>
                        <Material.TableCell>Date of Birth:</Material.TableCell>
                        <Material.TableCell>
                          {profileData.birthdate || "N/A"}
                        </Material.TableCell>
                      </Material.TableRow>
                      <Material.TableRow>
                        <Material.TableCell>Age:</Material.TableCell>
                        <Material.TableCell>
                          {profileData.age || "N/A"}
                        </Material.TableCell>
                      </Material.TableRow>
                      <Material.TableRow>
                        <Material.TableCell>Religion:</Material.TableCell>
                        <Material.TableCell>
                          {profileData.religion || "N/A"}
                        </Material.TableCell>
                      </Material.TableRow>
                      <Material.TableRow>
                        <Material.TableCell>Civil Status:</Material.TableCell>
                        <Material.TableCell>
                          {profileData.civil_status || "N/A"}
                        </Material.TableCell>
                      </Material.TableRow>
                      <Material.TableRow>
                        <Material.TableCell>Gender:</Material.TableCell>
                        <Material.TableCell>
                          {profileData.gender || "N/A"}
                        </Material.TableCell>
                      </Material.TableRow>
                      <Material.TableRow>
                        <Material.TableCell>Email:</Material.TableCell>
                        <Material.TableCell>
                          {profileData.email || "N/A"}
                        </Material.TableCell>
                      </Material.TableRow>
                    </Material.TableBody>
                  </Material.Table>
                </Material.Box>
              </Material.Collapse>
            </Material.TableCell>
          </Material.TableRow>
        </React.Fragment>
      ))}
    </>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Material.Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <Material.IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </Material.IconButton>
      <Material.IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </Material.IconButton>
      <Material.IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </Material.IconButton>
      <Material.IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </Material.IconButton>
    </Material.Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function Profile() {
  const user_role = useSelector((state) => state.user.role_id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allProfiles, setAllProfiles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [advancedSearchResults, setAdvancedSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [viewProfileId, setViewProfileId] = useState(null);
  const [viewProfileOpen, setViewProfileOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalityData, setMunicipalityData] = useState([]);
  const [selectedMunicipalityName, setSelectedMunicipalityName] = useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState("");
  const [barangays, setBarangays] = useState([]);
  const [selectedBarangay, setSelectedBarangay] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [totalNoOfRows, setTotalNoOfRows] = useState(0);

  useEffect(() => {
    fetchProfiles(page, rowsPerPage, selectedMunicipality, selectedBarangay);
    getMunicipalities();
  }, [page, rowsPerPage, selectedMunicipality, selectedBarangay]);

  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchProfiles(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchProfiles(0, newRowsPerPage);
  };

  const fetchProfiles = async (
    page,
    rowsPerPage,
    selectedMunicipality,
    selectedBarangay,
    searchTerm
  ) => {
    let values = {
      municipality_id: selectedMunicipality || 0,
      barangay_id: selectedBarangay || 0,
      keyword: searchTerm || "",
      page_no: page || 0,
      limit: rowsPerPage || 10,
      sort_column: "",
      sort_type: "",
    };

    setLoading(true);
    try {
      const data = await ProfileService.getProfileDetails(values);
      setTotalNoOfRows(data.result1[0].row_count);
      setAllProfiles([data.result0]);
      setSearchResults([data.result0]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching profile details:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  // Function to handle opening the view profile dialog
  const handleViewProfile = (profileId) => {
    setViewProfileId(profileId);
    setViewProfileOpen(true);
  };

  const handleDelete = async (_profiles_id) => {
    setSelectedProfile(_profiles_id);
    setShowConfirmationModal(true);
  };
  const handleReset = () => {
    setSelectedBarangay("");
    setSelectedMunicipality("");
    setSearchTerm("");

    fetchProfiles(page, rowsPerPage, "", "", "");
  };

  const handleAdvancedSearch = async () => {
    fetchProfiles(
      page,
      rowsPerPage,
      selectedMunicipality,
      selectedBarangay,
      searchTerm
    );
  };

  const confirmDelete = async () => {
    try {
      await ProfileService.deleteProfile(selectedProfile);
      setAllProfiles(
        allProfiles.filter((profile) => profile.id !== selectedProfile.id)
      );
      setSearchResults(
        searchResults.filter((profile) => profile.id !== selectedProfile.id)
      );
      setSelectedProfile(null);
      setShowConfirmationModal(false);
      displayAlert({
        message: "Profile deleted successfully!",
        severity: "success",
      });
      fetchProfiles(
        page,
        rowsPerPage,
        selectedMunicipality,
        selectedBarangay,
        searchTerm
      );
    } catch (error) {
      console.error("Error deleting profile:", error);
      displayAlert({
        message: error.message,
        severity: "error",
      });
    }
  };

  const cancelDelete = () => {
    setSelectedProfile(null);
    setShowConfirmationModal(false);
    displayAlert({
      message: "Deletion canceled.",
      severity: "info",
    });
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value ? event.target.value : "";

    setSearchTerm(searchTerm);
  };

  const getMunicipalities = async () => {
    try {
      const response = await ProfileService.getMunicipalities();

      let municipalityData = [];

      if (response.data && Array.isArray(response.data)) {
        municipalityData = response.data;
      } else if (Array.isArray(response)) {
        municipalityData = response;
      } else if (typeof response === "object" && response !== null) {
        municipalityData = Object.values(response);
      } else {
        console.error("Unexpected response structure:", response);
      }

      const municipalityNames = municipalityData.map(
        (item) => item.municipal_name
      );

      setMunicipalities(municipalityNames);
      setMunicipalityData(municipalityData);
    } catch (error) {
      console.error("Error fetching municipalities:", error);
    }
  };

  const getBarangays = async (municipalityId) => {
    try {
      const response = await ProfileService.getBarangaysByMunicipalName(
        municipalityId
      );
      let barangayData = [];
      if (Array.isArray(response) && response.length > 0) {
        barangayData = response[0];
      } else {
        console.log("No barangay data found in response.");
      }
      setBarangays(barangayData);
    } catch (error) {
      console.error("Error fetching barangays:", error);
    }
  };

  const handleMunicipalityChange = (event) => {
    event.preventDefault();
    const selectedMunicipalityId = event.target.value;
    const selectedMunicipality = municipalityData.find(
      (municipal) => municipal.municipal_id === selectedMunicipalityId
    );
    if (selectedMunicipality) {
      const selectedMunicipalityName = selectedMunicipality.municipal_name;
      setSelectedMunicipality(selectedMunicipalityId);
      setSelectedMunicipalityName(selectedMunicipalityName);
      getBarangays(selectedMunicipalityName);
    } else {
      console.error(
        "Municipality not found for the selected ID:",
        selectedMunicipalityId
      );
    }
  };

  const handleBarangayChange = (event) => {
    event.preventDefault();
    setSelectedBarangay(event.target.value);
  };

  const handleAddProfile = () => {
    setIsEditMode(false);
    setModalVisible(true);
  };

  const handleEditProfile = (profile) => {
    let profile_id = profile.id;
    getProfile(profile_id);
  };

  const handleCloseModal = () => {
    setSelectedProfile(null);
    setModalVisible(false);
    // fetchProfiles(); // Refresh profiles after closing modal
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allProfiles.length) : 0;

  const getProfile = async (profile_id) => {
    try {
      const res = await ProfileService.getProfilePerUser(profile_id);
      if (res && res.error) {
        displayAlert({
          message: res.message,
          severity: "error",
        });
      } else {
        setSelectedProfile(res);
        setIsEditMode(true);
        setModalVisible(true);
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      displayAlert({
        message: error.message || "An error occurred.",
        severity: "error",
      });
    }
  };

  return (
    <>
      <Material.Box
        flex={4}
        height="80vh"
        sx={{
          maxWidth: "100%",
          overflow: "hidden", // Hide overflow of the outer container
        }}
      >
        <Material.Box
          paddingLeft={{ xs: 4, sm: 10, md: 10 }}
          paddingRight={{ xs: 4, sm: 10, md: 10 }}
          // paddingTop={{ xs: 4, sm: 6, md: 10 }}
          sx={{ display: "flex", flexDirection: "column", height: "100%" }} // Flex column for proper layout
        >
          <Material.Typography
            sx={{
              paddingTop: 4,
              paddingBottom: 2,
              fontSize: 30,
              fontWeight: "bold",
              letterSpacing: 1.5,
              background: "linear-gradient(90deg, #2575fc, #6dd5ed)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
              borderRadius: 1,
            }}
          >
            Profiles
          </Material.Typography>
          <Material.Paper
            sx={{
              flex: 1, // Allow Paper to grow and take available space
              display: "flex",
              flexDirection: "column",
              overflow: "hidden", // Hide overflow within Paper
            }}
          >
            <Material.Box
              sx={{
                paddingBottom: 0,
                paddingTop: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "visible", // Ensure this container does not scroll
              }}
            >
              <Material.Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={handleAddProfile}
                sx={{ marginLeft: 3 }}
              >
                Add New Profile
              </Material.Button>
              <Material.Box
                sx={{ display: "flex", alignItems: "center", marginRight: 3 }}
              >
                <Material.Collapse
                  in={isSearchOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                    <Material.TextField
                      label="Search Name"
                      variant="standard"
                      size="small"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      sx={{ width: 180, marginRight: 1 }}
                    />
                    <Material.FormControl
                      size="large"
                      variant="standard"
                      sx={{ flex: 1, width: 180, marginRight: 2 }}
                    >
                      <Material.InputLabel>Municipality</Material.InputLabel>
                      <Material.Select
                        value={selectedMunicipality}
                        onChange={handleMunicipalityChange}
                        label="Municipality"
                      >
                        <Material.MenuItem value="">
                          <em>None</em>
                        </Material.MenuItem>
                        {municipalityData.map((municipal, index) => (
                          <Material.MenuItem
                            key={index}
                            value={municipal.municipal_id}
                          >
                            {municipal.municipal_name}{" "}
                            {/* Displaying the municipality name */}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>

                    <Material.FormControl
                      size="large"
                      variant="standard"
                      sx={{ flex: 1, width: 200 }}
                    >
                      <Material.InputLabel>Barangay</Material.InputLabel>
                      <Material.Select
                        value={selectedBarangay}
                        onChange={handleBarangayChange}
                        label="Barangay"
                        disabled={!selectedMunicipality}
                      >
                        <Material.MenuItem value="">
                          <em>None</em>
                        </Material.MenuItem>
                        {Array.isArray(barangays) ? (
                          barangays.length > 0 ? (
                            barangays.map((barangay) => (
                              <Material.MenuItem
                                key={barangay.barangay_name}
                                value={barangay.barangay_id}
                              >
                                {barangay.barangay_name}
                              </Material.MenuItem>
                            ))
                          ) : (
                            <Material.MenuItem disabled>
                              No barangays available
                            </Material.MenuItem>
                          )
                        ) : Object.keys(barangays).length > 0 ? (
                          Object.values(barangays).map((barangay) => (
                            <Material.MenuItem
                              key={barangay.barangay_name}
                              value={barangay.barangay_id}
                            >
                              {barangay.barangay_name}
                            </Material.MenuItem>
                          ))
                        ) : (
                          <Material.MenuItem disabled>
                            No barangays available
                          </Material.MenuItem>
                        )}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.Button
                      variant="outlined"
                      color="primary"
                      // endIcon={<SearchIcon />}
                      onClick={handleAdvancedSearch}
                      sx={{ marginLeft: 1 }}
                    >
                      Search
                    </Material.Button>
                    <Material.Button
                      variant="outlined"
                      color="error"
                      onClick={handleReset}
                      sx={{ marginLeft: 1 }}
                    >
                      RESET
                    </Material.Button>
                  </Material.Box>
                </Material.Collapse>
                <Material.Tooltip
                  title={isSearchOpen ? "Hide Search" : "Show Search"}
                >
                  <Material.IconButton
                    color="primary"
                    onClick={handleToggleSearch}
                    sx={{ marginRight: 2 }}
                  >
                    {isSearchOpen ? <SearchIcon /> : <SearchIcon />}
                  </Material.IconButton>
                </Material.Tooltip>
              </Material.Box>
            </Material.Box>
            <Material.Divider sx={{ paddingY: 1 }} />
            <Material.TableContainer
              component={Material.Paper}
              sx={{ flex: 1, overflowY: "auto" }} // Allow scrolling within TableContainer
            >
              <Material.Table aria-label="collapsible table" stickyHeader>
                <Material.TableHead>
                  <Material.TableRow sx={{ backgroundColor: "#e0e0e0" }}>
                    <Material.TableCell />
                    <Material.TableCell style={{ fontWeight: "bold" }}>
                      Fullname
                    </Material.TableCell>
                    <Material.TableCell style={{ fontWeight: "bold" }}>
                      Address
                    </Material.TableCell>
                    <Material.TableCell style={{ fontWeight: "bold" }}>
                      Employment Status
                    </Material.TableCell>
                    <Material.TableCell style={{ fontWeight: "bold" }}>
                      DOLE Program
                    </Material.TableCell>
                    <Material.TableCell style={{ fontWeight: "bold" }}>
                      Contact Number
                    </Material.TableCell>
                    <Material.TableCell
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      Actions
                    </Material.TableCell>
                  </Material.TableRow>
                </Material.TableHead>
                <Material.TableBody>
                  {allProfiles.length > 0 &&
                    allProfiles
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((profile) => (
                        <Row
                          key={profile.id}
                          row={profile}
                          onDelete={handleDelete}
                          onView={handleViewProfile}
                          onEdit={handleEditProfile}
                        />
                      ))}
                  {emptyRows > 0 && (
                    <Material.TableRow style={{ height: 53 * emptyRows }}>
                      <Material.TableCell colSpan={6} />
                    </Material.TableRow>
                  )}
                </Material.TableBody>
              </Material.Table>
            </Material.TableContainer>
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
              }}
            >
              <Material.TablePagination
                rowsPerPageOptions={[
                  10,
                  50,
                  100,
                  { label: "All", value: 10000000 },
                ]}
                count={totalNoOfRows}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </Material.Box>
          </Material.Paper>
          <MainFormModal
            ModalVisible={modalVisible}
            HideModal={handleCloseModal}
            isEditMode={isEditMode}
            fetchProfiles={fetchProfiles}
            InitialProfileData={selectedProfile} // Pass entire profile data for editing
          />
        </Material.Box>

        <Material.Dialog
          open={showConfirmationModal}
          onClose={cancelDelete}
          aria-labelledby="delete-confirmation-modal"
          aria-describedby="confirm-delete-profile"
        >
          <Material.DialogTitle>Confirm Deletion</Material.DialogTitle>
          <Material.DialogContent>
            <Material.DialogContentText>
              Are you sure you want to delete this profile?
            </Material.DialogContentText>
          </Material.DialogContent>
          <Material.DialogActions>
            <Material.Button onClick={cancelDelete} color="secondary">
              Cancel
            </Material.Button>
            <Material.Button
              onClick={confirmDelete}
              color="primary"
              variant="contained"
              sx={{
                color: "white",
                boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              Delete
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>
        <Material.Snackbar
          open={showToast}
          autoHideDuration={6000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Material.Alert
            onClose={handleToastClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Profile successfully deleted.
          </Material.Alert>
        </Material.Snackbar>
        <ViewProfile
          profileId={viewProfileId}
          open={viewProfileOpen}
          onClose={() => setViewProfileOpen(false)}
        />
      </Material.Box>
      {loading && <LoadingSpinner loading={loading} />}
    </>
  );
}

export default Profile;
