import API from "../helpers/AxiosHelper";

const SkillsService = {
  getSkills: async () => {
    try {
      const response = await API.get("/skills/getSkills"); // Using GET now
      const data = Object.values(response.data[0]);
      return data;
    } catch (error) {
      console.error("Error fetching Skills:", error);
      throw error;
    }
  },
};

export const Save = (value) => {
  const req = {
    name: value.name,
    description: value.description,
    added_by: value.added_by,
  };

  return API.post("/skills/save", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("$$error", err); // Log the error
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const Delete = (id) => {
  const reqBody = {
    id: id,
  };

  return API.delete("/skills/delete", { data: reqBody })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const UpdateSkills = (value) => {
  const req = {
    id: value.id,
    name: value.name,
    description: value.description,
  };

  return API.put("/skills/update", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // Handle the error properly
      const errorMessage = err.message || "An unknown error occurred";
      const responseMessage = err.response?.data?.message || "";
      const fullMessage = `${errorMessage} | ${responseMessage}`;

      console.error("$$error", fullMessage); // Log the error
      return {
        error: true,
        message: fullMessage,
      };
    });
};

export default SkillsService;
