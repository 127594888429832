import { Visibility, VisibilityOff, Shuffle } from "@mui/icons-material";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Modal,
	OutlinedInput,
	FilledInput,
	Select,
	Switch,
	TextField,
	Tooltip,
	Typography,
	styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { displayAlert } from "../../common/Alert";
import { Save } from "../../../services/UsersService";
import { UpdateUser } from "../../../services/UsersService"; //OJT - Added UpdateUser import
import LoadingSpinner from "../../common/LoadingSpinner";
import axios from 'axios';

const SytledModal = styled(Modal)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
});

const defaultStateData = {
	id: 0,
	firstname: "",
	middlename: "",
	lastname: "",
	birthday: "",
	role_id: 0,
	address: "",
	username: "",
	password: "",
	is_active: true,
};

function UsersModal({
	SelectedRow,
	ModalVisible,
	HideModal,
	UserRoles,
	RefreshUser,
}) {
	const editMode = Object.keys(SelectedRow).length > 0 ? true : false;
	// modal did mount
	useEffect(() => {
		// Code to execute when the modal is rendered
		if (ModalVisible) {
			const initialState = editMode
				? {
						id: SelectedRow.id,
						firstname: SelectedRow.firstname,
						middlename: SelectedRow.middlename,
						lastname: SelectedRow.lastname,
						birthday: SelectedRow.birthdate,
						role_id: SelectedRow.role_id,
						address: SelectedRow.address,
						username: SelectedRow.username,
						is_active: SelectedRow.is_active > 0,
				  }
				: defaultStateData;
			setUsersInput(initialState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ModalVisible]);

	const [usersInput, setUsersInput] = useState(defaultStateData);
	const [doneFirstLoad, setDoneFirstLoad] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	// testing:
	// useEffect(() => {
	//     console.log('$$usersInput', usersInput);
	// }, [usersInput]);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const onHandleChange = (e) => {
		setUsersInput((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	const onHandleIsActiveChange = (e) => {
		setUsersInput((prevState) => ({
			...prevState,
			is_active: !usersInput.is_active,
		}));
	};


//Original
// 	const onSave = () => {
// 		setDoneFirstLoad(true);
// 		const byPassPassword = editMode ? true : usersInput.password;
// 		if (
// 			byPassPassword &&
// 			usersInput.firstname &&
// 			usersInput.middlename &&
// 			usersInput.lastname &&
// 			usersInput.address &&
// 			usersInput.birthday &&
// 			usersInput.role_id > 0 &&
// 			usersInput.username
//         ) {
//             HideModal();
//             setLoading(true);
			// save here:
// 			Save(usersInput)
// 				.then((res) => {
// 					if (res.error) {
// 						displayAlert({
// 							message: res.message,
// 							severity: "error",
//                         });
//                         setLoading(false);
// 					} else {
// 						displayAlert({
// 							message: "Saved successfully!",
// 							severity: "success",
//                         });
//                         setLoading(false);
// 						RefreshUser();
// 						resetState();
// 						HideModal();
// 					}
// 				})
// 				.catch((err) => {
// 					displayAlert({
// 						message: err,
// 						severity: "error",
//                     });
//                     setLoading(false)
// 				});

// 		} else {
// 			displayAlert({
// 				message: "Please fill all the required fields!",
// 				severity: "warning",
//             });
//             setLoading(false)
// 		}
// 	};

//OJT - Edited onSave: Added UpdateUser
const onSave = () => {
	setDoneFirstLoad(true);
	const byPassPassword = editMode ? true : usersInput.password;
	if (
			byPassPassword &&
			usersInput.firstname &&
			usersInput.middlename &&
			usersInput.lastname &&
			usersInput.address &&
			usersInput.birthday &&
			usersInput.role_id > 0 &&
			usersInput.username
	) {
			HideModal();
			setLoading(true);
			// Check if in edit mode to determine whether to save or update user
			if (editMode) {
					// Update user details here:
					UpdateUser(usersInput)
							.then((res) => {
									if (res.error) {
											displayAlert({
													message: res.message,
													severity: "error",
											});
											setLoading(false);
									} else {
											displayAlert({
													message: "User details updated successfully!",
													severity: "success",
											});
											setLoading(false);
											RefreshUser();
											resetState();
											HideModal();
									}
							})
							.catch((err) => {
									displayAlert({
											message: err,
											severity: "error",
									});
									setLoading(false);
							});
			} else {
					// Save new user details here:
					Save(usersInput)
							.then((res) => {
									if (res.error) {
											displayAlert({
													message: res.message,
													severity: "error",
											});
											setLoading(false);
									} else {
											displayAlert({
													message: "User saved successfully!",
													severity: "success",
											});
											setLoading(false);
											RefreshUser();
											resetState();
											HideModal();
									}
							})
							.catch((err) => {
									displayAlert({
											message: err,
											severity: "error",
									});
									setLoading(false);
							});
			}
	} else {
			displayAlert({
					message: "Please fill all the required fields!",
					severity: "warning",
			});
			setLoading(false);
	}
};

	const onCancel = () => {
		resetState();
		HideModal();
	};

	const generatePassword = () => {
		const randomPassword =
			Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
		setUsersInput((prevState) => ({
			...prevState,
			password: randomPassword,
		}));
		// console.log('$$randomPassword', randomPassword);
		// window.prompt("Copy the new password to clipboard: Ctrl+C, Enter", randomPassword);
		navigator.clipboard.writeText(randomPassword);
		displayAlert({
			message: "Password is copied to clipboard.",
			severity: "info",
		});
	};

	const resetState = () => {
		setUsersInput(defaultStateData);
		setDoneFirstLoad(false);
	};

	return (
		<div>
			<SytledModal
				open={ModalVisible}
				onClose={HideModal}
				aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx = {{ overflow: 'auto' }}
			>
				<Box
					width={800}
					maxHeight={'90%'}
					bgcolor={"background.default"}
					color={"text.primary"}
					p={3}
					borderRadius={3}
					paddingBottom={10}
				>
					<Typography variant="h6" color="gray" textAlign="center">
						{editMode ? `EDIT USER` : `CREATE NEW USER`}
					</Typography>
					<Grid
						container
                        // direction="row"
                        direction="row"
						justifyContent="flex-start"
						// alignItems="center"
                        alignItems="stretch"
						spacing={2}
						// paddingTop={5}
						// paddingLeft={5}
						// paddingRight={5}
                        sx = {{ padding: '5px' }}
					>
						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								required
								name="firstname"
								label="Firstname"
								value={usersInput.firstname}
								onChange={onHandleChange}
								error={doneFirstLoad && !usersInput.firstname}
								variant="filled"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								required
								name="middlename"
								label="Middlename"
								value={usersInput.middlename}
								onChange={onHandleChange}
								error={doneFirstLoad && !usersInput.middlename}
								variant="filled"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								fullWidth
								required
								name="lastname"
								label="Lastname"
								value={usersInput.lastname}
								onChange={onHandleChange}
								error={doneFirstLoad && !usersInput.lastname}
								variant="filled"
							/>
						</Grid>
						<Grid item xs={6} sm={4}>
							<TextField
								fullWidth
								required
								name="birthday"
								label="Date of Birth"
								type="date"
								InputLabelProps={{
									shrink: true,
								}}
								value={usersInput.birthday}
								onChange={onHandleChange}
								error={doneFirstLoad && !usersInput.birthday}
								variant="filled"
							/>
						</Grid>
						<Grid item xs={6} sm={8}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Role</InputLabel>
								<Select
									variant="filled"
									name="role_id"
									required
									label="Role"
									value={usersInput.role_id}
									onChange={onHandleChange}
									error={doneFirstLoad && !usersInput.role_id}
								>
									<MenuItem value={0}>--Select a Role--</MenuItem>
									{UserRoles.map((row, index) => (
										<MenuItem key={index} value={row.id}>
											{row.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={12}>
							<TextField
								fullWidth
								required
								name="address"
								label="Address"
								multiline
								rows={2}
								value={usersInput.address || ""}
								onChange={onHandleChange}
								error={doneFirstLoad && !usersInput.address}
								variant="filled"
							/>
						</Grid>
						<Grid item xs={6} sm={6}>
							<TextField
								fullWidth
								required
								name="username"
								label="Email"
								value={usersInput.username}
								onChange={onHandleChange}
								error={doneFirstLoad && !usersInput.username}
								variant="filled"
							/>
						</Grid>
						{editMode ? (
							""
						) : (
							<Grid item xs={6} sm={6}>
								<FormControl fullWidth variant="outlined">
									<InputLabel required htmlFor="outlined-adornment-password">
										Password
									</InputLabel>
									<FilledInput
										name="password"
										type={showPassword ? "text" : "password"}
										value={usersInput.password}
										onChange={onHandleChange}
										error={doneFirstLoad && !usersInput.password}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
												<Tooltip title="Generate Random Password">
													<IconButton onClick={generatePassword} edge="end">
														<Shuffle />
													</IconButton>
												</Tooltip>
											</InputAdornment>
										}
										label="Password"
									/>
								</FormControl>
							</Grid>
						)}
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={6}>
									<FormGroup>
										<FormControlLabel
											required
											control={
												<Switch
													name="is_active"
													checked={usersInput.is_active}
													onChange={onHandleIsActiveChange}
												/>
											}
											label="Is Active"
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={6}>
									<Box display="flex" justifyContent="flex-end">
										<Button
											variant="contained"
											color="primary"
											onClick={onSave} //IDK
										>
											Save
										</Button>
										<Button variant="text" color="secondary" onClick={onCancel}>
											Cancel
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
            </SytledModal>
            <LoadingSpinner loading={loading} />
		</div>
	);
}

export default UsersModal;
