import API from "../helpers/AxiosHelper";

const EmploymentStatusService = {
  getEmploymentStatus: async () => {
    try {
      const response = await API.post("/employmentstatus/getEmploymentStatus");
      const data = Object.values(response.data[0]);
      console.log("dataServeee", data);
      return data;
    } catch (error) {
      console.error("Error fetching Skills :", error);
      throw error;
    }
  },
};

export const Save = (value) => {
  const req = {
    occupation: value.occupation,
    description: value.description,
    employment_status: value.employment_status,
    employed_type: value.employed_type,
    added_by: value.added_by,
  };

  return API.post("/employmentstatus/save", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error("$$error", err); // Log the error
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const Delete = (id) => {
  const reqBody = {
    id: id,
  };

  return API.delete("/employmentstatus/delete", { data: reqBody })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

// export const UpdateEmploymentStatus = (employmentStatusData) => {
//   console.log("$$empstatdata", employmentStatusData);
//   const req = {
//     occupation: employmentStatusData.occupation,
//     description: employmentStatusData.description,
//     employment_status: employmentStatusData.employment_status,
//     employed_type: employmentStatusData.employed_type,
//   };

//   return API.put("/employmentstatus/update", req)
//     .then((res) => {
//       return res;
//     })
//     .catch((err) => {
//       return {
//         error: true,
//         message: `${err?.message} | ${err?.response?.data?.message}`,
//       };
//     });
// };

export const UpdateEmploymentStatus = (value) => {
  const req = {
    id: value.id,
    occupation: value.occupation,
    description: value.description || null,
    employment_status: value.employment_status,
    employed_type: value.employed_type || null, // Default to null if not provided
  };

  return API.put("/employmentstatus/update", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // Handle the error properly
      const errorMessage = err.message || "An unknown error occurred";
      const responseMessage = err.response?.data?.message || "";
      const fullMessage = `${errorMessage} | ${responseMessage}`;

      console.error("$$error", fullMessage); // Log the error
      return {
        error: true,
        message: fullMessage,
      };
    });
};

export default EmploymentStatusService;
