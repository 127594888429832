import { ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "../pages/Login";
import PageNotFound from "../pages/common/PageNotFound";
import ChangePassword from '../pages/ChangePassword';
import { ForceLogin } from "../../helpers/AxiosHelper";
import DataPassing from "../../helpers/DataPassing";
import { useDispatch } from "react-redux";
import ForgotPassword from '../pages/ForgotPassword';

function LoginContainer() {
	// console.log("$$theme", DataPassing.Get("theme"));
	const darkTheme = createTheme({
		palette: {
			mode:
				!DataPassing.Get("theme") || DataPassing.Get("theme").length === 0
					? "light"
					: DataPassing.Get("theme"),
		},
	});

	const dispatch = useDispatch();
	const SetUser = (value) => {
		if (Object.keys(value).length > 0) {
			dispatch({ type: "SET_USER", payload: value });
		}
	};

	return (
		<BrowserRouter>
			<ThemeProvider theme={darkTheme}>
				<ToastContainer floatingTime={5000} />
				<Routes>
					<Route index element={<Login SetUser={SetUser} />} />
					<Route path="*" element={<PageNotFound />} />
					<Route path="resetPassword" element={<ChangePassword />} />
					<Route path="forgotPassword" element={<ForgotPassword />} />
				</Routes>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default LoginContainer;
