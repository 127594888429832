import API from "../helpers/AxiosHelper";

// sample get code:
// note: use params to pass the data
export const Get = (user_id) => {
  const req = {
    params: {
      user_id,
    },
  };
  return API.get("/user/get", req)
   .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

//Original - Delete User
// export const Delete = (user_id) => {
//   const req = {
//     params: {
//       user_id,
//     },
//   };
//   return API.delete("/user/delete", req)
//    .then((res) => {
//       return res;
//     })
//     .catch((err) => {
//       return {
//         error: true,
//         message: `${err?.message} | ${err?.response?.data?.message}`,
//       };
//     });
// };

//OJT - Edited Delete User
export const Delete = (user_id) => {
  const reqBody = {
    user_id: user_id
  };

  return API.delete("/user/delete", { data: reqBody })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const Save = (value) => {
  const req = {
    id: parseInt(value.id),
    username: value.username,
    password: value.password || "",
    is_active: value.is_active ? 1 : 0,
    role_id: value.role_id,
    firstname: value.firstname,
    middlename: value.middlename,
    lastname: value.lastname,
    address: value.address,
    birthdate: value.birthday,
  };
  return API.post("/user/save", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};


export const ResetPassword = (value) => {
  // console.log("$$Value", value);
  const req = {
    id: parseInt(value.id),
    password: value.password,
  };
  // console.log("$$req", req);
  return API.post("/user/resetPassword", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

// For Change Password with auth key

export const ChangePassword = (value) => {
  const req = {
    id: parseInt(value.id),
    password: value.password,
    new_password: value.new_password,
  };
  return API.post("/user/changePassword", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

// For Change Password withouth auth key
export const ChangeFgtPassword = (value) => {
  //   console.log("$$Value", value);
  const req = {
    id: parseInt(value.id),
    password: value.password,
  };
  //   console.log("$$req", req);
  return API.post("/user/changeFgtPassword", req)
   .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

// For verify code and reset password
export const ResetFgtPassword = (password_reset_code, password) => {
  const req = {
    password_reset_code: password_reset_code,
    password: password,
  };
  return API.post("/user/resetForgotPassword", req)
   .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const forgotPassword = (value) => {
  const req = {
    supplier_email: value,
  };
  return API.post("/user/forgotPassword", req)
   .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const VerifyPassResetCode = (value, usersInput) => {
  const req = {
    params: {
      supplier_password_reset_code: value,
      supplier_email: usersInput,
    },
  };
  return API.get("/user/getPasswordResetCode", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

//OJT - Added Update User
export const UpdateUser = (userData) => {
  const req = {
    user_id: parseInt(userData.id),
    username: userData.username,
    is_active: userData.is_active ? 1 : 0,
    role_id: userData.role_id,
    firstname: userData.firstname,
    middlename: userData.middlename,
    lastname: userData.lastname,
    address: userData.address,
    birthdate: userData.birthday,
  };

  return API.put("/user/update", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};
