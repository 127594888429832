import { Shuffle, Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	Modal,
	OutlinedInput,
	Tooltip,
	styled,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect } from "react";
// import UsersModal from './UsersModal';
import { ResetPassword } from "../../../services/UsersService";
import { useState } from "react";
import { useSelector } from "react-redux";
import { displayAlert } from "../../common/Alert";
import LoadingSpinner from "../../common/LoadingSpinner";

const SytledModal = styled(Modal)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
});

const defaultStateData = {
	id: 0,
	password: "",
};

function UsersResetPasswordModal({ SelectedRow, ModalVisible, HideModal }) {
	// const user_id = useSelector((state) => state.user.id);
	// const [selectedRow, setselectedRow] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [doneFirstLoad, setDoneFirstLoad] = useState(false);
	const [usersInput, setUsersInput] = useState(defaultStateData);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// Code to execute when the modal is rendered
		if (ModalVisible) {
			const State = {
				id: SelectedRow.id,
				password: usersInput.password,
			};
			setUsersInput(State);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ModalVisible]);

	const onHandleChange = (e) => {
		setUsersInput((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const generatePassword = () => {
		const randomPassword =
			Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
		setUsersInput((prevState) => ({
			...prevState,
			password: randomPassword,
		}));
		// console.log('$$randomPassword', randomPassword);
		// window.prompt("Copy the new password to clipboard: Ctrl+C, Enter", randomPassword);
		navigator.clipboard.writeText(randomPassword);
		displayAlert({
			message: "Password is copied to clipboard.",
			severity: "info",
		});
	};

	// const resetState = () => {
	//     id: 0,
	//     password: ''
	// }

	const closeModal = () => {
        setUsersInput((prevState) => ({
            ...prevState,
			password: '',
		}));
        HideModal();
	};

	const resetPassword = () => {

        if (SelectedRow && usersInput.password.length > 0) {
            HideModal();
			setLoading(true);
			ResetPassword(usersInput)
				.then((res) => {
					if (res.error) {
						displayAlert({
							message: res.message,
							severity: "error",
						});
						setLoading(false);
					} else {
						displayAlert({
							message: "Password changed successfully!",
							severity: "success",
						});
						setLoading(false);
						closeModal();
					}
				})
				.catch((err) => {
					displayAlert({
						message: err,
						severity: "error",
					});
					setLoading(false);
				});
		} else {
			displayAlert({
				message: "Please fill in the field before proceeding!",
				severity: "warning",
			});
			setLoading(false);
		}
	};
	return (
		<div>
			<SytledModal
				open={ModalVisible}
				onClose={HideModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					maxWidth={400}
					maxHeight={260} //OJT - Changed the maxHeight
					bgcolor={"background.default"}
					color={"text.primary"}
					p={3}
					borderRadius={3}
					paddingBottom={10}
				>
					<DialogTitle id="alert-dialog-title" color="gray">
						{`Reset password for ${SelectedRow.username?.toUpperCase()}?`}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{/* Are you sure you want to reset password for {`${SelectedRow.firstname} ${SelectedRow.lastname}`}? */}
							<FormControl fullWidth variant="outlined" margin="dense">
								<InputLabel required htmlFor="outlined-adornment-password">
									Password
								</InputLabel>
								<OutlinedInput
									name="password"
									type={showPassword ? "text" : "password"}
									value={usersInput.password}
									onChange={onHandleChange}
									error={doneFirstLoad && !usersInput.password}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
											<Tooltip title="Generate Random Password">
												<IconButton onClick={generatePassword} edge="end">
													<Shuffle />
												</IconButton>
											</Tooltip>
										</InputAdornment>
									}
									label="Password"
								/>
							</FormControl>
						</DialogContentText>
					</DialogContent>
					<DialogActions paddingBottom={2}>
						{/* <Button variant="contained" color='primary' onClick={() => { resetPassword(); }} autoFocus>Proceed</Button>
                    <Button variant="text" color='secondary' onClick={() => { closeModal(); } }>Cancel</Button> */}
						<Button
							variant="contained"
							color="primary"
							onClick={resetPassword}
							autoFocus
						>
							Proceed
						</Button>
						<Button variant="text" color="secondary" onClick={closeModal}>
							Cancel
						</Button>
					</DialogActions>
				</Box>
			</SytledModal>
			<LoadingSpinner loading={loading} />
		</div>
	);
}

export default UsersResetPasswordModal;
