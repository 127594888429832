//OJT - Added MyAccountService.js

import API from "../helpers/AxiosHelper";

const MyAccountService = {
  getMyAccountDetails: async (userId) => {
      try {
          const response = await API.post("/myaccount/getDetails", { userId });
          const data = response.data;
          return data;
        // return response.data;

      } catch (error) {
          console.error("Error fetching user details:", error); // Log any errors
          throw error;
      }
  },
};

export default MyAccountService; 
