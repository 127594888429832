import API from "../helpers/AxiosHelper";

const DoleProgramService = {
  getDoleProgram: async () => {
    try {
      const response = await API.post("/doleprogram/getProfileDetails");
      const data = Object.values(response.data[0]);
      // console.log("data", data);
      return data;
    } catch (error) {
      console.error("Error fetching DoleProgram :", error);
      throw error;
    }
  },
  // butngan natog dynamic bro pra dali ra makit an, mgama tag bag o sp sa stored procedure
  getDynamicDolePrograms: async () => {
    try {
      const response = await API.get("/doleprogram/getDynamicDolePrograms");
      const data = Object.values(response.data);
      return data;
    } catch (error) {
      console.error("Error fetching DynamicDolePrograms :", error);
      throw error;
    }
  },

  getDoleAvailmentsSummary: async () => {
    try {
      const response = await API.get(
        "/doleprogram/getProgramAvailmentsSummary"
      );
      const data = Object.values(response.data[0]);
      // console.log("dataServ", data);
      return data;
    } catch (error) {
      console.error("Error fetching ProgramAvailments :", error);
      throw error;
    }
  },

  getProfileDetails: async () => {
    try {
      const response = await API.post("/doleprogram/getProfileDetails");
      return response.data;
    } catch (error) {
      console.error("Error fetching profile details:", error);
      throw error;
    }
  },
};

export const Save = async (value) => {
  // console.log("value", value);
  const req = {
    profile_id: value.profile_id,
    fullname: value.fullname,
    address: value.address,
    programs: value.programs.map((program) => program),
  };
  try {
    const res = await API.post("/doleprogram/save", req);
    return res;
  } catch (err) {
    return {
      error: true,
      message: `${err?.message} | ${err?.response?.data?.message}`,
    };
  }
};

export const Edit = async (value) => {
  console.log("Valaue", value);

  const req = {
    id: value.id,
    date_from: value.date_from,
    date_to: value.date_to,
    program_id: value.program_id,
  };
  try {
    const res = await API.post("/doleprogram/edit", req);
    return res;
  } catch (err) {
    return {
      error: true,
      message: `${err?.message} | ${err?.response?.data?.message}`,
    };
  }
};

export const Delete = async (value) => {
  const req = {
    id: value.id,
  };
  try {
    const res = await API.post("/doleprogram/delete", req);
    return res;
  } catch (err) {
    return {
      error: true,
      message: `${err?.message} | ${err?.response?.data?.message}`,
    };
  }
};

export default DoleProgramService;
