import * as Material from "@mui/material";
import React, { useState, useEffect } from "react";
import { UsersLogin } from "../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { displayAlert } from "../common/Alert";
import PGBhLogo from "../assets/PGBhlogo.png";
import LoadingSpinner from "../common/LoadingSpinner";
import { useNavigate } from "react-router-dom";

// Icons used
import SafetyCheck from "@mui/icons-material/SafetyCheck";


const defaultStateDate = {
	id: 0,
	password: "",
	verify_password: "",
	firstLoad: true,
};

function ForgotPassword() {
	const [loading, setLoading] = useState(false);
	// for testing change stateData to any object in your state example: title

	useEffect(() => {
		setTimeout(handleHome, 5000)
	});

	const handleHome = () => {
		window.location.replace(`${window.location.origin}`);
		// window.close();
	}

	return (
		<div>
			<Material.Fade in={true} timeout={1000}>
				<Material.AppBar position="static">
					<Material.Toolbar>
						<Material.Avatar src={PGBhLogo} alt="PGBh Logo" sx={{ marginRight: 1 }} />
						<Material.Typography variant="h6">
							PGBh Procurement - Suppliers Portal
						</Material.Typography>
					</Material.Toolbar>
				</Material.AppBar>
			</Material.Fade>
			<Material.Fade in={true} timeout={2000}>
				<Material.Container component="main" maxWidth="sm">
					<Material.Box
						component="main"
						maxWidth="sm"
						bgcolor={"background.default"}
						color={"text.primary"}
						sx={{
							boxShadow: 12,
							borderRadius: 2,
							px: 4,
							py: 6,
							marginTop: 8,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							borderBottom: 4,
							borderColor: "primary.main",
						}}
					>
						<SafetyCheck sx={{ width: 50, height: 50, marginBottom: 2 }} />
						<Material.Typography variant="h3">Account Verification</Material.Typography>
						<Material.Typography variant="subtitle1" sx={{ padding: 4 }}>
							An email with your account confirmation link has been sent to your
							email , check your email and verify to proceed
						</Material.Typography>
						<Material.Button
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
							onClick={handleHome}
						>
							OK
						</Material.Button>
					</Material.Box>
				</Material.Container>
			</Material.Fade>
			<LoadingSpinner loading={loading} />
		</div>
	);
}

export default ForgotPassword;
