// THIS IS THE OFFICIAL CODE STRUCTURE TEMPLATE USE FOR THIS TEMPLATE
// USE: rfce + Tab
import React, { useState } from "react";
import * as Material from "@mui/material";

import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import MainFormModal from "./MainForm.Modal";

// icons used
import AddIcon from "@mui/icons-material/Add";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Material.TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <Material.TableCell>
          <Material.IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Material.IconButton>
        </Material.TableCell>
        <Material.TableCell component="th" scope="row">
          {row.name}
        </Material.TableCell>
        <Material.TableCell align="right">{row.calories}</Material.TableCell>
        <Material.TableCell align="right">{row.fat}</Material.TableCell>
        <Material.TableCell align="right">{row.carbs}</Material.TableCell>
        <Material.TableCell align="right">{row.protein}</Material.TableCell>
      </Material.TableRow>
      <Material.TableRow>
        <Material.TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Material.Collapse in={open} timeout="auto" unmountOnExit>
            <Material.Box sx={{ margin: 1 }}>
              <Material.Typography variant="h6" gutterBottom component="div">
                History
              </Material.Typography>
              <Material.Table size="small" aria-label="purchases">
                <Material.TableHead>
                  <Material.TableRow>
                    <Material.TableCell>Date</Material.TableCell>
                    <Material.TableCell>Customer</Material.TableCell>
                    <Material.TableCell align="right">
                      Amount
                    </Material.TableCell>
                    <Material.TableCell align="right">
                      Total price ($)
                    </Material.TableCell>
                  </Material.TableRow>
                </Material.TableHead>
                <Material.TableBody>
                  {row.history.map((historyRow) => (
                    <Material.TableRow key={historyRow.date}>
                      <Material.TableCell component="th" scope="row">
                        {historyRow.date}
                      </Material.TableCell>
                      <Material.TableCell>
                        {historyRow.customerId}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {historyRow.amount}
                      </Material.TableCell>
                      <Material.TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </Material.TableCell>
                    </Material.TableRow>
                  ))}
                </Material.TableBody>
              </Material.Table>
            </Material.Box>
          </Material.Collapse>
        </Material.TableCell>
      </Material.TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

function MainForm() {
  const [dialogVisible, setdialogVisible] = useState(false);
  const [selectedRow, setselectedRow] = useState({});
  const [modalVisible, setmodalVisible] = useState(false);

  const HideModal = () => {
    setmodalVisible(false);
  };
  const ShowModal = () => {
    setmodalVisible(true);
  };

  const onEdit = (row) => {
    // console.log('$$row', row);
    setselectedRow(row);
    ShowModal();
  };
  const onAdd = () => {
    setselectedRow({});
    ShowModal();
  };

  return (
    <Material.Box flex={4} height="100vh" sx={{ maxWidth: "95%" }}>
      <Material.Box
        paddingLeft={{ xs: 4, sm: 10, md: 10 }}
        paddingTop={{ xs: 4, sm: 6, md: 10 }}
      >
        <Material.Paper>
          <Material.Box sx={{ padding: 4 }}>
            <Material.Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={onAdd}
              sx={{ marginBottom: 2 }}
            >
              Add New
            </Material.Button>
          </Material.Box>
          <Material.TableContainer component={Paper}>
            <Material.Table aria-label="collapsible table">
              <Material.TableHead>
                <Material.TableRow>
                  <Material.TableCell />
                  <Material.TableCell>
                    Dessert (100g serving)
                  </Material.TableCell>
                  <Material.TableCell align="right">
                    Calories
                  </Material.TableCell>
                  <Material.TableCell align="right">
                    Fat&nbsp;(g)
                  </Material.TableCell>
                  <Material.TableCell align="right">
                    Carbs&nbsp;(g)
                  </Material.TableCell>
                  <Material.TableCell align="right">
                    Protein&nbsp;(g)
                  </Material.TableCell>
                </Material.TableRow>
              </Material.TableHead>
              <Material.TableBody>
                {rows.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </Material.TableBody>
            </Material.Table>
          </Material.TableContainer>
        </Material.Paper>

        <MainFormModal
          // SelectedRow={selectedRow}
          ModalVisible={modalVisible}
          HideModal={HideModal}

          // UserRoles={roleData}
          // RefreshUser={getUsers}
        />
      </Material.Box>
    </Material.Box>
  );
}

export default MainForm;
