import API from "../helpers/AxiosHelper";

const DoleProgramManagementService = {
  getDoleProgramManagement: async () => {
    try {
      const response = await API.post("/doleprogrammanagement/getDoleProgramManagement");
      const data = Object.values(response.data[0]);
      return data;
    } catch (error) {
      console.error("Error fetching DoleProgram :", error);
      throw error;
    }
  },
} 



export const Save = (value) => {
  const req = {
    program: value.program,
    description: value.description,
    added_by: value.added_by,
    // added_on: value.added_on
  }
  return API.post("/doleprogrammanagement/save", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};

export const Delete = (id) => {
  const reqBody = {
    id: id
  };

  return API.delete("/doleprogrammanagement/delete", { data: reqBody })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return {
        error: true,
        message: `${err?.message} | ${err?.response?.data?.message}`,
      };
    });
};


export const UpdateDoleProgramManagement = (value) => {

  const req = {
    id: value.id,
    program: value.program,
    description: value.description
  };

  return API.put("/doleprogrammanagement/update", req)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // Handle the error properly
      const errorMessage = err.message || 'An unknown error occurred';
      const responseMessage = err.response?.data?.message || '';
      const fullMessage = `${errorMessage} | ${responseMessage}`;

      console.error("$$error", fullMessage); // Log the error
      return {
        error: true,
        message: fullMessage,
      };
    });
};

export default DoleProgramManagementService;
