import axios from 'axios';
import DataPassing from './DataPassing';

const HttpStatus = {
    OK: 200,
    NOCONTENT: 204,
    BADREQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOTFOUND: 404,
    INTERNALSERVERERROR: 500,
    SERVICENOTAVAILABLE: 503,
    GATEWAYTIMEOUT: 504
}

const API = axios.create({
    // baseURL: process.env.REACT_APP_BASEURL, 
    // Replace with your API base URL
    baseURL: process.env.REACT_APP_BASEURL, //"http://192.168.13.87:5004", 
    timeout: 50000, // Adjust the timeout value as needed
});

API.interceptors.request.use(
    (config) => {
        // You can modify the request config here, such as adding headers or authentication tokens
        // config.headers.Authorization = 'Bearer YOUR_ACCESS_TOKEN';
        config.headers['Content-Type'] = 'application/json';
        config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
        config.headers['x-access-key'] = localStorage.getItem('access_token');
        config.headers['x-refresh-key'] = localStorage.getItem('refresh_token');

        // console.log('$$access_token_to_send', localStorage.getItem('access_token'));
        // console.log('$$refresh_token_to_send', localStorage.getItem('refresh_token'));
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => {
        // You can modify the response data here or do other processing
        // console.log('$$api_response', response);

        if (response.data?.access_token) {
            localStorage.setItem('access_token', response.data.access_token);
        }
        if (response.data?.refresh_token) {
            localStorage.setItem('refresh_token', response.data.refresh_token);
        }

        // console.log('$$NEW_access_token', localStorage.getItem('access_token'));
        // console.log('$$NEW_refresh_token', localStorage.getItem('refresh_token'));
        return response.data
    },
    (error) => {
        // Handle error responses
        if (error.response) {
            // Server responded with a status code outside the range of 2xx
            console.error('Request failed with status:', error.response.status);
            console.error('Error message:', error.response.data.error);

            if (error.response.status === HttpStatus.UNAUTHORIZED
                || error.response.status === HttpStatus.FORBIDDEN
            ) {
                ForceLogout();
            }
        } else if (error.request) {
            // Request was made but no response was received
            console.error('No response received:', error.request);
        } else {
            // Something happened in setting up the request
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export const ForceLogout = () => {
    DataPassing.Set('user', JSON.stringify({}));
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.replace(`${window.location.origin}`);
}
export const ForceLogin = () => {
    window.location.replace(`${window.location.origin}`);
}

export default API;
